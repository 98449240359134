import { FC, FormEvent, useState, useCallback, useMemo } from 'react';
import { Link, Route, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../Button/Button';
import { Nav } from '../Nav/Nav';
import { ReactComponent as InfoIcon } from '../../images/icons/alert-circle.svg';
import { ProjectCard } from '../ProjectCard/ProjectCard';
import { TextInput } from '../TextInput/TextInput';
import searchIconSrc from '../../images/icons/search.svg';
import slidersIconSrc from '../../images/icons/sliders.svg';
import xIconSrc from '../../images/icons/x.svg';
import { Select } from '../Select/Select';
import { useProjectList } from '../../contexts/ProjectListContext';
import { useCollegeList } from '../../contexts/CollegeListContext';
import { useDepartmentList } from '../../contexts/DepartmentListContext';
import { useProgramList } from '../../contexts/ProgramListContext';
import { CreateProjectModal } from '../modals/projectModals/CreateProjectModal';
import { useQuery } from '../../utils/useQuery';
import { useStaffManagerList } from '../../contexts/StaffManagerContext';
import useAsyncEffect from '../../utils/react/useAsyncEffect';
import { canUserCreateProjects } from 'shared/lib/utils/permissions';
import { useAccount } from '../../contexts/AccountContext';

export const ProjectListPage: FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const showArchivedProjects = useQuery().get('archived') === 'true';
  const { user } = useAccount();
  const { projects, listProjects } = useProjectList();
  const { colleges } = useCollegeList();
  const { departments } = useDepartmentList();
  const { programs } = useProgramList();
  const { staffManagers } = useStaffManagerList();
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [filterStaffManagerId, setFilterStaffManagerId] = useState<
    number | null
  >(null);
  const [filterCollegeId, setFilterCollegeId] = useState<number | null>(null);
  const [filterDepartmentId, setFilterDepartmentId] = useState<number | null>(
    null,
  );
  const [filterProgramId, setFilterProgramId] = useState<number | null>(null);
  const filteredProjects = useMemo(() => {
    const lowercaseSearchText = searchText.toLowerCase();
    return projects.filter(
      (project) =>
        // Matches archived tab
        showArchivedProjects === project.archived &&
        // Matches staff manager
        (!filterStaffManagerId ||
          project.staffManagerId === filterStaffManagerId) &&
        // Matches search text
        (!searchText ||
          project.name.toLowerCase().includes(lowercaseSearchText)) &&
        // Matches college filter
        (!filterCollegeId ||
          project.colleges.some((college) => college.id === filterCollegeId)) &&
        // Matched department filter
        (!filterDepartmentId ||
          project.departments.some(
            (college) => college.id === filterDepartmentId,
          )) &&
        // Matched program filter
        (!filterProgramId ||
          project.programs.some((college) => college.id === filterProgramId)),
    );
  }, [
    projects,
    searchText,
    filterStaffManagerId,
    filterCollegeId,
    filterDepartmentId,
    filterProgramId,
    showArchivedProjects,
  ]);

  useAsyncEffect(listProjects, []);

  const toggleFiltersVisible = useCallback(() => {
    setShowFilters((value) => !value);
  }, []);

  const handleSearchChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setSearchText(event.currentTarget.value);
    },
    [],
  );

  const handleStaffManagerFilterChange = useCallback((value: string | null) => {
    setFilterStaffManagerId(value ? +value : null);
  }, []);

  const handleCollegeFilterChange = useCallback((value: string | null) => {
    setFilterCollegeId(value ? +value : null);
  }, []);

  const handleDepartmentFilterChange = useCallback((value: string | null) => {
    setFilterDepartmentId(value ? +value : null);
  }, []);

  const handleProgramFilterChange = useCallback((value: string | null) => {
    setFilterProgramId(value ? +value : null);
  }, []);

  const closeFilters = useCallback(() => {
    setShowFilters(false);
    setFilterStaffManagerId(null);
    setFilterCollegeId(null);
    setFilterDepartmentId(null);
    setFilterProgramId(null);
  }, []);

  return (
    <div {...props} className="min-h-screen py-16 px-8 lg:px-20">
      <div className="container mx-auto max-w-full">
        <Nav tab="dashboard" />

        {/* Heading */}
        <div className="col md:flex-row md:items-center md:justify-between mb-12">
          <h1 className="text-6xl">Projects</h1>
          {user && canUserCreateProjects(user) && (
            <Button
              className="w-48"
              backgroundColor="uo-green"
              textColor="white"
              size="large"
              onClick={() =>
                history.push({
                  pathname: '/createProject',
                  search: location.search,
                })
              }
            >
              + New Project
            </Button>
          )}
        </div>

        {/* Project filters */}
        <div className="grid grid-cols-6 gap-4 mb-10 relative">
          <div className="flex flex-row items-center col-span-6 lg:col-span-2">
            <Link to="/" className="mr-4 flex-grow md:flex-grow-0">
              <Button
                className="font-semibold w-full md:w-24"
                textColor={showArchivedProjects ? 'gray' : 'black'}
                backgroundColor={showArchivedProjects ? 'none' : 'uo-yellow'}
              >
                Active
              </Button>
            </Link>
            <Link
              to="/?archived=true"
              className="mr-4 flex-grow md:flex-grow-0"
            >
              <Button
                className="font-semibold w-full md:w-24"
                textColor={showArchivedProjects ? 'black' : 'gray'}
                backgroundColor={showArchivedProjects ? 'uo-yellow' : 'none'}
              >
                Archived
              </Button>
            </Link>
            <div className="row flex-grow justify-start">
              <Button
                textColor="gray"
                backgroundColor="none"
                className="flex-shrink-0"
                size="small"
              >
                <InfoIcon color="black" />
              </Button>
            </div>
          </div>

          {showFilters ? (
            <>
              <div className="col-span-6 md:col-span-3 lg:col-span-1">
                <Select
                  className="flex-grow"
                  placeholder="IPS Staff"
                  value={filterStaffManagerId}
                  onValueChange={handleStaffManagerFilterChange}
                  options={staffManagers.map((staffManager) => ({
                    value: staffManager.id.toString(),
                    label: staffManager.name,
                  }))}
                />
              </div>
              <div className="col-span-6 md:col-span-3 lg:col-span-1">
                <Select
                  className="flex-grow"
                  placeholder="College"
                  value={filterCollegeId}
                  onValueChange={handleCollegeFilterChange}
                  options={colleges.map((college) => ({
                    value: college.id.toString(),
                    label: college.name,
                  }))}
                />
              </div>
              <div className="col-span-6 md:col-span-3 lg:col-span-1">
                <Select
                  className="flex-grow"
                  placeholder="Department"
                  value={filterDepartmentId}
                  onValueChange={handleDepartmentFilterChange}
                  options={departments.map((department) => ({
                    value: department.id.toString(),
                    label: department.name,
                  }))}
                />
              </div>
              <div className="col-span-6 md:col-span-3 lg:col-span-1">
                <Select
                  className="flex-grow"
                  placeholder="Program"
                  value={filterProgramId}
                  onValueChange={handleProgramFilterChange}
                  options={programs.map((program) => ({
                    value: program.id.toString(),
                    label: program.name,
                  }))}
                />
              </div>
              <Button
                backgroundColor="none"
                textColor="black"
                className="absolute right-0 top-0 lg:-mr-16"
                onClick={closeFilters}
              >
                <img src={xIconSrc} alt="Close filters" />
              </Button>
            </>
          ) : (
            <>
              <div className="col-span-6 md:col-span-2">
                <TextInput value={searchText} onChange={handleSearchChange}>
                  <img alt="Search" src={searchIconSrc} />
                </TextInput>
              </div>
              <div className="row items-center md:justify-end col-span-6 md:col-span-2">
                <Button
                  textColor="black"
                  backgroundColor="none"
                  className="row items-center"
                  onClick={toggleFiltersVisible}
                >
                  <img
                    src={slidersIconSrc}
                    alt=""
                    className="mr-4 -rotate-90"
                  />
                  Filter By
                </Button>
              </div>
            </>
          )}
        </div>

        {/* Project grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              className="sm:w-full"
            />
          ))}
        </div>
      </div>

      <Route path="/createProject">
        <CreateProjectModal
          onClose={() =>
            history.push({ pathname: '/', search: location.search })
          }
        />
      </Route>
    </div>
  );
};
