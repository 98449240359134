import { FC, FormEvent, useCallback } from 'react';
import { AdminToolsNav } from './AdminToolsNav';
import { Button } from '../Button/Button';
import { Nav } from '../Nav/Nav';
import { UserTable } from '../UserTable/UserTable';
import { Link, Route, useHistory } from 'react-router-dom';
import { CreateUserModal } from '../modals/userModals/CreateUserModal';
import { EditUserModal } from '../modals/userModals/EditUserModal';
import { TextInput } from '../TextInput/TextInput';
import searchIconSrc from '../../images/icons/search.svg';
import { useUserList } from '../../contexts/UserListContext';

export const ManageUsersPage: FC = (props) => {
  const history = useHistory();
  const { searchText, setSearchText } = useUserList();

  const closeModals = useCallback(() => {
    history.push('/admin/users');
  }, [history]);

  const handleSearchChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setSearchText(event.currentTarget.value);
    },
    [setSearchText],
  );

  return (
    <div {...props} className="min-h-screen pt-16 pb-48 px-8 lg:px-20">
      <div className="container mx-auto max-w-full">
        <Nav tab="admin tools" />

        {/* Heading */}
        <div className="col md:flex-row md:items-center md:justify-between">
          <h1 className="text-6xl">Admin Tools</h1>
          <Link to="/admin/users/add">
            <Button
              className="w-48"
              backgroundColor="uo-green"
              textColor="white"
              size="large"
            >
              Add User
            </Button>
          </Link>
        </div>

        <div className="row mt-8">
          <AdminToolsNav tab="users" className="w-1/3" />
          <TextInput
            value={searchText}
            onChange={handleSearchChange}
            className="w-1/3 self-end"
          >
            <img alt="Search" src={searchIconSrc} />
          </TextInput>
        </div>

        <UserTable searchText={searchText} className="mt-12" />
      </div>

      {/* User modals */}
      <Route path="/admin/users/add">
        <CreateUserModal onClose={closeModals} />
      </Route>

      <Route path="/admin/user/:userId/edit">
        {({ match }) =>
          match && (
            <EditUserModal
              onClose={closeModals}
              userId={+match.params.userId}
            />
          )
        }
      </Route>
    </div>
  );
};
