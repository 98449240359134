import { FC, useState } from 'react';
import { ReactComponent as FileIcon } from '../../images/icons/file.svg';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import { Flyout } from '../Flyout/Flyout';
import { getFileName } from '../../utils/getFileName';

export const MultiFileDownloadLink: FC<{ filePaths: string[] }> = ({
  filePaths,
  ...rest
}) => {
  const [showFileMenu, setShowFileMenu] = useState(false);

  if (filePaths.length === 0) {
    return null;
  }

  if (filePaths.length === 1) {
    return (
      <a {...rest} className="p-4" download href={filePaths[0]}>
        <FileIcon />
      </a>
    );
  }

  return (
    <div {...rest} className="relative">
      <button className="p-4" onClick={() => setShowFileMenu(!showFileMenu)}>
        <FileIcon />
      </button>
      {showFileMenu && (
        <Flyout
          arrowSide="top-right"
          color="uo-green"
          borderRadius="xl"
          className="absolute right-0"
          onClickOutside={() => setShowFileMenu(!showFileMenu)}
        >
          <ul>
            {filePaths.map((filePath, i) => (
              <li key={filePath} className={`w-48 ${i > 0 ? 'mt-4' : ''}`}>
                <a
                  download
                  href={filePath}
                  target="_blank"
                  rel="noreferrer"
                  className={`text-white py-4 row items-center opacity-90 hover:opacity-100`}
                >
                  <DownloadIcon />{' '}
                  <span className="ml-4">{getFileName(filePath)}</span>
                </a>
              </li>
            ))}
          </ul>
        </Flyout>
      )}
    </div>
  );
};
