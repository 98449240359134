import { isPast } from 'date-fns';
import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { isBlank } from 'shared/lib/utils/isBlank';
import { useApi } from '../../contexts/ApiContext';
import UofOLogoSrc from '../../images/univerity_of_oregon_o.svg';
import { Button } from '../Button/Button';
import { TextInput } from '../TextInput/TextInput';

export interface AcceptPasswordResetPageProps {
  email: string;
  expiresAt: string;
  code: string;
}

export const AcceptPasswordResetPage: FC<AcceptPasswordResetPageProps> = ({
  email,
  expiresAt,
  code,
  ...rest
}) => {
  const { acceptPasswordReset } = useApi();
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const codeIsExpired = isPast(new Date(expiresAt));

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        setSubmitting(true);
        if (isBlank(password)) {
          throw new Error('Password is required.');
        }
        if (password !== passwordConfirm) {
          throw new Error(`Passwords don't match`);
        }
        await acceptPasswordReset({ code, password });
        setError(null);
        setSuccess(true);
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [acceptPasswordReset, password, passwordConfirm, code],
  );

  return (
    <div {...rest} className="pt-12 pb-12 min-h-screen">
      <div className="container mx-auto px-4 w-100 max-w-full">
        <div className="row items-center justify-center">
          <a href="https://www.uoregon.edu/">
            <img src={UofOLogoSrc} alt="University of Oregon" />
          </a>
          <h2 className="text-xl pl-8 ml-8 border-l border-black">IPS Tool</h2>
        </div>
        <h1 className="text-2xl md:text-4xl text-center font-bold mt-16 md:mt-24 lg:mt-32">
          Reset Password
        </h1>
        {success ? (
          <div className="col justify-center text-center mt-16 md:mt-24 lg:mt-28">
            <p>Password reset successfully.</p>
            <Link to="/">
              <Button
                backgroundColor="uo-green"
                textColor="white"
                className="w-48 mt-12"
              >
                Login
              </Button>
            </Link>
          </div>
        ) : codeIsExpired ? (
          <div>
            <p>This password reset has expired.</p>
            <Link to="/">
              <Button
                backgroundColor="uo-green"
                textColor="white"
                className="w-48 mt-12"
              >
                Login
              </Button>
            </Link>
          </div>
        ) : (
          <form className="col mt-16 md:mt-24 lg:mt-28" onSubmit={handleSubmit}>
            {error && <p className="text-center text-red">{error}</p>}
            <label className="font-semibold mt-8">Email</label>
            <TextInput
              readOnly
              id="email"
              value={email}
              backgroundColor="gray"
              borderColor="gray"
              className="mt-3"
            />
            <label className="font-semibold mt-8">New password</label>
            <TextInput
              id="password"
              required
              autoComplete="new-password"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
              backgroundColor="white"
              borderColor="gray"
              className="mt-3"
            />
            <label className="font-semibold mt-8">New password (confirm)</label>
            <TextInput
              id="password-confirm"
              required
              autoComplete="new-password"
              type="password"
              value={passwordConfirm}
              onChange={(event) =>
                setPasswordConfirm(event.currentTarget.value)
              }
              backgroundColor="white"
              borderColor="gray"
              className="mt-3"
            />
            <Button
              disabled={submitting}
              textColor="white"
              backgroundColor="uo-green"
              className="w-48 mt-16 self-center"
            >
              Submit
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};
