/**
 * Pass as the `ref` to components you want to focus immediately when they render.
 * Example:
 *
 * ```tsx
 * <input ref={autoFocus} />
 * ```
 */
export function autoFocus(el: HTMLElement | null) {
  el?.focus();
}
