import React, { FC, ComponentPropsWithoutRef } from 'react';
import './flyout.css';
import { useOnClickOutside } from '../../utils/useOnClickOutside';

interface FlyoutProps extends ComponentPropsWithoutRef<'div'> {
  color: 'white' | 'uo-green' | 'uo-yellow';
  arrowSide:
    | 'top'
    | 'top-right'
    | 'top-left'
    | 'right'
    | 'right-top'
    | 'right-bottom'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-top'
    | 'left-bottom';
  borderRadius?: 'regular' | 'xl';
  onClickOutside?(): unknown;
}

export const Flyout: FC<FlyoutProps> = ({
  color,
  arrowSide,
  children,
  borderRadius = 'regular',
  className = '',
  onClickOutside,
  ...rest
}) => {
  const ref = useOnClickOutside<HTMLDivElement>(onClickOutside);

  return (
    <div {...rest} ref={ref} className={`absolute text-left z-10 ${className}`}>
      <div
        className={`relative p-4 bg-${color} ${
          borderRadius === 'xl' ? 'rounded-xl' : 'rounded'
        }`}
      >
        <div className={`absolute text-${color} flyout-triangle-${arrowSide}`}>
          <svg
            width="22"
            height="14"
            viewBox="0 0 22 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current"
          >
            <path d="M11.0002 5L16.1964 5L21.3925 14L0.60791 14L5.80406 5L11.0002 5Z" />
            <path d="M7.5359 2C9.0755 -0.666666 12.9245 -0.666666 14.4641 2L17.9282 8C19.4678 10.6667 17.5433 14 14.4641 14L7.5359 14C4.4567 14 2.5322 10.6667 4.0718 8L7.5359 2Z" />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};
