import React, { FC, useCallback } from 'react';
import { Button } from '../Button/Button';
import { Modal, ModalProps } from '../Modal/Modal';
import { ReactComponent as XCircleIcon } from '../../images/icons/x-circle.svg';

export interface ConfirmModalProps extends Omit<ModalProps, 'onClose'> {
  title: string;
  message: string;
  cancelButtonText?: string;
  confirmButtonText: string;
  onSubmit(confirmed: boolean): unknown;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  message,
  cancelButtonText = 'Cancel',
  confirmButtonText,
  onSubmit,
  className = '',
  ...rest
}) => {
  const confirm = useCallback(() => {
    onSubmit(true);
  }, [onSubmit]);

  const cancel = useCallback(() => {
    onSubmit(false);
  }, [onSubmit]);

  return (
    <Modal {...rest} className={`w-168 ${className}`} onClose={cancel}>
      <XCircleIcon className="w-16 h-16 flex-shrink-0 self-center text-red stroke-1" />
      <h1 className="text-4xl text-center mt-8">{title}</h1>
      <p className="mt-8 text-center px-8">{message}</p>

      <div className="row justify-center mt-16">
        <Button
          className="self-center w-48"
          textColor="black"
          backgroundColor="light-gray"
          onClick={cancel}
        >
          {cancelButtonText}
        </Button>
        <Button
          className="self-center w-48 ml-6"
          textColor="white"
          backgroundColor="red"
          onClick={confirm}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
};
