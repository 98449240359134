import React, { FC, useMemo } from 'react';
import useToggleState from '../../utils/react/useToggleState';
import { ReactComponent as MinusIcon } from '../../images/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../images/icons/plus.svg';
import {
  ProjectAssetListItem,
  ProjectAssetListItemProps,
} from './ProjectAssetListItem';
import { sortByDate } from 'shared/lib/utils/sort';
import { AccentBar } from '../AccentBar/AccentBar';
import { isAssetPending } from 'shared/lib/types/Asset';

export interface ProjectAssetListProps {
  title: string;
  className?: string;
  assets: ProjectAssetListItemProps['asset'][];
}

export const ProjectAssetList: FC<ProjectAssetListProps> = ({
  title,
  className = '',
  assets,
  ...rest
}) => {
  const [expanded, toggleExpanded] = useToggleState(false);
  const sortedAssets = useMemo(
    () =>
      [...assets].sort((a, b) =>
        sortByDate(new Date(b.createdAt), new Date(a.createdAt)),
      ),
    [assets],
  );
  const hasPendingAsset = sortedAssets.some(isAssetPending);

  if (!expanded) {
    return (
      <div
        {...rest}
        className={`relative bg-white shadow rounded px-5 py-6 ${className}`}
      >
        {hasPendingAsset && <AccentBar />}
        <div className="row justify-between items-center">
          <h5 className="font-semibold text-base">{title}</h5>
          <button onClick={toggleExpanded}>
            <PlusIcon className="w-5 h-5 round bg-uo-green rounded-full text-white" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      {...rest}
      className={`relative bg-white shadow rounded px-5 py-6 ${className}`}
    >
      <div className="row justify-between items-center mb-6">
        <h5 className="font-semibold text-base">{title}</h5>
        <button onClick={toggleExpanded}>
          <MinusIcon className="w-5 h-5 round bg-uo-green rounded-full text-white" />
        </button>
      </div>
      <ul>
        {sortedAssets.map((asset) => (
          <ProjectAssetListItem key={asset.id} asset={asset} />
        ))}
      </ul>
    </div>
  );
};
