import { FC, useCallback } from 'react';
import { useHistory, Route, Link } from 'react-router-dom';
import { AdminToolsNav } from './AdminToolsNav';
import { Button } from '../Button/Button';
import { CreateProjectRulesAgreementModal } from '../modals/projectRulesAgreementModals/CreateProjectRulesAgreementModal';
import { EditProjectRulesAgreementModal } from '../modals/projectRulesAgreementModals/EditProjectRulesAgreementModal';
import { ViewProjectRulesAgreementModal } from '../modals/projectRulesAgreementModals/ViewProjectRulesAgreementModal';
import { Nav } from '../Nav/Nav';
import { ProjectRulesAgreementTable } from '../ProjectRulesAgreementTable/ProjectRulesAgreementTable';
import { useProjectRulesAgreementList } from '../../contexts/ProjectRulesAgreementListContext';
import useAsyncEffect from '../../utils/react/useAsyncEffect';

export const ManageProjectRulesAgreementsPage: FC = (props) => {
  const {
    refresh: refreshProjectRulesAgreements,
  } = useProjectRulesAgreementList();
  const history = useHistory();

  useAsyncEffect(refreshProjectRulesAgreements, [
    refreshProjectRulesAgreements,
  ]);

  const closeModals = useCallback(() => {
    history.push('/admin/projectRulesAgreements');
  }, [history]);

  return (
    <div {...props} className="min-h-screen pt-16 pb-48 px-8 lg:px-20">
      <div className="container mx-auto max-w-full">
        <Nav tab="admin tools" />

        {/* Heading */}
        <div className="col md:flex-row md:items-center md:justify-between">
          <h1 className="text-6xl">Admin Tools</h1>
          <Link to="/admin/projectRulesAgreements/add">
            <Button
              className="w-48"
              backgroundColor="uo-green"
              textColor="white"
              size="large"
            >
              Add PRA
            </Button>
          </Link>
        </div>

        <AdminToolsNav tab="pras" className="mt-8" />

        <ProjectRulesAgreementTable className="mt-12" />
      </div>

      {/* PRA modals */}
      <Route path="/admin/projectRulesAgreements/add">
        <CreateProjectRulesAgreementModal onClose={closeModals} />
      </Route>

      <Route path="/admin/projectRulesAgreement/:projectRulesAgreementId/edit">
        {({ match }) =>
          match && (
            <EditProjectRulesAgreementModal
              onClose={closeModals}
              projectRulesAgreementId={+match.params.projectRulesAgreementId}
            />
          )
        }
      </Route>

      <Route path="/admin/projectRulesAgreement/:projectRulesAgreementId/view">
        {({ match }) =>
          match && (
            <ViewProjectRulesAgreementModal
              onClose={closeModals}
              projectRulesAgreementId={+match.params.projectRulesAgreementId}
            />
          )
        }
      </Route>
    </div>
  );
};
