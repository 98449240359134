import { FC } from 'react';
import { Link } from 'react-router-dom';
import { canUserViewAdminTools } from 'shared/lib/utils/permissions';
import { UserCircle, formatUserCircleName } from '../UserCircle/UserCircle';
import { ReactComponent as BellIcon } from '../../images/icons/bell.svg';
import { ReactComponent as EchelonLogo } from '../../images/echelon_logo.svg';
import { ReactComponent as ArrowLeftIcon } from '../../images/icons/arrow-left.svg';
import { Button } from '../Button/Button';
import { useAccount } from '../../contexts/AccountContext';
import { NotificationList } from '../NotificationList/NotificationList';
import useToggleState from '../../utils/react/useToggleState';
import { useNotifications } from '../../contexts/NotificationContext';
import { Dot } from '../Dot/Dot';

export interface NavProps {
  tab?: 'dashboard' | 'admin tools';
  showBackButton?: boolean;
  className?: string;
}

export const Nav: FC<NavProps> = ({
  className = '',
  tab,
  showBackButton = false,
  ...rest
}) => {
  const { user } = useAccount();
  const { hasUnviewedNotification } = useNotifications();
  const [showNotificationList, toggleNotificationList] = useToggleState(false);

  return (
    <div
      {...rest}
      className={`flex flex-col md:flex-row md:items-center md:justify-between flex-shrink-0 mb-20 md:h-10 ${className}`}
    >
      {showBackButton ? (
        <div className="row mb-4 md:mb-0 md:flex-grow md:w-1">
          <Link to="/">
            <Button backgroundColor="uo-green" textColor="white" size="small">
              <ArrowLeftIcon />
            </Button>
          </Link>
        </div>
      ) : (
        <ul className="row flex-shrink-0 flex-grow w-1">
          <li className="flex-shrink-0">
            <Link
              className={`col text-xl md:mr-10 lg:mr-20 ${
                tab !== 'dashboard' ? 'text-gray' : ''
              }`}
              to="/"
            >
              Dashboard
              {tab === 'dashboard' && (
                <div className="w-6 border-b-2 border-black" />
              )}
            </Link>
          </li>
          {user && canUserViewAdminTools(user) && (
            <li className="ml-8 md:ml-0 flex-shrink-0">
              <Link
                className={`col text-xl ${
                  tab !== 'admin tools' ? 'text-gray' : ''
                }`}
                to="/admin/users"
              >
                Admin Tools
                {tab === 'admin tools' && (
                  <div className="w-6 border-b-2 border-black" />
                )}
              </Link>
            </li>
          )}
        </ul>
      )}

      <h2 className="md:flex-grow md:w-1 md:text-center font-bold my-8 md:my-0 text-center">
        <EchelonLogo title="Echelon: PI" className="inline-block" />
      </h2>

      <div className="row mt-4 items-center md:flex-grow md:w-1 md:justify-end md:mt-0">
        <Link to="/profile" className="mr-10 row items-center">
          <UserCircle className="mr-3" color="uo-green">
            {user && formatUserCircleName(user.name)}
          </UserCircle>
          <div>{user?.name}</div>
        </Link>
        <div className="relative">
          <Button
            backgroundColor="none"
            size="small"
            textColor="black"
            className="relative"
            onClick={toggleNotificationList}
          >
            <BellIcon />
            {hasUnviewedNotification && (
              <Dot className="absolute right-2 top-2" />
            )}
          </Button>
          {showNotificationList && (
            <NotificationList
              onClose={toggleNotificationList}
              className="right-0 top-0 mt-16 -mr-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};
