import React, { FC } from 'react';
import { useDepartmentList } from '../../contexts/DepartmentListContext';
import { DepartmentListItem } from './DepartmentListItem';

export interface DepartmentListProps {
  className?: string;
}

export const DepartmentList: FC<DepartmentListProps> = ({
  className = '',
  ...rest
}) => {
  const { departments } = useDepartmentList();

  return (
    <ul {...rest} className={`col pl-6 pr-3 shadow rounded ${className}`}>
      {departments.map((department) => (
        <DepartmentListItem key={department.id} department={department} />
      ))}
    </ul>
  );
};
