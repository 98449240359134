export function formatTime(date: Date | string): string {
  const dateObject = new Date(date);
  const hours24 = dateObject.getHours();
  const hours12 = hours24 % 12 || 12;
  const minutes = dateObject.getMinutes();
  const isPm = hours24 >= 12;

  return `${hours12 < 10 ? `0${hours12}` : hours12}:${
    minutes < 10 ? `0${minutes}` : minutes
  } ${isPm ? 'PM' : 'AM'}`;
}
