import { FC, FormEvent, useState, useCallback } from 'react';
import { Modal } from '../../Modal/Modal';
import { Select } from '../../Select/Select';
import { useProjectRulesAgreementList } from '../../../contexts/ProjectRulesAgreementListContext';
import { Button } from '../../Button/Button';
import { canUserManageProjectRulesAgreements } from 'shared/lib/utils/permissions';
import { useAccount } from '../../../contexts/AccountContext';

export interface ProjectRulesAgreementInputModalProps {
  title: string;
  submitButtonText: string;
  initialValue?: number | null;
  onClose(): unknown;
  onSubmit(projectRulesAgreementId: number): unknown;
}

export const ProjectRulesAgreementInputModal: FC<ProjectRulesAgreementInputModalProps> = ({
  title,
  submitButtonText,
  initialValue = null,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { user } = useAccount();
  const {
    projectRulesAgreements,
    publicProjectRulesAgreements,
  } = useProjectRulesAgreementList();
  const [value, setValue] = useState<number | null>(initialValue);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!value) {
        return;
      }
      try {
        setSubmitting(true);
        await onSubmit(value);
        onClose();
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, onClose, value],
  );

  return (
    <Modal
      {...rest}
      className="w-168"
      showSpinner={submitting}
      onClose={onClose}
    >
      <form className="col" onSubmit={handleSubmit}>
        <h1 className="text-4xl text-center">{title}</h1>
        <label className="mb-3 font-semibold mt-16">PRA</label>
        {projectRulesAgreements.length > 0 && (
          <Select
            value={value}
            onValueChange={(newValue) => setValue(newValue ? +newValue : null)}
            options={(user && canUserManageProjectRulesAgreements(user)
              ? projectRulesAgreements
              : publicProjectRulesAgreements
            ).map((projectRulesAgreement) => ({
              value: projectRulesAgreement.id.toString(),
              label: projectRulesAgreement.name,
            }))}
          />
        )}
        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="w-48 mt-16 self-center"
        >
          {submitButtonText}
        </Button>
      </form>
    </Modal>
  );
};
