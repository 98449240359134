import React, { FC, useCallback, useMemo } from 'react';
import { CollegeModal, CollegeModalValue } from './CollegeModal';
import { useCollegeList } from '../../../contexts/CollegeListContext';

export interface EditCollegeModalProps {
  collegeId: number;
  onClose(): unknown;
}

export const EditCollegeModal: FC<EditCollegeModalProps> = ({
  collegeId,
  onClose,
  ...rest
}) => {
  const { colleges, updateCollege } = useCollegeList();
  const initialValue: CollegeModalValue | undefined = useMemo(() => {
    return colleges.find((college) => college.id === collegeId);
  }, [colleges, collegeId]);

  const handleSubmit = useCallback(
    async (value: CollegeModalValue) => {
      await updateCollege({ ...value, collegeId });
      onClose();
    },
    [updateCollege, onClose, collegeId],
  );

  return (
    <CollegeModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit College"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
