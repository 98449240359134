import { FC, useCallback, useMemo } from 'react';
import { areAssetsLoaded } from 'shared/lib/utils/projectUtils';
import { useProject } from '../../../contexts/ProjectContext';
import { USE_LOCAL_FILE_UPLOAD } from '../../../env';
import { getFileName } from '../../../utils/getFileName';
import {
  AssetModal,
  AssetModalValue,
  getAssetModalValueFromAsset,
} from './AssetModal';

export interface EditAssetModalProps {
  assetId: number;
  onClose(): unknown;
}

export const EditAssetModal: FC<EditAssetModalProps> = ({
  assetId,
  onClose,
  ...rest
}) => {
  const { project, updateAsset, uploadAssetFile } = useProject();
  const initialValue: AssetModalValue | undefined = useMemo(() => {
    if (project && areAssetsLoaded(project)) {
      const asset = project.assets.find((asset) => asset.id === assetId);
      return asset && getAssetModalValueFromAsset(asset);
    }
  }, [project, assetId]);

  const handleSubmit = useCallback(
    async ({ files, ...rest }: AssetModalValue) => {
      const filesToUpload = files.filter(
        (file): file is File => file instanceof File,
      );

      if (filesToUpload.length > 0) {
        await Promise.all(
          filesToUpload.map((file) =>
            uploadAssetFile({
              assetId,
              file,
              useLocalFileUpload: USE_LOCAL_FILE_UPLOAD,
            }),
          ),
        );
      }

      await updateAsset({
        ...rest,
        assetId,
        filePaths: files.map(getFileName),
      });
      onClose();
    },
    [updateAsset, uploadAssetFile, onClose, assetId],
  );

  if (project && !areAssetsLoaded(project)) {
    // Assets not available for this project
    // TODO: add an error modal?
    return null;
  }

  return (
    <AssetModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit Asset"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
