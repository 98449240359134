import { FC, useMemo } from 'react';
import { ProjectMemberWithUserAndRole } from 'shared/lib/types/ProjectMember';
import { sortAlphabetically } from 'shared/lib/utils/sort';
import { ProjectMemberListItem } from './ProjectMemberListItem';
import { ReactComponent as HelpCircleIcon } from '../../images/icons/help-circle.svg';
import { Button } from '../Button/Button';
import { Flyout } from '../Flyout/Flyout';

export interface ProjectMemberListProps {
  title: string;
  className?: string;
  helpText?: string;
  members: (Pick<ProjectMemberWithUserAndRole, 'id'> & {
    user: Pick<ProjectMemberWithUserAndRole['user'], 'id' | 'name'>;
  })[];
}

export const ProjectMemberList: FC<ProjectMemberListProps> = ({
  title,
  members,
  helpText,
  className = '',
  ...rest
}) => {
  const sortedMembers = useMemo(
    () =>
      [...members].sort((a, b) => sortAlphabetically(a.user.name, b.user.name)),
    [members],
  );

  return (
    <div {...rest} className={`bg-white shadow rounded py-5 ${className}`}>
      <div className="row items-center mb-2 pl-5 pr-3">
        <h5 className="font-bold mb-2">{title}</h5>
        {!!helpText && (
          <div className="flex-shrink-0 relative ml-4">
            <Button backgroundColor="none" textColor="black" size="small">
              <HelpCircleIcon />
            </Button>
            <Flyout
              arrowSide="top-left"
              color="uo-yellow"
              className="adjacent-hover-visible absolute top-0 -left-2 mt-12 w-100"
            >
              <h4 className="font-semibold">{title}</h4>
              <p className="mt-3">{helpText}</p>
            </Flyout>
          </div>
        )}
      </div>
      <ul>
        {sortedMembers.map((member) => (
          <ProjectMemberListItem key={member.id} member={member} />
        ))}
      </ul>
    </div>
  );
};
