import React, { FC } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { ReactComponent as AirplaneIcon } from '../../images/icons/send.svg';
import { ProjectMemberWithUserAndRole } from 'shared/lib/types/ProjectMember';
import { Flyout } from '../Flyout/Flyout';
import { useProject } from '../../contexts/ProjectContext';
import { showConfirm } from '../ConfirmModal/showConfirm';
import { Link } from 'react-router-dom';
import useToggleState from '../../utils/react/useToggleState';
import { formatDate } from '../../utils/formatDate';
import { useAccount } from '../../contexts/AccountContext';
import {
  canUserDeleteMember,
  canUserUpdateMember,
} from 'shared/lib/utils/permissions';
import { AccentBar } from '../AccentBar/AccentBar';
import { areMembersLoaded } from 'shared/lib/utils/projectUtils';

export interface ProjectMemberListItemProps {
  className?: string;
  member: Pick<ProjectMemberWithUserAndRole, 'id'> & {
    user: Pick<ProjectMemberWithUserAndRole['user'], 'id' | 'name'>;
  };
}

export const ProjectMemberListItem: FC<ProjectMemberListItemProps> = ({
  member,
  className = '',
  ...rest
}) => {
  const { user } = useAccount();
  const { projectId, project, deleteProjectMember } = useProject();
  const [showMenu, toggleMenu] = useToggleState();
  const praSignature = project?.rulesAgreementSignatures?.find(
    (signature) =>
      signature.userId === member.user.id &&
      signature.projectRulesAgreementId === project?.projectRulesAgreementId,
  );
  const showEditButton = !!(
    user &&
    project &&
    areMembersLoaded(project) &&
    canUserUpdateMember(user, project)
  );
  const showDeleteButton = !!(
    user &&
    project &&
    areMembersLoaded(project) &&
    canUserDeleteMember(user, project)
  );
  const showMenuButton = showEditButton || showDeleteButton;

  return (
    <li
      {...rest}
      className={`relative row justify-between items-center text-sm pl-5 pr-3 ${className}`}
    >
      {!praSignature && <AccentBar flipped />}
      <div className="flex-shrink-0">{member.user.name}</div>
      <div className="flex-shrink-0 row items-center w-56 h-16 whitespace-nowrap justify-between">
        <div
          className={`row items-center ${
            praSignature ? 'text-gray-800' : 'text-berry'
          } mr-6`}
        >
          PRA:&nbsp;
          {praSignature ? (
            <span>
              Signed - <time>{formatDate(praSignature.createdAt)}</time>
            </span>
          ) : (
            <div className="row items-center">
              Unsigned <AirplaneIcon className="ml-4" />
            </div>
          )}
        </div>
        <div className="relative">
          {showMenuButton && (
            <Button
              backgroundColor="none"
              textColor="uo-green"
              size="small"
              onClick={toggleMenu}
            >
              <MoreIcon />
            </Button>
          )}
          {showMenu && showMenuButton && (
            <Flyout
              arrowSide="top-right"
              color="uo-green"
              borderRadius="xl"
              className="w-48 top-0 right-0 mt-12 -mr-2"
              onClickOutside={toggleMenu}
            >
              {showEditButton && (
                <Link
                  to={`/project/${projectId}/members/${member.user.id}/edit`}
                >
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    className="row items-center px-0"
                    onClick={toggleMenu}
                  >
                    <EditIcon className="mr-3" /> Edit Member
                  </Button>
                </Link>
              )}
              {showDeleteButton && (
                <Button
                  backgroundColor="none"
                  textColor="white"
                  className="row items-center px-0"
                  onClick={async () => {
                    toggleMenu();
                    if (
                      await showConfirm({
                        title: `Remove Member`,
                        message: `You are about to remove this user “${member.user.name}” from project name “${project?.name}.” Are you sure wish to remove ${member.user.name}?`,
                        confirmButtonText: 'Remove Member',
                      })
                    ) {
                      await deleteProjectMember({
                        userId: member.user.id,
                      });
                    }
                  }}
                >
                  <DeleteIcon className="mr-3" /> Remove Member
                </Button>
              )}
            </Flyout>
          )}
        </div>
      </div>
    </li>
  );
};
