import { FC, useCallback } from 'react';
import ReactSelect from 'react-select';

export const Select: FC<{
  options: { value: string; label: string }[];
  disabled?: boolean;
  className?: string;
  value?: string | number | null | undefined;
  placeholder?: string;
  onChange?(event?: { value: string; label: string }): unknown;
  onValueChange?(value: string | null): unknown;
}> = ({
  options,
  value,
  disabled,
  placeholder,
  onChange,
  onValueChange,
  ...rest
}) => {
  const valueString = value?.toString();
  const selectedOption = valueString
    ? options.find((option) => option.value === valueString)
    : undefined;

  const handleChange = useCallback(
    (event: { label: string; value: string } | undefined) => {
      if (onChange) {
        onChange(event);
      }
      if (onValueChange) {
        onValueChange(event?.value ?? null);
      }
    },
    [onChange, onValueChange],
  );

  return (
    <ReactSelect
      {...rest}
      value={selectedOption ?? null}
      onChange={handleChange as any}
      isDisabled={disabled}
      options={options}
      placeholder={placeholder}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: '#F2F2F2',
          borderRadius: '0.25rem',
          border: 'none',
          padding: '0.5rem',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'black',
        }),
        indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: 'black',
          transform: 'scale(1.25)',
        }),
      }}
    />
  );
};
