const escapeCharacters = [
  '\\',
  '`',
  '*',
  '_',
  '{',
  '}',
  '[',
  ']',
  '<',
  '>',
  '(',
  ')',
  '#',
  '+',
  '-',
  '.',
  '!',
  '|',
];

/**
 * Escapes any markdown control characters in a string, so they appear
 * as-is in markdown without accidentally causing bold/italics/linethrough etc.
 */
export function escapeMarkdown(value: string): string {
  let result = value;
  for (const c of escapeCharacters) {
    result = result.replaceAll(c, `\\${c}`);
  }
  return result;
}
