import { FC } from 'react';
import { Asset } from 'shared/lib/types/Asset';
import { AssetStatus } from 'shared/lib/types/AssetStatus';
import { Button } from '../Button/Button';
import { Flyout } from '../Flyout/Flyout';
import { useProject } from '../../contexts/ProjectContext';
import { ReactComponent as LinkIcon } from '../../images/icons/link-2.svg';
import { ReactComponent as ErrorPageIcon } from '../../images/error-page.svg';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/info.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { Link } from 'react-router-dom';
import { showConfirm } from '../ConfirmModal/showConfirm';
import { showRejectAssetModal } from '../modals/assetModals/RejectAssetModal';
import useToggleState from '../../utils/react/useToggleState';
import { useAccount } from '../../contexts/AccountContext';
import { canUserApproveAsset } from 'shared/lib/utils/permissions';
import { areMembersLoaded } from 'shared/lib/utils/projectUtils';
import { MultiFileDownloadLink } from '../MultiFileDownloadLink/MultiFileDownloadLink';
import { getAssetFileUrl } from 'shared/lib/utils/getAssetFileUrl';

export interface ProjectAssetListItemProps {
  className?: string;
  asset: Pick<
    Asset,
    | 'id'
    | 'projectId'
    | 'name'
    | 'description'
    | 'originStory'
    | 'url'
    | 'status'
    | 'rejectMessage'
    | 'createdAt'
    | 'filePaths'
  >;
}

export const ProjectAssetListItem: FC<ProjectAssetListItemProps> = ({
  className = '',
  asset,
  ...rest
}) => {
  const { user } = useAccount();
  const { projectId, project, deleteAsset, updateAsset } = useProject();
  const [showMenu, toggleMenu] = useToggleState();
  const showApproveRejectButtons = !!(
    asset.status === AssetStatus.PENDING &&
    user &&
    project &&
    areMembersLoaded(project) &&
    canUserApproveAsset(user, project)
  );

  return (
    <li {...rest} className={`col border-gray-500 border-t py-4 ${className}`}>
      <div className="row justify-between items-center">
        <h6>{asset.name}</h6>
        <div className="row items-center">
          <a href={asset.url} target="_blank" rel="noreferrer" className="p-4">
            <LinkIcon />
          </a>
          <MultiFileDownloadLink
            filePaths={asset.filePaths.map((filePath) =>
              getAssetFileUrl(asset, filePath),
            )}
          />
          <Button backgroundColor="none" textColor="black">
            <ErrorPageIcon />
          </Button>
          <div className="relative">
            <Button
              backgroundColor="none"
              textColor="uo-green"
              onClick={toggleMenu}
            >
              <MoreIcon />
            </Button>
            {showMenu && (
              <Flyout
                arrowSide="top-right"
                color="uo-green"
                borderRadius="xl"
                className="w-48 top-0 right-0 mt-16"
                onClickOutside={toggleMenu}
              >
                <Link to={`/project/${projectId}/asset/${asset.id}/details`}>
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    className="row items-center px-0"
                    onClick={toggleMenu}
                  >
                    <InfoIcon className="mr-3" /> View Details
                  </Button>
                </Link>
                <Link to={`/project/${projectId}/asset/${asset.id}/edit`}>
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    className="row items-center px-0"
                    onClick={toggleMenu}
                  >
                    <EditIcon className="mr-3" /> Edit Asset
                  </Button>
                </Link>
                <Button
                  backgroundColor="none"
                  textColor="white"
                  className="row items-center px-0"
                  onClick={async () => {
                    toggleMenu();
                    if (
                      await showConfirm({
                        title: 'Delete Asset',
                        message: project
                          ? `You are about to delete this asset for project name “${project.name}.” Are you sure you wish to delete “${asset.name}”?`
                          : '',
                        confirmButtonText: 'Delete',
                      })
                    ) {
                      await deleteAsset({
                        assetId: asset.id,
                      });
                    }
                  }}
                >
                  <DeleteIcon className="mr-3" /> Delete Asset
                </Button>
              </Flyout>
            )}
          </div>
        </div>
      </div>
      <p className="text-sm">{asset.description}</p>

      {/* Approve/Reject buttons */}
      {showApproveRejectButtons && (
        <div className="row justify-center mt-6">
          <Button
            backgroundColor="uo-green"
            textColor="white"
            size="small"
            className="w-0 flex-grow mr-6"
            onClick={async () => {
              await updateAsset({
                assetId: asset.id,
                status: AssetStatus.APPROVED,
              });
            }}
          >
            Approve
          </Button>
          <Button
            backgroundColor="red"
            textColor="white"
            size="small"
            className="w-0 flex-grow"
            onClick={async () => {
              const rejectMessage = await showRejectAssetModal({
                projectName: project?.name ?? '',
                asset,
              });
              if (!rejectMessage) {
                return;
              }
              await updateAsset({
                assetId: asset.id,
                status: AssetStatus.REJECTED,
                rejectMessage,
              });
            }}
          >
            Reject
          </Button>
        </div>
      )}

      {asset.status === AssetStatus.APPROVED && (
        <div className="text-center text-uo-green font-semibold mt-8 mb-2">
          Asset Approved
        </div>
      )}

      {asset.status === AssetStatus.REJECTED && (
        <Link
          to={`/project/${projectId}/asset/${asset.id}/rejected`}
          className="text-center text-red font-semibold mt-6 pt-2 pb-2"
        >
          Asset Rejected
        </Link>
      )}
    </li>
  );
};
