import React, { FC, useCallback } from 'react';
import { ProjectModal, ProjectModalValue } from './ProjectModal';
import { useProjectList } from '../../../contexts/ProjectListContext';
import { EndpointProps } from 'shared/lib/api';

export interface CreateProjectModalProps {
  onClose(): unknown;
}

export const CreateProjectModal: FC<CreateProjectModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createProject } = useProjectList();

  const handleSubmit = useCallback(
    async (value: ProjectModalValue) => {
      await createProject(value as EndpointProps<'createProject'>);
      onClose();
    },
    [createProject, onClose],
  );

  return (
    <ProjectModal
      {...rest}
      title="Create a Project"
      submitButtonText="Create Project"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
