import { FC, useMemo } from 'react';
import { areAssetsLoaded } from 'shared/lib/utils/projectUtils';
import { useProject } from '../../../contexts/ProjectContext';
import {
  AssetModal,
  AssetModalValue,
  getAssetModalValueFromAsset,
} from './AssetModal';

export interface AssetDetailsModalProps {
  assetId: number;
  onClose(): unknown;
}

export const AssetDetailsModal: FC<AssetDetailsModalProps> = ({
  assetId,
  onClose,
  ...rest
}) => {
  const { project } = useProject();
  const initialValue: AssetModalValue | undefined = useMemo(() => {
    if (project && areAssetsLoaded(project)) {
      const asset = project.assets.find((asset) => asset.id === assetId);
      return asset && getAssetModalValueFromAsset(asset);
    }
  }, [project, assetId]);

  if (project && !areAssetsLoaded(project)) {
    return null;
  }

  return (
    <AssetModal
      {...rest}
      readOnly
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Asset Details"
      onClose={onClose}
    />
  );
};
