import { FC, useState, useCallback, useEffect } from 'react';
import useAsyncEffect from '../../../utils/react/useAsyncEffect';
import { AuditLog } from 'shared/lib/types/AuditLog';
import { ReactComponent as DownloadIcon } from '../../../images/icons/download.svg';
import { useProject } from '../../../contexts/ProjectContext';
import { Modal, ModalProps } from '../../Modal/Modal';
import { formatDate } from '../../../utils/formatDate';
import { formatTime } from '../../../utils/formatTime';
import { SpinnerOverlay } from '../../SpinnerOverlay/SpinnerOverlay';

export const ProjectAuditLogModal: FC<ModalProps> = (props) => {
  const now = new Date();
  const { projectId, project, getAuditLogs } = useProject();
  const [logListEl, setLogListEl] = useState<HTMLOListElement | null>(null);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState<Error | null>(null);

  const refresh = useCallback(
    async (isCancelled?: () => boolean) => {
      try {
        const fetchedAuditLogs = await getAuditLogs();
        if (!isCancelled || !isCancelled()) {
          setAuditLogs(fetchedAuditLogs);
        }
      } catch (error) {
        console.error(error);
        if (!isCancelled || !isCancelled()) {
          setLoadError(error);
        }
      } finally {
        if (!isCancelled || !isCancelled()) {
          setLoading(false);
        }
      }
    },
    [getAuditLogs],
  );

  useAsyncEffect(refresh, [refresh]);

  useEffect(() => {
    if (!loading && logListEl) {
      logListEl.scrollTo({ top: logListEl.scrollHeight });
    }
  }, [loading, logListEl]);

  return (
    <Modal {...props} className="relative w-168 lg:w-200 text-left">
      {loading && <SpinnerOverlay />}
      <h1 className="pt-4 font-bold text-lg">Project log</h1>

      <ol
        className="h-100 bg-gray-100 rounded overflow-auto list-none p-4 mt-4"
        ref={setLogListEl}
      >
        {loadError && (
          <li>
            <p className="text-red mt-4">
              Failed to load logs: {loadError.message}
            </p>
          </li>
        )}
        {auditLogs.map((log, i) => (
          <li key={log.id} className={i > 0 ? 'mt-4' : ''}>
            <span className="inline-block text-gray-500 w-40">
              {formatDate(log.createdAt)} {formatTime(log.createdAt)}
            </span>
            {log.message}
          </li>
        ))}
      </ol>
      <div className="row mt-4 justify-end">
        <a
          className="row items-center"
          download={`${project?.name ?? 'project'}_logs_${formatDate(
            now,
            '-',
          )}.csv`}
          href={`/api/project/${projectId}/auditLog?format=CSV`}
        >
          Download <DownloadIcon className="ml-4" />
        </a>
      </div>
    </Modal>
  );
};
