import { FC, useState } from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import { Modal } from '../../Modal/Modal';
import { TextArea } from '../../TextArea/TextArea';
import { Button } from '../../Button/Button';
import showModal from '../../../utils/react/showModal';
import { Asset } from 'shared/lib/types/Asset';

export interface RejectAssetModalProps {
  projectName: string;
  asset: Pick<Asset, 'originStory'>;
  onClose(): unknown;
  onSubmit(rejectionReason: string): unknown;
  className?: string;
}

export const RejectAssetModal: FC<RejectAssetModalProps> = ({
  projectName,
  asset,
  onSubmit,
  className = '',
  ...rest
}) => {
  const [rejectionReason, setRejectionReason] = useState('');
  const [showOriginStory, setShowOriginStory] = useState(
    !isBlank(asset.originStory),
  );

  if (showOriginStory) {
    return (
      <Modal {...rest} className={`w-168 h-120 ${className}`}>
        <h1 className="text-4xl text-center">Asset origin</h1>
        <p className="mt-8 mx-8 flex-grow">{asset.originStory}</p>
        <Button
          className="w-48 mt-8 flex-shrink-0 self-center"
          backgroundColor="uo-green"
          textColor="white"
          onClick={() => setShowOriginStory(false)}
        >
          Continue
        </Button>
      </Modal>
    );
  }

  return (
    <Modal {...rest} className={`w-168 h-120 ${className}`}>
      <h1 className="text-4xl text-center">Reject Asset</h1>
      <p className="mt-8 mx-8">
        You are about to reject a proposed asset for project name “{projectName}
        .” Please state your reason for rejection.
      </p>
      <TextArea
        placeholder="Type Here"
        value={rejectionReason}
        onTextChange={setRejectionReason}
        className="mt-3 mx-8 flex-grow"
      />
      <div className="row justify-center flex-shrink-0 mt-8 items-end">
        <Button
          className="self-center w-48"
          textColor="black"
          backgroundColor="light-gray"
          onClick={rest.onClose}
        >
          Cancel
        </Button>
        <Button
          className="self-center w-48 ml-6"
          textColor="white"
          backgroundColor="red"
          disabled={isBlank(rejectionReason)}
          onClick={() => onSubmit(rejectionReason)}
        >
          Reject
        </Button>
      </div>
    </Modal>
  );
};

export async function showRejectAssetModal(
  props: Omit<RejectAssetModalProps, 'onSubmit' | 'onClose'>,
): Promise<string | null> {
  return showModal((resolve) => (
    <RejectAssetModal
      {...props}
      onSubmit={(reason) => resolve(reason)}
      onClose={() => resolve(null)}
    />
  ));
}
