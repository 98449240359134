import { useState, useCallback, FormEventHandler } from "react";

type InputEventHandler = FormEventHandler<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

/**
 * A hook for keeping track of input state. Works just like `useState`, but the returned
 * function will automatically pull the value from input events. A second function is returned that
 * allows the value to be set directly.
 *
 * Example:
 *
 * ```tsx
 * const [value, handleValueChange, setValue] = useInputState()
 * return (
 *   <>
 *     <input value={value} onChange={handleValueChange}/>
 *     <input value={value} onChange={event => setValue(event.currentTarget.value)}/>
 *   </>
 * );
 * ```
 */
export default function useInputState(
  initialValue: string | (() => string) = ""
): [string, InputEventHandler, (value: string) => void] {
  const [value, setValue] = useState<string>(initialValue);

  const handleEvent: InputEventHandler = useCallback((event) => {
    setValue(event.currentTarget.value);
  }, []);

  return [value, handleEvent, setValue];
}
