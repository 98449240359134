import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import replaceWhere from 'shared/lib/utils/array/replaceWhere';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { Api, EndpointProps } from 'shared/lib/api';
import { Department } from 'shared/lib/types/Department';
import { missingReactContext } from 'shared/lib/utils/errors';
import { EndpointResult } from 'shared/lib/api';
import { useApi } from './ApiContext';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface DepartmentListContextValue {
  loading: boolean;
  departments: Department[];
  createDepartment: Api['createDepartment'];
  updateDepartment: Api['updateDepartment'];
  deleteDepartment: Api['deleteDepartment'];
}

const DepartmentListContext = createContext<DepartmentListContextValue | null>(
  null,
);

export const DepartmentListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState<
    DepartmentListContextValue['departments']
  >([]);
  const sortedDepartments = useMemo(
    () => [...departments].sort((a, b) => sortAlphabetically(a.name, b.name)),
    [departments],
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedDepartments = await api.listDepartments();

      if (!isCancelled()) {
        setDepartments(fetchedDepartments);
        setLoading(false);
      }
    },
    [api],
  );

  const createDepartment = useCallback(
    async (
      inputs: EndpointProps<'createDepartment'>,
    ): Promise<EndpointResult<'createDepartment'>> => {
      const department = await api.createDepartment(inputs);
      setDepartments((value) => [...value, department]);
      return department;
    },
    [api],
  );

  const updateDepartment = useCallback(
    async (
      inputs: EndpointProps<'updateDepartment'>,
    ): Promise<EndpointResult<'updateDepartment'>> => {
      const department = await api.updateDepartment(inputs);
      setDepartments((value) =>
        replaceWhere(
          value,
          (other) => other.id === department.id,
          () => department,
        ),
      );
      return department;
    },
    [api],
  );

  const deleteDepartment = useCallback(
    async (
      inputs: EndpointProps<'deleteDepartment'>,
    ): Promise<EndpointResult<'deleteDepartment'>> => {
      await api.deleteDepartment(inputs);
      setDepartments((value) =>
        value.filter((other) => other.id !== inputs.departmentId),
      );
    },
    [api],
  );

  const value = useMemo<DepartmentListContextValue>(
    () => ({
      loading,
      departments: sortedDepartments,
      createDepartment,
      updateDepartment,
      deleteDepartment,
    }),
    [
      loading,
      sortedDepartments,
      createDepartment,
      updateDepartment,
      deleteDepartment,
    ],
  );

  return (
    <DepartmentListContext.Provider value={value}>
      {children}
    </DepartmentListContext.Provider>
  );
};

export function useDepartmentList(): DepartmentListContextValue {
  return (
    useContext(DepartmentListContext) ??
    missingReactContext('DepartmentListProvider', 'useDepartmentList')
  );
}
