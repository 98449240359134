import { useRef, useEffect } from 'react';

export function useOnClickOutside<T extends HTMLElement>(
  onClickOutside: (() => unknown) | null | undefined,
) {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (!onClickOutside) {
      return;
    }
    function handleClick(event: MouseEvent | TouchEvent) {
      if (
        onClickOutside &&
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node)
      ) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [onClickOutside]);

  return ref;
}
