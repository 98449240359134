import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import replaceWhere from 'shared/lib/utils/array/replaceWhere';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { Api, EndpointProps } from 'shared/lib/api';
import { College } from 'shared/lib/types/College';
import { missingReactContext } from 'shared/lib/utils/errors';
import { EndpointResult } from 'shared/lib/api/index';
import { useApi } from './ApiContext';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface CollegeListContextValue {
  loading: boolean;
  colleges: College[];
  createCollege: Api['createCollege'];
  updateCollege: Api['updateCollege'];
  deleteCollege: Api['deleteCollege'];
}

const CollegeListContext = createContext<CollegeListContextValue | null>(null);

export const CollegeListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [colleges, setColleges] = useState<CollegeListContextValue['colleges']>(
    [],
  );
  const sortedColleges = useMemo(
    () => [...colleges].sort((a, b) => sortAlphabetically(a.name, b.name)),
    [colleges],
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedColleges = await api.listColleges();

      if (!isCancelled()) {
        setColleges(fetchedColleges);
        setLoading(false);
      }
    },
    [api],
  );

  const createCollege = useCallback(
    async (
      inputs: EndpointProps<'createCollege'>,
    ): Promise<EndpointResult<'createCollege'>> => {
      const college = await api.createCollege(inputs);
      setColleges((value) => [...value, college]);
      return college;
    },
    [api],
  );

  const updateCollege = useCallback(
    async (
      inputs: EndpointProps<'updateCollege'>,
    ): Promise<EndpointResult<'updateCollege'>> => {
      const college = await api.updateCollege(inputs);
      setColleges((value) =>
        replaceWhere(
          value,
          (other) => other.id === college.id,
          () => college,
        ),
      );
      return college;
    },
    [api],
  );

  const deleteCollege = useCallback(
    async (
      inputs: EndpointProps<'deleteCollege'>,
    ): Promise<EndpointResult<'deleteCollege'>> => {
      await api.deleteCollege(inputs);
      setColleges((value) =>
        value.filter((other) => other.id !== inputs.collegeId),
      );
    },
    [api],
  );

  const value = useMemo<CollegeListContextValue>(
    () => ({
      loading,
      colleges: sortedColleges,
      createCollege,
      updateCollege,
      deleteCollege,
    }),
    [loading, sortedColleges, createCollege, updateCollege, deleteCollege],
  );

  return (
    <CollegeListContext.Provider value={value}>
      {children}
    </CollegeListContext.Provider>
  );
};

export function useCollegeList(): CollegeListContextValue {
  return (
    useContext(CollegeListContext) ??
    missingReactContext('CollegeListProvider', 'useCollegeList')
  );
}
