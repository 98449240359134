import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { User } from 'shared/lib/types/User';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface StaffManagerListContextValue {
  loading: boolean;
  staffManagers: User[];
}

const StaffManagerListContext = createContext<StaffManagerListContextValue | null>(
  null,
);

export const StaffManagerListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [staffManagers, setStaffManagers] = useState<
    StaffManagerListContextValue['staffManagers']
  >([]);
  const sortedStaffManagers = useMemo(
    () => [...staffManagers].sort((a, b) => sortAlphabetically(a.name, b.name)),
    [staffManagers],
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedStaffManagers = await api.listStaffManagers();

      if (!isCancelled()) {
        setStaffManagers(fetchedStaffManagers);
        setLoading(false);
      }
    },
    [api],
  );

  const value = useMemo<StaffManagerListContextValue>(
    () => ({
      loading,
      staffManagers: sortedStaffManagers,
    }),
    [loading, sortedStaffManagers],
  );

  return (
    <StaffManagerListContext.Provider value={value}>
      {children}
    </StaffManagerListContext.Provider>
  );
};

export function useStaffManagerList(): StaffManagerListContextValue {
  return (
    useContext(StaffManagerListContext) ??
    missingReactContext('StaffManagerListProvider', 'useStaffManagerList')
  );
}
