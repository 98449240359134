import React, { FC } from 'react';
import { useProjectRulesAgreementList } from '../../contexts/ProjectRulesAgreementListContext';
import { ProjectRulesAgreementTableRow } from './ProjectRulesAgreementTableRow';

export interface ProjectRulesAgreementTableProps {
  className?: string;
}

export const ProjectRulesAgreementTable: FC<ProjectRulesAgreementTableProps> = ({
  className = '',
  ...rest
}) => {
  const { projectRulesAgreements } = useProjectRulesAgreementList();

  return (
    <div {...rest} className={`col ${className}`}>
      <div className="row pb-3 px-5">
        <div className="w-0 flex-grow-3 text-xl font-bold mr-4">PRA</div>
        <div className="w-0 flex-grow text-xl font-bold mr-4 text-center">
          View PRA
        </div>
        <div className="w-0 flex-grow text-xl font-bold mr-4 text-center">
          Visibility
        </div>
        <div className="w-0 flex-grow text-xl font-bold mr-4 text-center">
          Active Projects
        </div>
        <div className="w-0 flex-grow text-xl font-bold">
          {/* More button column */}
        </div>
      </div>
      <ul className="col px-5 bg-white text-gray-800 text-sm rounded shadow-6xl">
        {projectRulesAgreements.map((projectRulesAgreement) => (
          <ProjectRulesAgreementTableRow
            key={projectRulesAgreement.id}
            projectRulesAgreement={projectRulesAgreement}
          />
        ))}
      </ul>
    </div>
  );
};
