import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';
import React, { FC, useCallback, useMemo } from 'react';
import ReactDatepicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../images/icons/calendar.svg';

export interface DateInputProps {
  value?: string | null;
  onChange?: (value: string | null) => any;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
  className = '',
  ...rest
}) => {
  const dateObject = useMemo(() => {
    if (value) {
      // Only return the date object if it is a valid date
      const date = new Date(value);
      if (!Number.isNaN(date.getTime())) {
        return date;
      }
    }
    return null;
  }, [value]);

  const handleChange = useCallback(
    (date: Date | null) => {
      if (onChange) {
        onChange(date ? date.toISOString() : null);
      }
    },
    [onChange],
  );

  return (
    <div {...rest} className={`relative row flex-shrink-0 ${className}`}>
      <ReactDatepicker
        wrapperClassName={className}
        selected={dateObject}
        onChange={handleChange}
        placeholderText={placeholder}
        disabled={disabled}
        dateFormat="M/d/yy"
        className="w-full flex-shrink-0"
        customInput={
          <input className="rounded h-12 flex-shrink-0 p-4 w-full bg-light-gray" />
        }
      />
      <div className="absolute right-0 top-0 pointer-events-none mt-3 mr-4">
        <CalendarIcon />
      </div>
    </div>
  );
};
