import { FC } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { UserWithRoleAndProjectCount } from 'shared/lib/types/User';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { showConfirm } from '../ConfirmModal/showConfirm';
import { Flyout } from '../Flyout/Flyout';
import useToggleState from '../../utils/react/useToggleState';
import { Link } from 'react-router-dom';
import { useUserList } from '../../contexts/UserListContext';

export interface UserTableRowProps {
  user: UserWithRoleAndProjectCount;
  className?: string;
}

export const UserTableRow: FC<UserTableRowProps> = ({
  user,
  className = '',
  ...rest
}) => {
  const { deleteUser } = useUserList();
  const [showMenu, toggleMenu] = useToggleState();

  return (
    <li {...rest} className={`row items-center py-8 ${className}`}>
      <div className="w-0 flex-grow-3 mr-4 font-semibold">{user.name}</div>
      <div className="w-0 flex-grow-4 mr-4 break-all">{user.email}</div>
      <div className="w-0 flex-grow-2 mr-4">{user.role.name}</div>
      <div className="w-0 flex-grow-2 mr-4">{user.affiliation}</div>
      <div className="w-0 flex-grow-2 mr-4 text-center text-3xl">
        {user.activeProjectCount}
      </div>
      <div className="w-0 flex-grow row justify-end">
        <div className="relative">
          <Button
            backgroundColor="none"
            textColor="uo-green"
            size="small"
            onClick={toggleMenu}
          >
            <MoreIcon />
          </Button>
          {showMenu && (
            <Flyout
              arrowSide="top-right"
              color="uo-green"
              borderRadius="xl"
              className="w-52 top-0 right-0 mt-12 -mr-2"
              onClickOutside={toggleMenu}
            >
              <Link to={`/admin/user/${user.id}/edit`}>
                <Button
                  backgroundColor="none"
                  textColor="white"
                  size="small"
                  className="row items-center px-0 mb-3"
                >
                  <EditIcon className="mr-3" /> Edit User
                </Button>
              </Link>
              <Button
                backgroundColor="none"
                textColor="white"
                size="small"
                className="row items-center px-0"
                onClick={async () => {
                  if (
                    await showConfirm({
                      title: 'Delete User',
                      message: `You are about to delete user “${user.name}.” Are you sure you wish to delete “${user.name}”?`,
                      confirmButtonText: 'Delete',
                    })
                  ) {
                    await deleteUser({ userId: user.id });
                  }
                }}
              >
                <DeleteIcon className="mr-3" /> Delete User
              </Button>
            </Flyout>
          )}
        </div>
      </div>
    </li>
  );
};
