import { FC, ReactNode } from 'react';

export const Breadcrumbs: FC<{
  count: number;
  index: number;
  className?: string;
  onChange(newIndex: number): unknown;
}> = ({ count, index, className = '', onChange, ...rest }) => {
  const breadcrumbs: ReactNode[] = [];

  for (let i = 0; i < count; i++) {
    const active = i === index;
    breadcrumbs.push(
      <button
        key={i}
        disabled={active}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (!active) {
            onChange(i);
          }
        }}
        className={`p-2 ${active ? 'cursor-default' : 'cursor-pointer'}`}
        aria-label={`Jump to step ${i + 1}`}
      >
        <div
          className={`rounded-full w-3 h-3 ${
            active ? 'bg-uo-yellow' : 'bg-light-gray'
          }`}
        />
      </button>,
    );
  }

  return (
    <div {...rest} className={`row ${className}`}>
      {breadcrumbs}
    </div>
  );
};
