import React, {
  FC,
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { UserWithRole } from 'shared/lib/types/User';
import { Api, EndpointProps, EndpointResult } from 'shared/lib/api/index';
import { missingReactContext } from 'shared/lib/utils/errors';
import { LoginCredential } from 'shared/lib/types/LoginCredential';
import { useApi } from './ApiContext';

interface AccountContextValue {
  loading: boolean;
  user: UserWithRole | null;
  login: Api['login'];
  logout: Api['logout'];
  updateProfile: Api['updateProfile'];
}

const AccountContext = createContext<AccountContextValue | null>(null);

export const AccountProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<AccountContextValue['user']>(null);

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedUser = await api.getCurrentUser().catch((error) => {
        console.error('Failed to get user', error);
        return null;
      });
      if (!isCancelled()) {
        setUser(fetchedUser);
        setLoading(false);
      }
    },
    [api],
  );

  const login = useCallback(
    async (credential: LoginCredential) => {
      const user = await api.login(credential);
      setUser(user);
      return user;
    },
    [api],
  );

  const logout = useCallback(async () => {
    await api.logout();
    setUser(null);
  }, [api]);

  const updateProfile = useCallback(
    async (
      inputs: EndpointProps<'updateProfile'>,
    ): Promise<EndpointResult<'updateProfile'>> => {
      await api.updateProfile(inputs);
      setUser(
        (user) =>
          user && {
            ...user,
            email: inputs.email ?? user.email,
            name: inputs.name ?? user.name,
          },
      );
    },
    [api],
  );

  const value = useMemo<AccountContextValue>(
    () => ({ loading, user, login, logout, updateProfile }),
    [loading, user, login, logout, updateProfile],
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export function useAccount(): AccountContextValue {
  return (
    useContext(AccountContext) ??
    missingReactContext('AccountProvider', 'useAccount')
  );
}
