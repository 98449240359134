import { FC, FormEvent, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isBlank } from 'shared/lib/utils/isBlank';
import { Nav } from '../Nav/Nav';
import { useAccount } from '../../contexts/AccountContext';
import { TextInput } from '../TextInput/TextInput';
import { SpinnerOverlay } from '../SpinnerOverlay/SpinnerOverlay';
import { Button } from '../Button/Button';
import { getErrorMessage } from '../../utils/getErrorMessage';

export const EditProfilePage: FC = (props) => {
  const history = useHistory();
  const { user, updateProfile, logout } = useAccount();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const canSubmit = !!(
    user &&
    !submitting &&
    (user.name !== name || user.email !== email || password.length)
  );

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user?.email);
    }
  }, [user]);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();
        setSubmitting(true);
        if (!isBlank(password) && password !== passwordConfirm) {
          throw new Error(`Passwords don't match.`);
        }
        await updateProfile({
          name,
          email,
          password: password.length > 0 ? password : undefined,
        });
        setSubmitSuccess(true);
        setSubmitError(null);
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [updateProfile, name, email, password, passwordConfirm],
  );

  const handleLogoutClick = useCallback(async () => {
    await logout();
    history.push('/');
  }, [logout, history]);

  if (!user) {
    return (
      <div {...props} className="min-h-screen py-16 px-8 lg:px-20">
        <SpinnerOverlay />
      </div>
    );
  }

  return (
    <div {...props} className="min-h-screen pt-16 pb-48 px-8 lg:px-20">
      <div className="container mx-auto max-w-full col">
        <Nav showBackButton />
        <h1 className="text-6xl">My Profile</h1>
        <h2>{user.name}</h2>
        <form
          className="relative col w-100 max-w-full mx-auto flex-shrink-0 mt-32"
          onSubmit={handleSubmit}
        >
          {submitting && <SpinnerOverlay />}
          <label className="font-semibold flex-shrink-0">Name</label>
          <TextInput
            value={name}
            onTextChange={setName}
            className="mt-3 mb-8"
          />
          <label className="font-semibold flex-shrink-0">Email</label>
          <TextInput
            value={email}
            onTextChange={setEmail}
            className="mt-3 mb-8"
          />
          <label className="font-semibold flex-shrink-0">Password</label>
          <TextInput
            type="password"
            value={password}
            onTextChange={setPassword}
            className="mt-3 mb-8"
          />
          <label className="font-semibold flex-shrink-0">
            Password (confirmed)
          </label>
          <TextInput
            type="password"
            value={passwordConfirm}
            onTextChange={setPasswordConfirm}
            className="mt-3 mb-8"
          />
          {submitError && (
            <p className="my-4 text-red text-center text-lg">
              {getErrorMessage(submitError)}
            </p>
          )}
          {submitSuccess && (
            <p className="my-4 text-green-600 text-center text-lg">
              Saved successfully
            </p>
          )}
          <Button
            backgroundColor="uo-green"
            textColor="white"
            disabled={!canSubmit}
            className="w-48 mt-12 self-center"
          >
            Save
          </Button>
        </form>
        <Button
          backgroundColor="red"
          textColor="white"
          className="w-48 mt-12 self-center"
          onClick={handleLogoutClick}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};
