import React, { FC, useCallback, useMemo } from 'react';
import { ProgramModal, ProgramModalValue } from './ProgramModal';
import { useProgramList } from '../../../contexts/ProgramListContext';

export interface EditProgramModalProps {
  programId: number;
  onClose(): unknown;
}

export const EditProgramModal: FC<EditProgramModalProps> = ({
  programId,
  onClose,
  ...rest
}) => {
  const { programs, updateProgram } = useProgramList();
  const initialValue: ProgramModalValue | undefined = useMemo(() => {
    return programs.find((program) => program.id === programId);
  }, [programs, programId]);

  const handleSubmit = useCallback(
    async (value: ProgramModalValue) => {
      await updateProgram({ ...value, programId });
      onClose();
    },
    [updateProgram, onClose, programId],
  );

  return (
    <ProgramModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit Program"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
