import { FC, ComponentPropsWithoutRef } from 'react';
import classnames from 'classnames';

const buttonSizeMap: Record<ButtonSize, string> = {
  small: 'text-sm p-2',
  regular: 'p-4',
  large: 'p-6',
};

type ButtonSize = 'small' | 'regular' | 'large';

interface CustomButtonProps {
  backgroundColor:
    | 'uo-green'
    | 'uo-yellow'
    | 'lime-green'
    | 'berry'
    | 'red'
    | 'light-gray'
    | 'none';
  textColor: 'white' | 'black' | 'gray' | 'uo-green';
  fontWeight?: 'normal' | 'bold';
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
}
export interface ButtonProps
  extends ComponentPropsWithoutRef<'button'>,
    CustomButtonProps {}

export const Button: FC<ButtonProps> = ({
  size,
  backgroundColor,
  textColor,
  className,
  fontWeight,
  disabled,
  ...rest
}) => (
  <button
    {...rest}
    disabled={disabled}
    className={getButtonClassnames({
      size,
      backgroundColor,
      textColor,
      className,
      fontWeight,
      disabled,
    })}
  />
);

export function getButtonClassnames({
  size = 'regular',
  backgroundColor,
  textColor,
  className,
  fontWeight,
  disabled,
}: CustomButtonProps): string {
  return classnames(
    `rounded transition-all text-center text-${textColor} ${buttonSizeMap[size]}`,
    // Set background color, hover background color, and add transition
    backgroundColor !== 'none' &&
      `bg-${backgroundColor} hover:bg-${backgroundColor}-highlight transition duration-200`,
    // Add disabled button styles (gray background and text with no hover effect and a default cursor)
    backgroundColor !== 'none' &&
      disabled &&
      'bg-light-gray hover:bg-light-gray text-gray cursor-default',
    fontWeight && `font-${fontWeight}`,
    className,
  );
}
