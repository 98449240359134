import React, { FC } from 'react';
import { useCollegeList } from '../../contexts/CollegeListContext';
import { CollegeListItem } from './CollegeListItem';

export interface CollegeListProps {
  className?: string;
}

export const CollegeList: FC<CollegeListProps> = ({
  className = '',
  ...rest
}) => {
  const { colleges } = useCollegeList();

  return (
    <ul {...rest} className={`col pl-6 pr-3 shadow rounded ${className}`}>
      {colleges.map((college) => (
        <CollegeListItem key={college.id} college={college} />
      ))}
    </ul>
  );
};
