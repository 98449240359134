import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import uniqBy from 'lodash.uniqby';
import replaceWhere from 'shared/lib/utils/array/replaceWhere';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { UserWithRoleAndProjectCount } from 'shared/lib/types/User';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';
import { Api, EndpointProps, EndpointResult } from 'shared/lib/api';
import { removeById } from 'shared/lib/utils/removeById';

interface UserListContextValue {
  loading: boolean;
  users: UserWithRoleAndProjectCount[];
  searchText: string;
  isLastPage: boolean;
  loadNextPage(): Promise<void>;
  getUserById: Api['getUserById'];
  createUser: Api['createUser'];
  updateUser: Api['updateUser'];
  deleteUser: Api['deleteUser'];
  setSearchText(searchText: string): void;
}

const UserListContext = createContext<UserListContextValue | null>(null);

export const UserListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [users, setUsers] = useState<UserListContextValue['users']>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(true);

  useAsyncEffect(
    async (isCancelled) => {
      const response = await api.listUsers({ page: 0 });
      if (!isCancelled()) {
        setUsers(response.users);
        setIsLastPage(response.isLastPage);
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    // Skip on first mount
    if (loading) {
      return;
    }
    const timeout = setTimeout(async () => {
      setLoading(true);
      const response = await api.listUsers({ page: 0, searchText });
      setPageNumber(0);
      setUsers(response.users);
      setIsLastPage(response.isLastPage);
      setLoading(false);
    }, 500 /* Debounce the request when the search text changes. */);
    return () => clearTimeout(timeout);

    // Needed to avoid adding `loading` to the dependencies which would cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchText]);

  const { getUserById } = api;

  const createUser = useCallback(
    async (
      inputs: EndpointProps<'createUser'>,
    ): Promise<EndpointResult<'createUser'>> => {
      const newUser = await api.createUser(inputs);
      setUsers((value) => [...value, { ...newUser, activeProjectCount: 0 }]);
      return newUser;
    },
    [api],
  );

  const updateUser = useCallback(
    async (
      inputs: EndpointProps<'updateUser'>,
    ): Promise<EndpointResult<'updateUser'>> => {
      const updatedUser = await api.updateUser(inputs);
      setUsers((value) =>
        replaceWhere(
          value,
          (other) => other.id === inputs.userId,
          (other) => ({ ...other, ...updatedUser }),
        ),
      );
      return updatedUser;
    },
    [api],
  );

  const deleteUser = useCallback(
    async (
      inputs: EndpointProps<'deleteUser'>,
    ): Promise<EndpointResult<'deleteUser'>> => {
      await api.deleteUser(inputs);
      setUsers((value) => removeById(value, inputs.userId));
    },
    [api],
  );

  const loadNextPage = useCallback(async () => {
    setPageNumber(pageNumber + 1);
    const response = await api.listUsers({ page: pageNumber + 1 });
    setUsers((prevUsers) => uniqBy([...prevUsers, ...response.users], 'id'));
    setIsLastPage(response.isLastPage);
  }, [api, pageNumber]);

  const value = useMemo<UserListContextValue>(
    () => ({
      loading,
      users,
      isLastPage,
      searchText,
      setSearchText,
      createUser,
      updateUser,
      deleteUser,
      getUserById,
      loadNextPage,
    }),
    [
      loading,
      users,
      isLastPage,
      searchText,
      setSearchText,
      createUser,
      updateUser,
      deleteUser,
      getUserById,
      loadNextPage,
    ],
  );

  return (
    <UserListContext.Provider value={value}>
      {children}
    </UserListContext.Provider>
  );
};

export function useUserList(): UserListContextValue {
  return (
    useContext(UserListContext) ??
    missingReactContext('UserListProvider', 'useUserList')
  );
}
