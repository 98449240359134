import { FC } from 'react';
import { Link } from 'react-router-dom';
import { RestrictedDetailedProject } from 'shared/lib/types/Project';
import {
  getUnsignedRulesAgreementCount,
  getPendingAssetCount,
  getAssetCount,
  getProjectMemberNames,
} from 'shared/lib/utils/projectUtils';
import { UserCircle, formatUserCircleName } from '../UserCircle/UserCircle';
import { formatDate } from '../../utils/formatDate';

export interface ProjectCardProps {
  project: RestrictedDetailedProject;
  className?: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({
  project,
  className = '',
  ...rest
}) => {
  const pendingAssetCount = getPendingAssetCount(project);
  const unsignedRulesAgreementCount = getUnsignedRulesAgreementCount(project);
  const projectMemberNames = getProjectMemberNames(project);

  return (
    <Link
      {...rest}
      to={`/project/${project.id}`}
      className={`rounded shadow-6xl overflow-hidden bg-white col flex-shrink-0 ${className}`}
    >
      <div className="flex-grow p-4 sm:p-6">
        <div className="row justify-between items-center">
          <h2 className="font-bold text-xl truncate overflow-ellipsis">
            {project.name}
          </h2>
          <time className="text-sm">{formatDate(project.createdAt)}</time>
        </div>
        <h3 className="mb-6 text-sm">Member</h3>
        <div className="row mb-4">
          <div className="col flex-grow">
            <h4 className="mb-3">Members:</h4>
            <div className="row">
              {projectMemberNames.slice(0, 3).map((memberName, i) => (
                <UserCircle
                  key={i}
                  color={i % 2 === 0 ? 'uo-green' : 'lime-green'}
                  className={i > 0 ? '-ml-2' : ''}
                >
                  {formatUserCircleName(memberName)}
                </UserCircle>
              ))}
              {projectMemberNames.length > 3 && (
                <UserCircle color="lime-green" className="-ml-2">
                  +{projectMemberNames.length - 3}
                </UserCircle>
              )}
            </div>
          </div>
          <div className="col flex-grow items-center">
            <h4 className="mb-1">Assets:</h4>
            <div className="text-4xl font-bold">{getAssetCount(project)}</div>
          </div>
        </div>
        <div className="self-center flex-shrink-0 text-center mb-6">
          {project.staffManager && (
            <h4 className="text-sm font-bold">{project.staffManager.name}</h4>
          )}
          <ul>
            {project.programs.map((program) => (
              <li key={program.id}>{program.name}</li>
            ))}
          </ul>
        </div>
        <div className="row justify-between text-center text-sm">
          <div className="w-0 col flex-grow items-center">
            <h4 className="italic text-sm">College</h4>
            <ul>
              {project.colleges.map((college) => (
                <li key={college.id}>{college.name}</li>
              ))}
            </ul>
          </div>
          <div className="w-0 col flex-grow items-center">
            <h4 className="italic text-sm">Department</h4>
            <ul>
              {project.departments.map((department) => (
                <li key={department.id}>{department.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {(unsignedRulesAgreementCount > 0 || pendingAssetCount > 0) &&
        project.projectRulesAgreementId && (
          <div className="bg-berry flex-shrink-0 text-white text-center p-6">
            {unsignedRulesAgreementCount > 0 && (
              <span>
                ({unsignedRulesAgreementCount}) PRA Unsigned
                {pendingAssetCount > 0 && '; '}
              </span>
            )}
            {pendingAssetCount > 0 && (
              <span>({pendingAssetCount}) Asset Approval</span>
            )}
          </div>
        )}
    </Link>
  );
};
