import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '../Button/Button';

export interface AdminToolsNavProps {
  className?: string;
  tab: 'users' | 'pras' | 'college info';
}

export const AdminToolsNav: FC<AdminToolsNavProps> = ({
  className = '',
  tab,
  ...rest
}) => (
  <div {...rest} className={`row ${className}`}>
    <Link to="/admin/users" className="mr-4">
      <Button {...getTabButtonProps(tab === 'users')} className="px-6 py-3">
        Users
      </Button>
    </Link>
    <Link to="/admin/projectRulesAgreements" className="mr-4">
      <Button {...getTabButtonProps(tab === 'pras')} className="px-6 py-3">
        PRAs
      </Button>
    </Link>
    <Link to="/admin/colleges">
      <Button
        {...getTabButtonProps(tab === 'college info')}
        className="px-6 py-3"
      >
        College Info
      </Button>
    </Link>
  </div>
);

function getTabButtonProps(
  active: boolean,
): Pick<ButtonProps, 'backgroundColor' | 'textColor' | 'fontWeight'> {
  return active
    ? {
        backgroundColor: 'uo-yellow',
        textColor: 'black',
        fontWeight: 'bold',
      }
    : {
        backgroundColor: 'none',
        textColor: 'gray',
        fontWeight: 'normal',
      };
}
