import React, { FC, FormEvent, useState, useCallback } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { isBlank } from 'shared/lib/utils/isBlank';
import UofOLogoSrc from '../../images/univerity_of_oregon_o.svg';
import { Button, getButtonClassnames } from '../Button/Button';
import { TextInput } from '../TextInput/TextInput';
import { useAccount } from '../../contexts/AccountContext';

export interface LoginPageProps {
  className?: string;
}

export const LoginPage: FC<LoginPageProps> = ({ className, ...rest }) => {
  const { login } = useAccount();
  const [email, setEmail] = useState(
    process.env.REACT_APP_LOGIN_PAGE_INITIAL_EMAIL ?? '',
  );
  const [password, setPassword] = useState(
    process.env.REACT_APP_LOGIN_PAGE_INITIAL_PASSWORD ?? '',
  );
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        setSubmitting(true);
        if (isBlank(email)) {
          throw new Error('Email is required.');
        }
        if (isBlank(password)) {
          throw new Error('Password is required.');
        }
        await login({ username: email, password });
        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [login, email, password],
  );

  return (
    <div
      {...rest}
      className={classnames(`pt-12 pb-12 min-h-screen`, className)}
    >
      <div className="container mx-auto px-4 w-100 max-w-full">
        <div className="row items-center justify-center mb-16 md:mb-24 lg:mb-32">
          <a href="https://www.uoregon.edu/">
            <img src={UofOLogoSrc} alt="University of Oregon" />
          </a>
          <h2 className="text-xl pl-8 ml-8 border-l border-black">
            Echelon: PI
          </h2>
        </div>
        <h1 className="text-2xl md:text-4xl text-center font-bold mb-16 md:mb-24 lg:mb-32">
          Welcome back
        </h1>
        <form className="col" onSubmit={handleSubmit}>
          {error && <p className="mb-8 text-center text-red">{error}</p>}
          <label className="font-semibold mb-3">Email</label>
          <TextInput
            id="email"
            required
            autoComplete="username"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            backgroundColor="white"
            borderColor="gray"
            className="mb-8"
          />
          <label className="font-semibold mb-3">Password</label>
          <TextInput
            id="password"
            required
            autoComplete="current-password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            backgroundColor="white"
            borderColor="gray"
            className="mb-16"
          />
          <div className="row mb-8">
            <Button
              disabled={submitting}
              textColor="white"
              backgroundColor="uo-green"
              className="w-48 mr-4"
            >
              Login
            </Button>
            <a
              className={`w-48 ${getButtonClassnames({
                textColor: 'black',
                backgroundColor: 'uo-yellow',
              })}`}
              href="/api/saml/login"
              onClick={(event) => event.stopPropagation()}
            >
              UO Login
            </a>
          </div>
          <Link to="/forgot-password" className="self-center">
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
};
