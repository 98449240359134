import React, { FC } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { showConfirm } from '../ConfirmModal/showConfirm';
import { Flyout } from '../Flyout/Flyout';
import useToggleState from '../../utils/react/useToggleState';
import { Link } from 'react-router-dom';
import { Department } from 'shared/lib/types/Department';
import { useDepartmentList } from '../../contexts/DepartmentListContext';

export interface DepartmentListItemProps {
  department: Department;
  className?: string;
}

export const DepartmentListItem: FC<DepartmentListItemProps> = ({
  department,
  className = '',
  ...rest
}) => {
  const { deleteDepartment } = useDepartmentList();
  const [showMenu, toggleMenu] = useToggleState();

  return (
    <li
      {...rest}
      className={`row items-center justify-between py-3 ${className}`}
    >
      <div className="text-sm font-semibold text-gray-800 flex-grow">
        {department.name}
      </div>
      <div className="relative flex-shrink-0">
        <Button
          backgroundColor="none"
          textColor="uo-green"
          size="small"
          onClick={toggleMenu}
        >
          <MoreIcon />
        </Button>
        {showMenu && (
          <Flyout
            arrowSide="top-right"
            color="uo-green"
            borderRadius="xl"
            className="w-52 top-0 right-0 mt-12 -mr-2"
            onClickOutside={toggleMenu}
          >
            <Link to={`/admin/department/${department.id}/edit`}>
              <Button
                backgroundColor="none"
                textColor="white"
                size="small"
                className="row items-center px-0 mb-3"
                onClick={toggleMenu}
              >
                <EditIcon className="mr-3" /> Edit Department
              </Button>
            </Link>
            <Button
              backgroundColor="none"
              textColor="white"
              size="small"
              className="row items-center px-0"
              onClick={async () => {
                toggleMenu();
                if (
                  await showConfirm({
                    title: 'Delete Department',
                    message: `You are about to delete Department “${department.name}.” Are you sure you wish to delete this department?`,
                    confirmButtonText: 'Delete',
                  })
                ) {
                  await deleteDepartment({ departmentId: department.id });
                }
              }}
            >
              <DeleteIcon className="mr-3" /> Delete Department
            </Button>
          </Flyout>
        )}
      </div>
    </li>
  );
};
