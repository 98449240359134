import { FC, useCallback, useMemo } from 'react';
import {
  ProjectRulesAgreementModal,
  ProjectRulesAgreementModalValue,
} from './ProjectRulesAgreementModal';
import { useProjectRulesAgreementList } from '../../../contexts/ProjectRulesAgreementListContext';

export interface EditProjectRulesAgreementModalProps {
  projectRulesAgreementId: number;
  onClose(): unknown;
}

export const EditProjectRulesAgreementModal: FC<EditProjectRulesAgreementModalProps> = ({
  projectRulesAgreementId,
  onClose,
  ...rest
}) => {
  const {
    projectRulesAgreements,
    updateProjectRulesAgreement,
  } = useProjectRulesAgreementList();
  const initialValue:
    | ProjectRulesAgreementModalValue
    | undefined = useMemo(() => {
    return projectRulesAgreements.find(
      (projectRulesAgreement) =>
        projectRulesAgreement.id === projectRulesAgreementId,
    );
  }, [projectRulesAgreements, projectRulesAgreementId]);

  const handleSubmit = useCallback(
    async (value: ProjectRulesAgreementModalValue) => {
      await updateProjectRulesAgreement({ ...value, projectRulesAgreementId });
      onClose();
    },
    [updateProjectRulesAgreement, onClose, projectRulesAgreementId],
  );

  return (
    <ProjectRulesAgreementModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit PRA"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
