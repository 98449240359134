import React, { FC, ComponentPropsWithoutRef } from 'react';
import classnames from 'classnames';

export interface TextInputProps extends ComponentPropsWithoutRef<'input'> {
  borderColor?: 'gray' | 'none';
  backgroundColor?: 'gray' | 'white';
  onTextChange?: (text: string) => unknown;
  inputRef?: (el: HTMLInputElement | null) => unknown;
}

export const TextInput: FC<TextInputProps> = ({
  borderColor = 'none',
  backgroundColor = 'gray',
  className,
  children,
  inputRef,
  onChange,
  onTextChange,
  ...rest
}) => (
  <div className={classnames('relative text-left', className)}>
    <input
      {...rest}
      ref={inputRef}
      className={classnames(
        'rounded h-12 flex-shrink-0 p-4 text-align-inherit w-full',
        borderColor === 'gray' ? 'border-gray border' : null,
        backgroundColor === 'gray' ? 'bg-light-gray' : 'white',
      )}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        if (onTextChange) {
          onTextChange(event.currentTarget.value);
        }
      }}
    />
    {/* Icon container */}
    <div className="absolute right-0 top-0 mt-3 mr-4 pointer-events-none">
      {children}
    </div>
  </div>
);
