import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { UserRole } from 'shared/lib/types/UserRole';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface UserRoleListContextValue {
  loading: boolean;
  userRoles: UserRole[];
  selectableUserRoles: UserRole[];
}

const UserRoleListContext = createContext<UserRoleListContextValue | null>(
  null,
);

export const UserRoleListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [userRoles, setUserRoles] = useState<
    UserRoleListContextValue['userRoles']
  >([]);
  const sortedUserRoles = useMemo(
    () => [...userRoles].sort((a, b) => sortAlphabetically(a.name, b.name)),
    [userRoles],
  );
  const selectableUserRoles = useMemo(
    () => sortedUserRoles.filter((role) => role.selectable),
    [sortedUserRoles],
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedUserRoles = await api.listUserRoles();

      if (!isCancelled()) {
        setUserRoles(fetchedUserRoles);
        setLoading(false);
      }
    },
    [api],
  );

  const value = useMemo<UserRoleListContextValue>(
    () => ({
      loading,
      selectableUserRoles,
      userRoles: sortedUserRoles,
    }),
    [loading, sortedUserRoles, selectableUserRoles],
  );

  return (
    <UserRoleListContext.Provider value={value}>
      {children}
    </UserRoleListContext.Provider>
  );
};

export function useUserRoleList(): UserRoleListContextValue {
  return (
    useContext(UserRoleListContext) ??
    missingReactContext('UserRoleListProvider', 'useUserRoleList')
  );
}
