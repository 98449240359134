import { FC, FormEvent, useState, useCallback } from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import { CheckBox } from '../../CheckBox/CheckBox';
import { Modal, ModalProps } from '../../Modal/Modal';
import { SpinnerOverlay } from '../../SpinnerOverlay/SpinnerOverlay';
import { Button } from '../../Button/Button';
import { TextInput } from '../../TextInput/TextInput';
import { TextArea } from '../../TextArea/TextArea';
import { DateInput } from '../../DateInput/DateInput';
import { getErrorMessage } from '../../../utils/getErrorMessage';

export interface ObligationModalValue {
  name: string;
  sponsor: string;
  description: string;
  internal: boolean;
  grantNumber: string;
  startDate: string | null;
  endDate: string | null;
}

export interface ObligationModalProps extends ModalProps {
  title: string;
  initialValue?: ObligationModalValue;
  showSpinner?: boolean;
  readOnly?: boolean;
  submitButtonText?: string;
  onSubmit?(value: ObligationModalValue): unknown;
}

export const ObligationModal: FC<ObligationModalProps> = ({
  title,
  initialValue,
  showSpinner,
  readOnly,
  submitButtonText,
  onSubmit,
  ...rest
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [name, setName] = useState(initialValue?.name ?? '');
  const [sponsor, setSponsor] = useState<string>(initialValue?.sponsor ?? '');
  const [description, setDescription] = useState(
    initialValue?.description ?? '',
  );
  const [internal, setInternal] = useState(initialValue?.internal ?? false);
  const [grantNumber, setGrantNumber] = useState(
    initialValue?.grantNumber ?? '',
  );
  const [startDate, setStartDate] = useState<string | null>(
    initialValue?.startDate ?? null,
  );
  const [endDate, setEndDate] = useState<string | null>(
    initialValue?.endDate ?? null,
  );
  const canSubmit = !submitting && !isBlank(name);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (readOnly) {
        return;
      }
      setSubmitting(true);
      try {
        if (onSubmit) {
          await onSubmit({
            name,
            sponsor,
            description,
            internal,
            grantNumber,
            startDate,
            endDate,
          });
        }
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [
      onSubmit,
      name,
      sponsor,
      description,
      internal,
      grantNumber,
      startDate,
      endDate,
      readOnly,
    ],
  );

  return (
    <Modal {...rest} className="relative w-168 text-left">
      <form className="col" onSubmit={handleSubmit}>
        {(submitting || showSpinner) && (
          <SpinnerOverlay
            message={submitting && `Saving obligation`}
            className="rounded-xl"
          />
        )}
        <h1 className="text-4xl text-center font-normal">{title}</h1>
        <div className="row justify-center mt-16">
          <div
            className="row items-center"
            onClick={() => !readOnly && setInternal(!internal)}
          >
            <CheckBox
              disabled={readOnly}
              checked={internal}
              onChange={setInternal}
            />
            <label className={`ml-3 ${readOnly ? '' : 'cursor-pointer'}`}>
              Internal Fund
            </label>
          </div>
        </div>

        <div className="row mt-8">
          <div className="w-0 flex-grow">
            <label className="font-semibold">Obligation Title*</label>
            <TextInput
              value={name}
              onTextChange={setName}
              disabled={readOnly}
              className="mt-3"
            />
          </div>
          <div className="w-0 flex-grow ml-6">
            <label className="font-semibold">Sponsor*</label>
            <TextInput
              value={sponsor}
              onTextChange={setSponsor}
              disabled={readOnly}
              className="mt-3"
            />
          </div>
        </div>

        <div className="row mt-8">
          <div className="col w-0 flex-grow mr-6">
            <label className="font-semibold">Grant #</label>
            <TextInput
              value={grantNumber}
              onTextChange={setGrantNumber}
              disabled={readOnly}
              className="mt-3"
            />
          </div>
          <div className="col w-0 flex-grow">
            <label className="font-semibold">Dates</label>
            <div className="row mt-3">
              <DateInput
                value={startDate}
                onChange={setStartDate}
                disabled={readOnly}
                placeholder="start"
                className="w-0 flex-grow"
              />
              <DateInput
                value={endDate}
                onChange={setEndDate}
                disabled={readOnly}
                placeholder="end"
                className="w-0 flex-grow ml-3"
              />
            </div>
          </div>
        </div>

        <label className="mt-8 font-semibold">Obligation Description</label>
        <TextArea
          value={description}
          onTextChange={setDescription}
          disabled={readOnly}
          className="mt-3"
          placeholder="Description Goes Here"
        />

        {submitError && (
          <p className="mt-8 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}

        {!readOnly && (
          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="mt-16 w-48 self-center"
            disabled={!canSubmit}
          >
            {submitButtonText}
          </Button>
        )}
      </form>
    </Modal>
  );
};
