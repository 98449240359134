import {
  FC,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { EndpointProps, EndpointResult } from 'shared/lib/api/index';
import { replaceById } from 'shared/lib/utils/replaceById';
import { removeById } from 'shared/lib/utils/removeById';
import { missingReactContext } from 'shared/lib/utils/errors';
import { RestrictedDetailedProject } from 'shared/lib/types/Project';
import { AuditLog } from 'shared/lib/types/AuditLog';
import { useApi } from './ApiContext';
import { ProjectMemberWithUserAndRole } from 'shared/lib/types/ProjectMember';
import { useAccount } from './AccountContext';
import {
  areAssetsLoaded,
  areMembersLoaded,
  areObligationsLoaded,
} from 'shared/lib/utils/projectUtils';

interface ProjectContextValue {
  loading: boolean;
  projectId: number;
  project: RestrictedDetailedProject | null;
  member: ProjectMemberWithUserAndRole | null;
  updateProject(
    props: Omit<EndpointProps<'updateProject'>, 'projectId'>,
  ): Promise<EndpointResult<'updateProject'>>;
  createObligation(
    props: Omit<EndpointProps<'createObligation'>, 'projectId'>,
  ): Promise<EndpointResult<'createObligation'>>;
  updateObligation(
    props: Omit<EndpointProps<'updateObligation'>, 'projectId'>,
  ): Promise<EndpointResult<'updateObligation'>>;
  deleteObligation(
    props: Omit<EndpointProps<'deleteObligation'>, 'projectId'>,
  ): Promise<EndpointResult<'deleteObligation'>>;
  createAsset(
    props: Omit<EndpointProps<'createAsset'>, 'projectId'>,
  ): Promise<EndpointResult<'createAsset'>>;
  updateAsset(
    props: Omit<EndpointProps<'updateAsset'>, 'projectId'>,
  ): Promise<EndpointResult<'updateAsset'>>;
  deleteAsset(
    props: Omit<EndpointProps<'deleteAsset'>, 'projectId'>,
  ): Promise<EndpointResult<'deleteAsset'>>;
  uploadAssetFile(
    props: Omit<EndpointProps<'uploadAssetFile'>, 'projectId'>,
  ): Promise<EndpointResult<'uploadAssetFile'>>;
  createProjectMember(
    props: Omit<EndpointProps<'createProjectMember'>, 'projectId'>,
  ): Promise<EndpointResult<'createProjectMember'>>;
  updateProjectMember(
    props: Omit<EndpointProps<'updateProjectMember'>, 'projectId'>,
  ): Promise<EndpointResult<'updateProjectMember'>>;
  deleteProjectMember(
    props: Omit<EndpointProps<'deleteProjectMember'>, 'projectId'>,
  ): Promise<EndpointResult<'deleteProjectMember'>>;
  signProjectRulesAgreement(
    props: Omit<EndpointProps<'signProjectRulesAgreement'>, 'projectId'>,
  ): Promise<void>;
  getAuditLogs(): Promise<AuditLog[]>;
  toggleAssetsEnabled(): Promise<void>;
  toggleObligationsEnabled(): Promise<void>;
}

const ProjectContext = createContext<ProjectContextValue | null>(null);

export const ProjectProvider: FC<{ projectId: number }> = ({
  projectId,
  children,
}) => {
  const { user } = useAccount();
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<ProjectContextValue['project']>(null);
  const member = useMemo(
    () =>
      user
        ? project?.members?.find((member) => member.userId === user.id) ?? null
        : null,
    [user, project],
  );

  const fetchProject = useCallback(
    async (isCancelled?: () => boolean) => {
      const fetchedProject = await api.getProjectById({ projectId });

      if (!isCancelled || !isCancelled()) {
        setLoading(false);
        setProject(fetchedProject);
      }
    },
    [api, projectId],
  );

  useAsyncEffect(fetchProject, [fetchProject]);

  const updateProject: ProjectContextValue['updateProject'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['updateProject']>[0]) => {
      const updatedProject = await api.updateProject({
        ...inputs,
        projectId,
      });

      setProject(updatedProject);

      return updatedProject;
    },
    [api, projectId],
  );

  const createObligation: ProjectContextValue['createObligation'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['createObligation']>[0]) => {
      const obligation = await api.createObligation({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areObligationsLoaded(value)) {
          return {
            ...value,
            obligations: [...value.obligations, obligation],
          };
        }
        return value;
      });

      return obligation;
    },
    [api, projectId],
  );

  const updateObligation: ProjectContextValue['updateObligation'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['updateObligation']>[0]) => {
      const obligation = await api.updateObligation({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areObligationsLoaded(value)) {
          return {
            ...value,
            obligations: replaceById(
              value.obligations,
              obligation.id,
              obligation,
            ),
          };
        }
        return value;
      });

      return obligation;
    },
    [api, projectId],
  );

  const deleteObligation: ProjectContextValue['deleteObligation'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['deleteObligation']>[0]) => {
      await api.deleteObligation({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areObligationsLoaded(value)) {
          return {
            ...value,
            obligations: removeById(value.obligations, inputs.obligationId),
          };
        }
        return value;
      });
    },
    [api, projectId],
  );

  const createAsset: ProjectContextValue['createAsset'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['createAsset']>[0]) => {
      const asset = await api.createAsset({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areAssetsLoaded(value)) {
          return {
            ...value,
            assets: [...value.assets, asset],
          };
        }
        return value;
      });

      return asset;
    },
    [api, projectId],
  );

  const updateAsset: ProjectContextValue['updateAsset'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['updateAsset']>[0]) => {
      const asset = await api.updateAsset({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areAssetsLoaded(value)) {
          return {
            ...value,
            assets: replaceById(value.assets, asset.id, asset),
          };
        }
        return value;
      });

      return asset;
    },
    [api, projectId],
  );

  const uploadAssetFile: ProjectContextValue['uploadAssetFile'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['uploadAssetFile']>[0]) => {
      await api.uploadAssetFile({
        ...inputs,
        projectId,
      });
    },
    [api, projectId],
  );

  const deleteAsset: ProjectContextValue['deleteAsset'] = useCallback(
    async (inputs: Parameters<ProjectContextValue['deleteAsset']>[0]) => {
      await api.deleteAsset({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areAssetsLoaded(value)) {
          return {
            ...value,
            assets: removeById(value.assets, inputs.assetId),
          };
        }
        return value;
      });
    },
    [api, projectId],
  );

  const createProjectMember: ProjectContextValue['createProjectMember'] = useCallback(
    async (
      inputs: Parameters<ProjectContextValue['createProjectMember']>[0],
    ) => {
      const newProjectMember = await api.createProjectMember({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areMembersLoaded(value)) {
          return {
            ...value,
            members: [...value.members, newProjectMember],
          };
        }
        return value;
      });

      return newProjectMember;
    },
    [api, projectId],
  );

  const updateProjectMember: ProjectContextValue['updateProjectMember'] = useCallback(
    async (
      inputs: Parameters<ProjectContextValue['updateProjectMember']>[0],
    ) => {
      const updatedProjectMember = await api.updateProjectMember({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areMembersLoaded(value)) {
          return {
            ...value,
            members: value.members.map((other) =>
              other.userId === inputs.userId ? updatedProjectMember : other,
            ),
          };
        }
        return value;
      });

      return updatedProjectMember;
    },
    [api, projectId],
  );

  const deleteProjectMember: ProjectContextValue['deleteProjectMember'] = useCallback(
    async (
      inputs: Parameters<ProjectContextValue['deleteProjectMember']>[0],
    ) => {
      await api.deleteProjectMember({
        ...inputs,
        projectId,
      });

      setProject((value) => {
        if (value && areMembersLoaded(value)) {
          return {
            ...value,
            members: value.members.filter(
              (other) => other.userId !== inputs.userId,
            ),
          };
        }
        return value;
      });
    },
    [api, projectId],
  );

  const signProjectRulesAgreement: ProjectContextValue['signProjectRulesAgreement'] = useCallback(
    async (
      inputs: Parameters<ProjectContextValue['signProjectRulesAgreement']>[0],
    ) => {
      await api.signProjectRulesAgreement({
        ...inputs,
        projectId,
      });
      // Reload unrestricted project
      setLoading(true);
      await fetchProject();
    },
    [api, projectId, fetchProject],
  );

  const toggleAssetsEnabled: ProjectContextValue['toggleAssetsEnabled'] = useCallback(async () => {
    if (project) {
      const assetsEnabled = !project.assetsEnabled;
      setProject({ ...project, assetsEnabled });
      await api.updateProject({
        projectId: project.id,
        assetsEnabled,
      });
    }
  }, [api, project]);

  const toggleObligationsEnabled: ProjectContextValue['toggleObligationsEnabled'] = useCallback(async () => {
    if (project) {
      const obligationsEnabled = !project.obligationsEnabled;
      setProject({ ...project, obligationsEnabled });
      await api.updateProject({
        projectId: project.id,
        obligationsEnabled,
      });
    }
  }, [api, project]);

  const getAuditLogs: ProjectContextValue['getAuditLogs'] = useCallback(
    async () => api.getProjectAuditLogs(projectId),
    [api, projectId],
  );

  const contextValue: ProjectContextValue = useMemo(
    () => ({
      loading,
      projectId,
      project,
      member,
      updateProject,
      createObligation,
      updateObligation,
      deleteObligation,
      createAsset,
      updateAsset,
      deleteAsset,
      uploadAssetFile,
      createProjectMember,
      updateProjectMember,
      deleteProjectMember,
      signProjectRulesAgreement,
      toggleAssetsEnabled,
      toggleObligationsEnabled,
      getAuditLogs,
    }),
    [
      loading,
      projectId,
      project,
      member,
      updateProject,
      createObligation,
      updateObligation,
      deleteObligation,
      createAsset,
      updateAsset,
      deleteAsset,
      uploadAssetFile,
      createProjectMember,
      updateProjectMember,
      deleteProjectMember,
      signProjectRulesAgreement,
      toggleAssetsEnabled,
      toggleObligationsEnabled,
      getAuditLogs,
    ],
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};

export function useProject(): ProjectContextValue {
  return (
    useContext(ProjectContext) ??
    missingReactContext('ProjectProvider', 'useProject')
  );
}

export function useProjectOptional(): ProjectContextValue | null {
  return useContext(ProjectContext);
}
