import { fakeUsers } from 'shared/lib/fake-data';
import { notFound } from 'shared/lib/utils/errors';

export const adminUser =
  fakeUsers.find((user) => user.role.name === 'Admin') ??
  notFound('fake user with role "admin"');
export const ipsStaffUser =
  fakeUsers.find((user) => user.role.name === 'IPS Staff') ??
  notFound('fake user with role "ipsStaff"');
export const externalUser =
  fakeUsers.find((user) => user.role.name === 'External') ??
  notFound('fake user with role "external"');
export const uoUser =
  fakeUsers.find((user) => user.role.name === 'UO') ??
  notFound('fake user with role "uo"');
