import React, { FC, createContext, useContext, useMemo, useRef } from 'react';
import { missingReactContext } from 'shared/lib/utils/errors';
import { Api, api } from 'shared/lib/api';
import { storybookApi } from '../storybookApi';
import { wait } from 'shared/lib/utils/wait';

const ApiContext = createContext<Api | null>(null);

export const ApiProvider: FC = ({ children }) => {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export function useApi(): Api {
  return useContext(ApiContext) ?? missingReactContext('ApiProvider', 'useApi');
}

/**
 * Should only be used in storybook.
 */
export const ApiStoryProvider: FC<{ api?: Partial<Api> }> = ({
  /**
   * Optional mock API functions.
   */
  api: customApi = {},
  children,
}) => {
  const customApiRef = useRef(customApi);
  customApiRef.current = customApi;
  const fakeApi = useMemo<Api>(
    () =>
      new Proxy(
        {},
        {
          get(_, key) {
            const fakeFn =
              (customApiRef.current as any)[key] ?? (storybookApi as any)[key];

            if (fakeFn) {
              return fakeFn;
            }

            return async () => {
              await wait(1000); // for testing spinners
              // Any API function not mocked in the story will throw this when called
              throw new Error(`Not implemented in storybook: ${String(key)}`);
            };
          },
        },
      ) as Api,
    [],
  );

  return <ApiContext.Provider value={fakeApi}>{children}</ApiContext.Provider>;
};
