import { FC, useCallback } from 'react';
import groupBy from 'lodash.groupby';
import { Button } from '../Button/Button';
import { Nav } from '../Nav/Nav';
import { ReactComponent as ClipboardIcon } from '../../images/icons/clipboard.svg';
import { ReactComponent as HelpCircleIcon } from '../../images/icons/help-circle.svg';
import { ReactComponent as UserPlusIcon } from '../../images/icons/user-plus.svg';
import { ReactComponent as ArchiveIcon } from '../../images/icons/archive.svg';
import { ReactComponent as DollarIcon } from '../../images/icons/dollar-sign.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/info.svg';
import { ProjectMemberList } from '../ProjectMemberList/ProjectMemberList';
import { ProjectAssetList } from '../ProjectAssetList/ProjectAssetList';
import { ObligationList } from '../ObligationList/ObligationList';
import { Flyout } from '../Flyout/Flyout';
import { Route, useLocation, useHistory, Link } from 'react-router-dom';
import { EditProjectModal } from '../modals/projectModals/EditProjectModal';
import { useProject } from '../../contexts/ProjectContext';
import { CreateObligationModal } from '../modals/obligationModals/CreateObligationModal';
import { EditObligationModal } from '../modals/obligationModals/EditObligationModal';
import { ObligationDetailsModal } from '../modals/obligationModals/ObligationDetailsModal';
import { AssetDetailsModal } from '../modals/assetModals/AssetDetailsModal';
import { CreateAssetModal } from '../modals/assetModals/CreateAssetModal';
import { EditAssetModal } from '../modals/assetModals/EditAssetModal';
import { ProjectRulesAgreementInputModal } from '../modals/projectRulesAgreementModals/ProjectRulesAgreementInputModal';
import { ViewProjectRulesAgreementModal } from '../modals/projectRulesAgreementModals/ViewProjectRulesAgreementModal';
import { SignProjectRulesAgreementModal } from '../modals/projectRulesAgreementModals/SignProjectRulesAgreementModal';
import { AddProjectMemberModal } from '../modals/projectMemberModals/AddProjectMemberModal';
import { EditProjectMemberModal } from '../modals/projectMemberModals/EditProjectMemberModal';
import useToggleState from '../../utils/react/useToggleState';
import { ProjectInfoFlyout } from '../ProjectInfoFlyout/ProjectInfoFlyout';
import { RejectedAssetModal } from '../modals/assetModals/RejectedAssetModal';
import {
  hasUserSignedRulesAgreement,
  getUnsignedRulesAgreementCount,
  getPendingAssetCount,
  areAssetsLoaded,
  areMembersLoaded,
  areObligationsLoaded,
} from 'shared/lib/utils/projectUtils';
import { useAccount } from '../../contexts/AccountContext';
import {
  canUserCreateAsset,
  canUserCreateMember,
  canUserCreateObligation,
  canUserViewProjectAuditLog,
  canUserUpdateRulesAgreement,
} from 'shared/lib/utils/permissions';
import { SpinnerOverlay } from '../SpinnerOverlay/SpinnerOverlay';
import { ProjectAuditLogModal } from '../modals/projectModals/ProjectAuditLogModal';

const memberRoleHelpText: Record<string, string | undefined> = {
  Supervisor:
    'Supervisors are legally and fiscally responsible for overseeing project outcomes and deliverables, often due to the terms of a funding agreement (Obligation) and their formal role as a Principal Investigator. Typically, a Supervisor will be a Principal Investigator (PI), a co-PI or the head of a department, center or institute.',
  Manager:
    'Managers have delegated authority from the Supervisor(s) to manage project Members, decide whether Member-contributed Assets should be included in a project, and to add and update project Obligations to provide Members with visibility. The Supervisor retains ultimate responsibility for the Project.',
  Member:
    'Members are project participants expected to contribute content, conduct research, or otherwise participate under the terms of Project Rules Agreement (PRA).',
};

export const ProjectPage: FC = (props) => {
  const { user } = useAccount();
  const {
    projectId,
    project,
    updateProject,
    signProjectRulesAgreement,
  } = useProject();
  const location = useLocation();
  const history = useHistory();
  const [showAddMenu, toggleAddMenu] = useToggleState(false);
  const [showInfoFlyout, toggleInfoFlyout] = useToggleState(false);
  const assetGroups = Object.values(
    groupBy(
      project && areAssetsLoaded(project) ? project.assets : [],
      'assetCategoryId',
    ),
  );
  const memberGroups = Object.values(
    groupBy(project?.members ?? [], 'projectMemberRoleId'),
  );
  const unsignedPraCount = project
    ? getUnsignedRulesAgreementCount(project)
    : 0;
  const pendingAssetCount = project ? getPendingAssetCount(project) : 0;
  const hasSignedPra = !!(
    user &&
    project &&
    hasUserSignedRulesAgreement({
      user,
      project,
    })
  );
  const showAddMemberButton = !!(
    project &&
    user &&
    areMembersLoaded(project) &&
    canUserCreateMember(user, project)
  );
  const showAddAssetButton = !!(
    user &&
    project &&
    areAssetsLoaded(project) &&
    areMembersLoaded(project) &&
    canUserCreateAsset(user, project)
  );
  const showAddObligationButton = !!(
    user &&
    project &&
    areObligationsLoaded(project) &&
    areMembersLoaded(project) &&
    canUserCreateObligation(user, project)
  );
  const showAddPraButton = !!(
    project &&
    user &&
    areMembersLoaded(project) &&
    canUserUpdateRulesAgreement(user, project)
  );
  const showAuditLogButton = !!(
    user &&
    project &&
    areMembersLoaded(project) &&
    canUserViewProjectAuditLog(user, project)
  );
  const showAddButton =
    showAddMemberButton ||
    showAddAssetButton ||
    showAddObligationButton ||
    showAddPraButton;

  const closeModals = useCallback(() => {
    history.push({
      pathname: `/project/${projectId}`,
      search: location.search,
    });
  }, [history, projectId, location.search]);

  return (
    <div
      {...props}
      className="min-h-screen pt-16 pb-100 px-8 lg:px-20 overflow-x-hidden overflow-y-hidden"
    >
      {!project && <SpinnerOverlay fadeIn={false} />}
      <div className="container mx-auto max-w-full">
        <Nav showBackButton />

        {/* Heading */}
        <div className="col md:flex-row md:items-center md:justify-between mb-12">
          <div className="row items-center">
            <h1 className="block text-6xl max-w-md">{project?.name}</h1>
            <div className="relative ml-8 mt-4">
              <Button
                backgroundColor="none"
                textColor="black"
                size="small"
                className="flex-shrink-0"
                onClick={toggleInfoFlyout}
              >
                <InfoIcon />
              </Button>
              {showInfoFlyout && (
                <ProjectInfoFlyout
                  className="fixed left-1/4 -ml-40 xl:absolute xl:left-0"
                  onClose={toggleInfoFlyout}
                />
              )}
            </div>
            <div className="relative ml-8 mt-4">
              <Button backgroundColor="none" textColor="black">
                <HelpCircleIcon />
              </Button>
              <Flyout
                arrowSide="top-left"
                color="uo-yellow"
                className="adjacent-hover-visible absolute top-0 left-0 mt-16 w-168"
              >
                <h4 className="font-semibold">Project</h4>
                <p className="mt-3">
                  A project is a primary platform for the management,
                  development and dissemination of information assets (“Assets”)
                  but may also be used to manage expectations with respect to
                  professional behavior of a research group or to ensure
                  regulatory and privacy issues are addressed upfront. This
                  includes defining the roles and authority of each project
                  participant (“Member”) and the ground rules governing the
                  project, including an acknowledgement from each Member that
                  establishes their commitment and the benefits the project
                  offers to each (“Project Rules”). Additionally, most projects
                  include a section on financial expectations in the Project
                  Rules or reference a separate financial memorandum that
                  connects the development and value capture of Assets back to
                  the University and requires tracking obligations to sponsors
                  funding the work (“Funds”). This Tool creates a centralized
                  platform to organize Assets, Members, Project Rules and Funds.
                </p>
              </Flyout>
            </div>
          </div>
          <div className="w-48 relative h-24">
            {showAddButton && (
              <Button
                className="w-full"
                backgroundColor="uo-green"
                textColor="white"
                size="large"
                onClick={toggleAddMenu}
              >
                Add
              </Button>
            )}
            {showAddMenu && showAddButton && (
              <Flyout
                arrowSide="top-right"
                color="uo-green"
                borderRadius="xl"
                className="w-full top-0 left-0 mt-22"
                onClickOutside={toggleAddMenu}
              >
                {showAddMemberButton && (
                  <Link to={`/project/${projectId}/members/add`}>
                    <Button
                      onClick={toggleAddMenu}
                      backgroundColor="none"
                      textColor="white"
                      className="row items-center px-0"
                    >
                      <UserPlusIcon className="mr-3" /> Add Member
                    </Button>
                  </Link>
                )}
                {showAddAssetButton && (
                  <Link to={`/project/${projectId}/add-asset`}>
                    <Button
                      onClick={toggleAddMenu}
                      backgroundColor="none"
                      textColor="white"
                      className="row items-center px-0"
                    >
                      <ArchiveIcon className="mr-3" /> Add Asset
                    </Button>
                  </Link>
                )}
                {showAddObligationButton && (
                  <Link to={`/project/${projectId}/add-obligation`}>
                    <Button
                      onClick={toggleAddMenu}
                      backgroundColor="none"
                      textColor="white"
                      className="row items-center px-0"
                    >
                      <DollarIcon className="mr-3" /> Add Obligation
                    </Button>
                  </Link>
                )}
                {showAddPraButton && (
                  <Link to={`/project/${projectId}/add-pra`}>
                    <Button
                      onClick={toggleAddMenu}
                      backgroundColor="none"
                      textColor="white"
                      className="row items-center px-0"
                    >
                      <ClipboardIcon className="mr-3" /> Add PRA
                    </Button>
                  </Link>
                )}
              </Flyout>
            )}

            {showAuditLogButton && (
              <div className="absolute bottom-0 right-0 -mb-4">
                <Link to={`/project/${projectId}/log`}>View log</Link>
              </div>
            )}
          </div>
        </div>
        <h2 className="text-dark-gray text-xl h-8">
          {project?.staffManager?.name}
        </h2>
        <div className="row items-center mb-6">
          <h3 className="text-xl mr-4">
            PRA: {project?.projectRulesAgreement?.name ?? 'N/A'}
          </h3>
          {!!project?.projectRulesAgreementId && (
            <Link to={`/project/${projectId}/pra`}>
              <Button backgroundColor="none" textColor="black">
                <ClipboardIcon />
              </Button>
            </Link>
          )}
          <div className="relative">
            <Button backgroundColor="none" textColor="black">
              <HelpCircleIcon />
            </Button>
            <Flyout
              arrowSide="top-left"
              color="uo-yellow"
              className="adjacent-hover-visible absolute top-0 left-0 mt-16 w-100"
            >
              <h4 className="font-semibold">PRA</h4>
              <p className="mt-3">
                A Project Rule Agreement (PRA) establishes ground rules which
                govern the project including a participation acknowledgement
                that details the commitments of project participants and the
                benefits the project offers to each. All projects require a PRA
                and every participant must agree to the terms before accessing
                the particular project.
              </p>
            </Flyout>
          </div>
        </div>
        <hr className="border-gray-300 mb-8" />
        <div className="flex flex-col lg:flex-row">
          {/* Member list */}
          <div className="lg:w-0 col flex-grow-4 lg:mr-5 lg:max-w-4xl">
            <div className="row justify-between items-center">
              <h4 className="text-xl font-bold">
                Members
                {unsignedPraCount > 0 && (
                  <span className="text-dark-gray ml-5 font-normal text-base">
                    ({unsignedPraCount}) Unsigned PRA
                    {unsignedPraCount === 1 ? '' : 's' /* pluralize */}
                  </span>
                )}
              </h4>
              <div className="relative">
                <Button backgroundColor="none" textColor="black" size="small">
                  <HelpCircleIcon />
                </Button>
                <Flyout
                  arrowSide="top-left"
                  color="uo-yellow"
                  className="adjacent-hover-visible absolute top-0 left-0 mt-12 -mr-2 w-100"
                >
                  <h4 className="font-semibold">Members</h4>
                  <p className="mt-3">
                    Members are project participants expected to contribute
                    content, conduct research, or otherwise participate under
                    the terms of Project Rules Agreement (PRA).
                  </p>
                </Flyout>
              </div>
            </div>
            <hr className="border-gray-800 border-2 border-t mb-3" />
            <ul>
              {memberGroups
                .filter((memberList) => memberList.length > 0)
                .map((memberList, i) => (
                  <ProjectMemberList
                    key={memberList[0].projectMemberRoleId}
                    title={memberList[0].projectMemberRole.name}
                    className={i < memberGroups.length - 1 ? 'mb-3' : ''}
                    members={memberList}
                    helpText={
                      memberRoleHelpText[memberList[0].projectMemberRole.name]
                    }
                  />
                ))}
            </ul>
          </div>

          {/* Asset list */}
          {project?.assetsEnabled && areAssetsLoaded(project) && (
            <div className="lg:w-0 col flex-grow-3 mt-5 lg:mt-0 lg:mr-5">
              <div className="row justify-between items-center">
                <h4 className="text-xl font-bold">
                  Assets
                  {pendingAssetCount > 0 && (
                    <span className="text-dark-gray ml-5 font-normal text-base">
                      ({pendingAssetCount}) Asset Approval
                    </span>
                  )}
                </h4>
                <div className="relative">
                  <Button backgroundColor="none" textColor="black" size="small">
                    <HelpCircleIcon />
                  </Button>
                  <Flyout
                    arrowSide="top-right"
                    color="uo-yellow"
                    className="adjacent-hover-visible absolute top-0 right-0 mt-12 -mr-2 w-100"
                  >
                    <h4 className="font-semibold">Assets</h4>
                    <p className="mt-3">
                      An Asset is information or content being considered for
                      development and/or inclusion in a project. An Asset might
                      be a snippet of code, an article, an image, a logo, a
                      dataset, a chemical compound, or DNA, to name a few
                      possible examples. This Tool provides a platform to
                      recognize, reference and organize Assets submitted by
                      project participants, and to record whether the Asset is
                      accepted or rejected for inclusion by a project Supervisor
                      or Manager.
                    </p>
                  </Flyout>
                </div>
              </div>
              <hr className="border-gray-800 border-2 border-t mb-3" />
              {assetGroups.map((assetList, i) => (
                <ProjectAssetList
                  key={assetList[0].assetCategoryId}
                  title={assetList[0].category.name}
                  assets={assetList}
                  className={i < assetGroups.length - 1 ? 'mb-3' : ''}
                />
              ))}
            </div>
          )}

          {/* Obligation list */}
          {project?.obligationsEnabled && areObligationsLoaded(project) && (
            <div className="lg:w-0 col flex-grow-2 mt-5 lg:mt-0">
              <div className="row justify-between items-center">
                <h4 className="text-xl font-bold">Obligations</h4>
                <div className="relative">
                  <Button backgroundColor="none" textColor="black" size="small">
                    <HelpCircleIcon />
                  </Button>
                  <Flyout
                    arrowSide="top-right"
                    color="uo-yellow"
                    className="adjacent-hover-visible absolute top-0 right-0 mt-12 -mr-2 w-100"
                  >
                    <h4 className="font-semibold">Obligations</h4>
                    <p className="mt-3">
                      A project Obligation is a legal commitment to a project
                      sponsor, pursuant to a contract or funding award. A
                      project may have multiple Obligations that must be managed
                      congruently to make sure all requirements to all sponsors
                      are met.
                    </p>
                  </Flyout>
                </div>
              </div>
              <hr className="border-gray-800 border-2 border-t mb-3" />
              <ObligationList obligations={project?.obligations ?? []} />
            </div>
          )}
        </div>
      </div>

      {/* Edit project */}
      <Route path={`/project/${projectId}/edit`}>
        <EditProjectModal
          onClose={closeModals}
          showRulesAgreementInput={showAddPraButton}
        />
      </Route>

      {/* Create obligation */}
      <Route path={`/project/${projectId}/add-obligation`}>
        <CreateObligationModal onClose={closeModals} />
      </Route>

      {/* Edit obligation */}
      <Route path={`/project/${projectId}/obligation/:obligationId/edit`}>
        {({ match }) =>
          match && (
            <EditObligationModal
              obligationId={+match.params.obligationId}
              onClose={closeModals}
            />
          )
        }
      </Route>

      {/* Obligation details */}
      <Route path={`/project/${projectId}/obligation/:obligationId/details`}>
        {({ match }) =>
          match && (
            <ObligationDetailsModal
              obligationId={+match.params.obligationId}
              onClose={closeModals}
            />
          )
        }
      </Route>

      {/* Create asset */}
      <Route path={`/project/${projectId}/add-asset`}>
        <CreateAssetModal onClose={closeModals} />
      </Route>

      {/* Edit asset */}
      <Route path={`/project/${projectId}/asset/:assetId/edit`}>
        {({ match }) =>
          match && (
            <EditAssetModal
              assetId={+match.params.assetId}
              onClose={closeModals}
            />
          )
        }
      </Route>

      {/* Asset details */}
      <Route path={`/project/${projectId}/asset/:assetId/details`}>
        {({ match }) =>
          match && (
            <AssetDetailsModal
              assetId={+match.params.assetId}
              onClose={closeModals}
            />
          )
        }
      </Route>

      {/* View asset rejection message */}
      <Route path={`/project/${projectId}/asset/:assetId/rejected`}>
        {({ match }) =>
          match && (
            <RejectedAssetModal
              assetId={+match.params.assetId}
              onClose={closeModals}
            />
          )
        }
      </Route>

      {/* Add PRA */}
      <Route path={`/project/${projectId}/add-pra`}>
        <ProjectRulesAgreementInputModal
          title="Add PRA"
          submitButtonText="Add PRA"
          initialValue={project?.projectRulesAgreementId}
          onClose={closeModals}
          onSubmit={async (projectRulesAgreementId) => {
            if (!projectRulesAgreementId) {
              return;
            }
            await updateProject({ projectRulesAgreementId });
          }}
        />
      </Route>

      {/* View PRA */}
      {project?.projectRulesAgreementId && (
        <Route path={`/project/${projectId}/pra`}>
          <ViewProjectRulesAgreementModal
            projectRulesAgreementId={project.projectRulesAgreementId}
            onClose={closeModals}
          />
        </Route>
      )}

      {/* Sign PRA */}
      {!hasSignedPra && user && project?.projectRulesAgreementId && (
        <SignProjectRulesAgreementModal
          projectId={project.id}
          projectRulesAgreementId={project.projectRulesAgreementId}
          onCancel={() => history.push('/')}
          onSubmit={(signature) => signProjectRulesAgreement({ signature })}
        />
      )}

      {/* Member modals */}
      <Route path={`/project/${projectId}/members/add`}>
        <AddProjectMemberModal onClose={closeModals} />
      </Route>

      {/* Audit log */}
      {showAuditLogButton && (
        <Route path={`/project/${projectId}/log`}>
          <ProjectAuditLogModal onClose={closeModals} />
        </Route>
      )}

      <Route path={`/project/${projectId}/members/:userId/edit`}>
        {({ match }) =>
          match && (
            <EditProjectMemberModal
              onClose={closeModals}
              userId={+match.params.userId}
            />
          )
        }
      </Route>
    </div>
  );
};
