import React, { FC, useCallback } from 'react';
import { useUserList } from '../../../contexts/UserListContext';
import { UserModal, UserModalValue } from './UserModal';

export interface CreateUserModalProps {
  onClose(): unknown;
}

export const CreateUserModal: FC<CreateUserModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createUser } = useUserList();

  const handleSubmit = useCallback(
    async (value: UserModalValue) => {
      await createUser(value);
      onClose();
    },
    [onClose, createUser],
  );

  return (
    <UserModal
      {...rest}
      title="Add User"
      submitButtonText="Add User"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
