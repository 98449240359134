import { useState, useCallback } from "react";

function toggle(value: boolean): boolean {
  return !value;
}

/**
 * Like `useState` except the returned function takes no arguments and just toggles the value between `true`/`false`.
 * The second function returned accepts a boolean for manually setting the value.
 *
 * Example:
 *
 * ```tsx
 * const [on, toggle, setOn] = useToggleState(true);
 *
 * return (
 *   <>
 *     <button onClick={toggle}>Toggle</button>
 *     <button onClick={() => setOn(true)}>Turn on</button>
 *     <button onClick={() => setOn(false)}>Turn off</button>
 *   </>
 * );
 * ```
 */
export default function useToggleState(
  initialValue: boolean | (() => boolean) = false
): [boolean, () => void, (value: boolean) => void] {
  const [value, setValue] = useState(initialValue);

  const handleToggle = useCallback(() => {
    setValue(toggle);
  }, []);

  return [value, handleToggle, setValue];
}
