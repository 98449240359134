import React, { FC, ComponentPropsWithoutRef } from 'react';

interface TextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  onTextChange?(text: string): unknown;
}

export const TextArea: FC<TextAreaProps> = ({
  className = '',
  onChange,
  onTextChange,
  ...rest
}) => (
  <textarea
    {...rest}
    className={`bg-light-gray rounded h-24 min-h-0 py-4 px-5 resize-none ${className}`}
    onChange={(event) => {
      if (onChange) {
        onChange(event);
      }
      if (onTextChange) {
        onTextChange(event.currentTarget.value);
      }
    }}
  />
);
