import React, {
  FC,
  ComponentPropsWithoutRef,
  ReactNode,
  useEffect,
} from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as XIcon } from '../../images/icons/x.svg';
import { SpinnerOverlay } from '../SpinnerOverlay/SpinnerOverlay';

const defaultBodyOverflow = document.body.style.overflow;
let openModalCount = 0;

export interface ModalProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
  onClose(): unknown;
  roundedSize?: 'lg' | '6xl';
  showSpinner?: boolean;
  fadeIn?: boolean;
  closeButtonClassName?: string;
  spinnerMessage?: ReactNode;
}

export const Modal: FC<ModalProps> = ({
  className = '',
  closeButtonClassName = '',
  children,
  showSpinner,
  spinnerMessage,
  fadeIn = true,
  roundedSize = 'lg',
  onClose,
  ...rest
}) => {
  useEffect(() => {
    openModalCount += 1;
    document.body.style.overflow = 'hidden';

    return () => {
      openModalCount -= 1;
      if (openModalCount <= 0) {
        openModalCount = 0;
        document.body.style.overflow = defaultBodyOverflow;
      }
    };
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto h-screen">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className={`fixed inset-0 transition-opacity h-screen ${
            fadeIn ? 'fade-in' : ''
          }`}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className={`inline-flex flex-col relative align-bottom text-left sm:align-middle transform transition-all py-8`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {/* Window */}
          <div
            {...rest}
            className={`col relative bg-white pt-10 pb-16 px-6 max-w-full self-center rounded-${roundedSize} ${className}`}
          >
            {showSpinner && (
              <SpinnerOverlay
                message={spinnerMessage}
                className={`rounded-${roundedSize}`}
              />
            )}
            <Button
              backgroundColor="none"
              textColor="black"
              className={`absolute right-0 top-0 ${
                roundedSize === '6xl' ? 'mr-8 mt-4' : 'mr-8 mt-8'
              } ${closeButtonClassName}`}
              onClick={onClose}
            >
              <XIcon title="Close" />
            </Button>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
