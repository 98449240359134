import React, { FC } from 'react';
import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';

interface CheckboxProps {
  checked: boolean;
  onChange?(checked: boolean): unknown;
  className?: string;
  disabled?: boolean;
}

export const CheckBox: FC<CheckboxProps> = ({
  checked,
  className = '',
  disabled = false,
  onChange,
  ...rest
}) => (
  <div {...rest} className={`w-6 h-6 ${className}`}>
    <div
      className={`w-full h-full flex-shrink-0 rounded border transition duration-200 text-white ${
        disabled ? '' : 'cursor-pointer'
      } ${
        checked ? 'bg-lime-green border-lime-green' : 'bg-white border-gray-800'
      }`}
      role="checkbox"
      aria-checked={checked}
      onClick={onChange && !disabled ? () => onChange(!checked) : undefined}
    >
      {checked && <CheckIcon className="w-full h-full" />}
    </div>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={
        onChange && !disabled
          ? (event) => onChange(event.currentTarget.checked)
          : undefined
      }
      className="hidden"
    />
  </div>
);
