import React, { FC, FormEvent, useState, useCallback } from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import { Modal, ModalProps } from '../../Modal/Modal';
import { Button } from '../../Button/Button';
import { TextInput } from '../../TextInput/TextInput';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { autoFocus } from '../../../utils/autoFocus';

export interface DepartmentModalValue {
  name: string;
}

export interface DepartmentModalProps extends ModalProps {
  title: string;
  initialValue?: DepartmentModalValue;
  showSpinner?: boolean;
  submitButtonText?: string;
  onSubmit?(value: DepartmentModalValue): unknown;
}

export const DepartmentModal: FC<DepartmentModalProps> = ({
  title,
  initialValue,
  showSpinner,
  submitButtonText,
  onSubmit,
  ...rest
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [name, setName] = useState(initialValue?.name ?? '');

  const canSubmit = !!(!submitting && !isBlank(name));

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setSubmitting(true);
      try {
        if (onSubmit) {
          await onSubmit({
            name,
          });
        }
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, name],
  );

  return (
    <Modal
      {...rest}
      className="relative w-168 text-left"
      showSpinner={submitting}
    >
      <form className="col" onSubmit={handleSubmit}>
        <h1 className="text-4xl text-center font-normal">{title}</h1>

        <label className="font-semibold mt-8">Department</label>
        <TextInput
          value={name}
          onTextChange={setName}
          inputRef={autoFocus}
          className="mt-3"
        />

        {submitError && (
          <p className="mt-8 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}

        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="mt-16 w-48 self-center"
          disabled={!canSubmit}
        >
          {submitButtonText}
        </Button>
      </form>
    </Modal>
  );
};
