import React from 'react';
import showModal from '../../utils/react/showModal';
import { ConfirmModal, ConfirmModalProps } from './ConfirmModal';

export function showConfirm(
  options: Omit<ConfirmModalProps, 'onSubmit'>,
): Promise<boolean> {
  return showModal((resolve) => (
    <ConfirmModal {...options} onSubmit={resolve} />
  ));
}
