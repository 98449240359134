import { FC } from 'react';
import { ReactComponent as PaperclipIcon } from '../../images/icons/paperclip.svg';

export interface FileInputProps {
  className?: string;
  placeholder?: string;
  multiple?: boolean;
  onChange(files: File[]): unknown;
}

export const FileInput: FC<FileInputProps> = ({
  onChange,
  placeholder = 'Attach',
  className = '',
  multiple,
  ...rest
}) => (
  <label
    {...rest}
    className={`row justify-between items-center rounded bg-light-gray hover:bg-gray-100 transition text-sm h-12 w-48 px-5 cursor-pointer ${className}`}
  >
    {placeholder} <PaperclipIcon />
    <input
      className="hidden"
      type="file"
      multiple={multiple}
      onChange={(event) => {
        const { files: newFiles } = event.currentTarget;
        if (newFiles && newFiles.length > 0) {
          onChange(Array.from(newFiles));
        }
      }}
    />
  </label>
);
