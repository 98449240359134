import { FC, FormEvent, useCallback, useState } from 'react';
import useInputState from '../../utils/react/useInputState';
import { ProjectMemberRole } from 'shared/lib/types/ProjectMemberRole';
import { Button } from '../Button/Button';
import { TextInput } from '../TextInput/TextInput';
import { ReactComponent as XIcon } from '../../images/icons/x.svg';
import { useApi } from '../../contexts/ApiContext';
import { Select } from '../Select/Select';
import { useUserRoleList } from '../../contexts/UserRoleListContext';
import { isBlank } from 'shared/lib/utils/isBlank';

export interface ProjectMemberTableFormValue {
  userId: number | null;
  userRoleId: number;
  projectMemberRoleId: number;
  name: string;
  email: string;
  affiliation?: string | null;
}

export interface PendingProjectMemberTableFormValue
  extends Omit<ProjectMemberTableFormValue, 'userRoleId'> {
  userRoleId: number | null;
}

export interface ProjectMemberTableFormProps {
  className?: string;
  projectMemberRole: ProjectMemberRole;
  values: ProjectMemberTableFormValue[];
  onChange(values: ProjectMemberTableFormValue[]): unknown;
}

export const ProjectMemberTableForm: FC<ProjectMemberTableFormProps> = ({
  className,
  projectMemberRole,
  values,
  onChange,
  ...rest
}) => {
  const { selectableUserRoles } = useUserRoleList();
  const [email, handleEmailChange, setEmail] = useInputState();
  const [addNew, setAddNew] = useState(false);
  const [
    pendingMember,
    setPendingMember,
  ] = useState<PendingProjectMemberTableFormValue | null>(null);
  const pendingMemberRole =
    pendingMember && pendingMember.userRoleId
      ? selectableUserRoles.find((role) => role.id === pendingMember.userRoleId)
      : null;
  const { findUserByEmail } = useApi();
  const visibleValues = values.filter(
    (other) => other.projectMemberRoleId === projectMemberRole.id,
  );

  const handleAddSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (addNew) {
        setPendingMember({
          userId: null,
          projectMemberRoleId: projectMemberRole.id,
          userRoleId: null,
          name: '',
          email,
        });
        setAddNew(false);
        setEmail('');
      } else {
        const foundUser = await findUserByEmail({ email });

        if (foundUser) {
          if (values.some((other) => other.userId === foundUser.id)) {
            // TODO: Replace with better alert
            window.alert('That user is already added as a member.');
            setEmail('');
            return;
          }
          onChange([
            ...values,
            {
              userId: foundUser.id,
              projectMemberRoleId: projectMemberRole.id,
              userRoleId: foundUser.roleId,
              name: foundUser.name,
              email: foundUser.email,
              affiliation: foundUser.affiliation,
            },
          ]);
          setEmail('');
        } else {
          setAddNew(true);
        }
      }
    },
    [
      findUserByEmail,
      onChange,
      setEmail,
      email,
      values,
      addNew,
      projectMemberRole,
    ],
  );

  return (
    <div {...rest} className={`col ${className}`}>
      <form className="row mb-1" onSubmit={handleAddSubmit}>
        <TextInput
          className="flex-grow mr-6"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="h-12 w-48 py-0"
          disabled={!/\S+@\S+/.test(email)}
        >
          {addNew ? 'Add New User' : 'Add Existing User'}
        </Button>
      </form>
      {addNew && (
        <p className="text-berry text-xs mb-5">
          Email not recognized. Press “Add New User” to create new user.
        </p>
      )}
      {pendingMember && (
        <form
          onSubmit={(event) => {
            event.preventDefault();

            const { userRoleId } = pendingMember;
            if (userRoleId) {
              onChange([
                {
                  ...pendingMember,
                  userRoleId,
                },
                ...values,
              ]);
            }
            setPendingMember(null);
          }}
        >
          <div className="row mt-6">
            <TextInput
              value={pendingMember.name}
              placeholder="Name"
              onChange={(event) =>
                setPendingMember({
                  ...pendingMember,
                  name: event.currentTarget.value,
                })
              }
              className="w-0 flex-grow"
            />
            <TextInput
              value={pendingMember.email}
              placeholder="Email"
              onChange={(event) =>
                setPendingMember({
                  ...pendingMember,
                  email: event.currentTarget.value,
                })
              }
              className="w-0 flex-grow ml-6"
            />
            <Select
              className="w-0 flex-grow ml-6"
              value={pendingMember.userRoleId}
              onValueChange={(value) =>
                setPendingMember({
                  ...pendingMember,
                  userRoleId: value ? +value : null,
                })
              }
              options={selectableUserRoles.map((role) => ({
                value: role.id.toString(),
                label: role.name,
              }))}
            />
            {!!(pendingMemberRole && pendingMemberRole.requireAffiliation) && (
              <TextInput
                placeholder="Affiliation"
                className="w-0 flex-grow ml-6"
                value={pendingMember.affiliation ?? ''}
                onChange={(event) =>
                  setPendingMember({
                    ...pendingMember,
                    affiliation: event.currentTarget.value,
                  })
                }
              />
            )}
          </div>
          <div className="row mt-6 justify-center">
            <Button
              backgroundColor="uo-green"
              textColor="white"
              className="w-48 order-2"
              disabled={
                isBlank(pendingMember.name) ||
                isBlank(pendingMember.email) ||
                !pendingMember.userRoleId ||
                !!(
                  pendingMemberRole &&
                  pendingMemberRole.requireAffiliation &&
                  isBlank(pendingMember.affiliation)
                )
              }
            >
              Add New User
            </Button>
            <Button
              backgroundColor="red"
              textColor="white"
              className="w-48 mr-6 order-1"
              onClick={(event) => {
                event.stopPropagation();
                setPendingMember(null);
              }}
            >
              Cancel New User
            </Button>
          </div>
        </form>
      )}
      <table className="w-full mt-10">
        <thead />
        <tbody>
          {visibleValues.map((member) => (
            <tr key={member.email}>
              <td>{member.name}</td>
              <td>{member.email}</td>
              <td>{member.affiliation}</td>
              <td className="row justify-end">
                <Button
                  backgroundColor="none"
                  textColor="black"
                  size="small"
                  onClick={() =>
                    onChange(values.filter((other) => other !== member))
                  }
                >
                  <XIcon />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot />
      </table>
    </div>
  );
};
