import { FC, useState } from 'react';
import replaceWhere from 'shared/lib/utils/array/replaceWhere';
import { Button } from '../Button/Button';
import { Select } from '../Select/Select';

interface SelectListProps {
  className?: string;
  values: number[];
  options: { value: string; label: string }[];
  onChange(value: number[]): unknown;
}

export const SelectList: FC<SelectListProps> = ({
  className,
  values,
  options,
  onChange,
  ...rest
}) => {
  const [showAddButton, setShowAddButton] = useState(false);

  return (
    <div {...rest} className={`col ${className}`}>
      {values.map((value, i) => (
        <Select
          className={i > 0 ? 'mt-3' : ''}
          value={value}
          options={options}
          onValueChange={(newValue) => {
            if (newValue) {
              const newNumberValue = +newValue;
              onChange(
                replaceWhere(
                  values,
                  (other) => other === newNumberValue,
                  () => newNumberValue,
                ),
              );
            } else {
              onChange(values.filter((other) => other !== value));
            }
          }}
        />
      ))}
      {(!showAddButton || !values.length) && (
        <Select
          className={values.length > 0 ? 'mt-3' : ''}
          options={options}
          onChange={(event) => {
            if (event) {
              onChange([...values, +event.value]);
              setShowAddButton(true);
            }
          }}
        />
      )}
      {showAddButton && (
        <Button
          backgroundColor="none"
          textColor="black"
          size="small"
          onClick={() => {
            setShowAddButton(false);
          }}
        >
          + Add Another
        </Button>
      )}
    </div>
  );
};
