import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { LoginPage } from './components/LoginPage/LoginPage';
import { AccountProvider, useAccount } from './contexts/AccountContext';
import { ProjectListPage } from './components/ProjectListPage/ProjectListPage';
import { ProjectPage } from './components/ProjectPage/ProjectPage';
import { ProjectListProvider } from './contexts/ProjectListContext';
import { ApiProvider } from './contexts/ApiContext';
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import { ProjectProvider } from './contexts/ProjectContext';
import { AdminToolsPage } from './components/AdminToolsPage/AdminToolsPage';
import { UserListProvider } from './contexts/UserListContext';
import { EditProfilePage } from './components/EditAccountPage/EditProfilePage';
import { canUserViewAdminTools } from 'shared/lib/utils/permissions';
import { AcceptUserInvitePage } from './components/AcceptUserInvitePage/AcceptUserInvitePage';
import { AcceptPasswordResetPage } from './components/AcceptPasswordResetPage/AcceptPasswordResetPage';
import { ForgotPasswordPage } from './components/ForgotPasswordPage/ForgotPasswordPage';
import { SpinnerOverlay } from './components/SpinnerOverlay/SpinnerOverlay';
import { NotificationProvider } from './contexts/NotificationContext';

function LoggedInApp() {
  const { user } = useAccount();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/project/:projectId">
          {({ match }) =>
            match && (
              <ProjectListProvider>
                <ProjectProvider projectId={+match.params.projectId}>
                  <ProjectPage />
                </ProjectProvider>
              </ProjectListProvider>
            )
          }
        </Route>
        {user && canUserViewAdminTools(user) && (
          <Route path="/admin">
            <UserListProvider>
              <AdminToolsPage />
            </UserListProvider>
          </Route>
        )}
        <Route path="/profile">
          <EditProfilePage />
        </Route>
        <Route path="/">
          <ProjectListProvider>
            <ProjectListPage />
          </ProjectListProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function LoggedOutApp() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/accept-invite">
          {({ match, location }) => {
            if (!match) {
              return null;
            }
            const searchParams = new URLSearchParams(location.search);
            const email = searchParams.get('email') ?? '';
            const code = searchParams.get('code') ?? '';
            const expiresAt = searchParams.get('expiresAt') ?? '';

            return (
              <AcceptUserInvitePage
                email={email}
                code={code}
                expiresAt={expiresAt}
              />
            );
          }}
        </Route>
        <Route path="/reset-password">
          {({ match, location }) => {
            if (!match) {
              return null;
            }
            const searchParams = new URLSearchParams(location.search);
            const email = searchParams.get('email') ?? '';
            const code = searchParams.get('code') ?? '';
            const expiresAt = searchParams.get('expiresAt') ?? '';

            return (
              <AcceptPasswordResetPage
                email={email}
                code={code}
                expiresAt={expiresAt}
              />
            );
          }}
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/">
          <LoginPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function AppAuthCheck() {
  const auth = useAccount();

  /**
   * Waiting for the server to tell the user if they're logged in.
   */
  if (auth.loading) {
    return (
      <div className="min-h-screen">
        <SpinnerOverlay fadeIn={false} />
      </div>
    );
  }

  if (auth.user) {
    return (
      <GlobalDataProvider>
        <NotificationProvider>
          <LoggedInApp />
        </NotificationProvider>
      </GlobalDataProvider>
    );
  } else {
    return <LoggedOutApp />;
  }
}

export function App() {
  return (
    <ApiProvider>
      <AccountProvider>
        <AppAuthCheck />
      </AccountProvider>
    </ApiProvider>
  );
}
