import { FC, useMemo } from 'react';
import { areObligationsLoaded } from 'shared/lib/utils/projectUtils';
import { useProject } from '../../../contexts/ProjectContext';
import { ObligationModal, ObligationModalValue } from './ObligationModal';

export interface ObligationDetailsModalProps {
  obligationId: number;
  onClose(): unknown;
}

export const ObligationDetailsModal: FC<ObligationDetailsModalProps> = ({
  obligationId,
  onClose,
  ...rest
}) => {
  const { project } = useProject();
  const initialValue: ObligationModalValue | undefined = useMemo(() => {
    if (project && areObligationsLoaded(project)) {
      return project.obligations.find(
        (obligation) => obligation.id === obligationId,
      );
    }
  }, [project, obligationId]);

  if (project && !areObligationsLoaded(project)) {
    // Cannot edit obligations if the PRA is unsigned
    // TODO: add an error modal?
    return null;
  }

  return (
    <ObligationModal
      {...rest}
      readOnly
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Obligation Details"
      onClose={onClose}
    />
  );
};
