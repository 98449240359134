import { FC, useMemo } from 'react';
import { areAssetsLoaded } from 'shared/lib/utils/projectUtils';
import { Asset, isAssetRejected } from 'shared/lib/types/Asset';
import { useProject } from '../../../contexts/ProjectContext';
import { Modal } from '../../Modal/Modal';
import { TextArea } from '../../TextArea/TextArea';

export interface RejectedAssetModalProps {
  assetId: number;
  onClose(): unknown;
  className?: string;
}

export const RejectedAssetModal: FC<RejectedAssetModalProps> = ({
  className = '',
  assetId,
  ...rest
}) => {
  const { project } = useProject();
  const asset = useMemo<Asset | undefined>(() => {
    if (project && areAssetsLoaded(project)) {
      return project.assets.find((other) => other.id === assetId);
    }
  }, [project, assetId]);

  if (project && !areAssetsLoaded(project)) {
    // Cannot reject assets if the PRA is unsigned
    // TODO: add an error modal?
    return null;
  }

  if (project && !asset) {
    // Asset doesn't exist
    return null;
  }

  if (asset && !isAssetRejected(asset)) {
    // Asset is no longer rejected
    return null;
  }

  return (
    <Modal {...rest} className={`w-168 ${className}`} showSpinner={!asset}>
      <h1 className="text-4xl text-center">Rejected Asset</h1>
      <p className="mt-8 mx-8">
        This asset was rejected for the following reason(s):
      </p>
      <TextArea
        disabled
        value={asset?.rejectMessage ?? ''}
        className="mt-3 mx-8"
      />
    </Modal>
  );
};
