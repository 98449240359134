import { FC, useCallback, useEffect, useState } from 'react';
import {
  getProjectModalValueFromProject,
  ProjectModal,
  ProjectModalProps,
  ProjectModalValue,
} from './ProjectModal';
import { EndpointProps } from 'shared/lib/api';
import { useProject } from '../../../contexts/ProjectContext';

export interface EditProjectModalProps
  extends Omit<
    ProjectModalProps,
    'title' | 'value' | 'submitButtonText' | 'onSubmit'
  > {
  onClose(): unknown;
}

export const EditProjectModal: FC<EditProjectModalProps> = ({
  onClose,
  ...rest
}) => {
  const { projectId, project, updateProject } = useProject();
  const [initialValue, setInitialValue] = useState<ProjectModalValue | null>(
    () => (project ? getProjectModalValueFromProject(project) : null),
  );

  useEffect(() => {
    if (project) {
      setInitialValue(
        (initialValue) =>
          initialValue ?? getProjectModalValueFromProject(project),
      );
    }
  }, [project]);

  const handleSubmit = useCallback(
    async (value: ProjectModalValue) => {
      await updateProject({ ...value, projectId } as EndpointProps<
        'updateProject'
      >);
      onClose();
    },
    [updateProject, onClose, projectId],
  );

  return (
    <ProjectModal
      {...rest}
      key={
        initialValue ? 'loaded' : 'loading' /* Refreshes hooks when changed */
      }
      showArchivedButtons
      showSpinner={!initialValue}
      value={initialValue}
      title="Edit Project Info"
      submitButtonText="Save Project"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
