import { FC, ReactNode } from 'react';

export interface RadioListInputProps {
  groupId: string;
  value: string | number | boolean | null;
  options: { value: string | number | boolean; label: ReactNode }[];
  className?: string;
  onChange(value: string): unknown;
}

export const RadioListInput: FC<RadioListInputProps> = ({
  groupId,
  value,
  options,
  className = '',
  onChange,
  ...rest
}) => {
  const valueString = value?.toString() ?? null;

  return (
    <ul
      {...rest}
      onChange={(event) => {
        onChange((event.target as HTMLInputElement)?.value);
      }}
    >
      {options.map((option, i) => {
        const optionValueString = option.value.toString();
        const checked = valueString === optionValueString;

        return (
          <li key={optionValueString} className={`${i > 0 ? 'mt-5' : ''}`}>
            <label className="row cursor-pointer">
              <div className="flex-shrink-0 col justify-center items-center w-6 h-6 rounded-full border border-gray-300">
                <div
                  className={`rounded-full bg-uo-green w-4 h-4 transition-opacity ${
                    checked ? '' : 'opacity-0'
                  }`}
                />
              </div>
              <input
                type="radio"
                name={groupId}
                value={optionValueString}
                className="sr-only"
              />
              <div className="ml-5">{option.label}</div>
            </label>
          </li>
        );
      })}
    </ul>
  );
};
