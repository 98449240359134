import React, { FC, useCallback, useMemo } from 'react';
import { DepartmentModal, DepartmentModalValue } from './DepartmentModal';
import { useDepartmentList } from '../../../contexts/DepartmentListContext';

export interface EditDepartmentModalProps {
  departmentId: number;
  onClose(): unknown;
}

export const EditDepartmentModal: FC<EditDepartmentModalProps> = ({
  departmentId,
  onClose,
  ...rest
}) => {
  const { departments, updateDepartment } = useDepartmentList();
  const initialValue: DepartmentModalValue | undefined = useMemo(() => {
    return departments.find((department) => department.id === departmentId);
  }, [departments, departmentId]);

  const handleSubmit = useCallback(
    async (value: DepartmentModalValue) => {
      await updateDepartment({ ...value, departmentId });
      onClose();
    },
    [updateDepartment, onClose, departmentId],
  );

  return (
    <DepartmentModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit Department"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
