import React, { FC, useMemo } from 'react';
import { Obligation } from 'shared/lib/types/Obligation';
import { sortByDate } from 'shared/lib/utils/sort';
import { ObligationListItem } from './ObligationListItem';

export interface ObligationListProps {
  className?: string;
  obligations: Pick<Obligation, 'id' | 'name' | 'description' | 'createdAt'>[];
}

export const ObligationList: FC<ObligationListProps> = ({
  obligations,
  className = '',
  ...rest
}) => {
  const sortedObligations = useMemo(
    () =>
      [...obligations].sort((a, b) =>
        sortByDate(new Date(b.createdAt), new Date(a.createdAt)),
      ),
    [obligations],
  );

  return (
    <ul {...rest} className={`col ${className}`}>
      {sortedObligations.map((obligation, i) => (
        <ObligationListItem
          key={obligation.id}
          obligation={obligation}
          className={i < sortedObligations.length - 1 ? 'mb-3' : ''}
        />
      ))}
    </ul>
  );
};
