import { FC } from 'react';
import { useUserList } from '../../contexts/UserListContext';
import { Button } from '../Button/Button';
import { UserTableRow } from './UserTableRow';

export interface UserTableProps {
  className?: string;
  searchText?: string;
}

export const UserTable: FC<UserTableProps> = ({
  className = '',
  searchText,
  ...rest
}) => {
  const { users, isLastPage, loadNextPage } = useUserList();

  return (
    <div {...rest} className={`col ${className}`}>
      <div className="row pb-3 px-5">
        <div className="w-0 flex-grow-3 text-xl font-bold mr-4">Name</div>
        <div className="w-0 flex-grow-4 text-xl font-bold mr-4">Email</div>
        <div className="w-0 flex-grow-2 text-xl font-bold mr-4">Role</div>
        <div className="w-0 flex-grow-2 text-xl font-bold mr-4">
          Affiliation
        </div>
        <div className="w-0 flex-grow-2 text-xl font-bold mr-4 text-center">
          Active Projects
        </div>
        <div className="w-0 flex-grow text-xl font-bold">
          {/* More button column */}
        </div>
      </div>
      <ul className="col px-5 bg-white text-gray-800 text-sm rounded shadow-6xl">
        {users.map((user) => (
          <UserTableRow key={user.id} user={user} />
        ))}
      </ul>
      {!isLastPage && (
        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="w-48 my-8 self-center"
          onClick={loadNextPage}
        >
          More
        </Button>
      )}
    </div>
  );
};
