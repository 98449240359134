import { FC, useState, useCallback } from 'react';
import useInputState from '../../../utils/react/useInputState';
import { isBlank } from 'shared/lib/utils/isBlank';
import { ReactComponent as HelpCircleIcon } from '../../../images/icons/help-circle.svg';
import { Modal, ModalProps } from '../../Modal/Modal';
import { formatDate } from '../../../utils/formatDate';
import { useProgramList } from '../../../contexts/ProgramListContext';
import { useCollegeList } from '../../../contexts/CollegeListContext';
import { useDepartmentList } from '../../../contexts/DepartmentListContext';
import { useProjectRulesAgreementList } from '../../../contexts/ProjectRulesAgreementListContext';
import { useStaffManagerList } from '../../../contexts/StaffManagerContext';
import { useProjectMemberRoleList } from '../../../contexts/ProjectMemberRoleListContext';
import { SelectList } from '../../SelectList/SelectList';
import {
  ProjectMemberTableForm,
  ProjectMemberTableFormValue,
} from '../../ProjectMemberTableForm/ProjectMemberTableForm';
import { Switch } from '../../Switch/Switch';
import { TextArea } from '../../TextArea/TextArea';
import { Select } from '../../Select/Select';
import { Button } from '../../Button/Button';
import { SpinnerOverlay } from '../../SpinnerOverlay/SpinnerOverlay';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { ReactComponent as InfoIcon } from '../../../images/icons/info.svg';
import { Flyout } from '../../Flyout/Flyout';
import { RestrictedDetailedProject } from 'shared/lib/types/Project';
import { useAccount } from '../../../contexts/AccountContext';
import {
  canUserManageColleges,
  canUserManageDepartments,
  canUserManagePrograms,
  canUserManageProjectRulesAgreements,
  canUserManageUsers,
} from 'shared/lib/utils/permissions';
import { CreateProjectRulesAgreementModal } from '../projectRulesAgreementModals/CreateProjectRulesAgreementModal';
import { ProjectRulesAgreementModalValue } from '../projectRulesAgreementModals/ProjectRulesAgreementModal';
import { useApi } from '../../../contexts/ApiContext';
import { TextInput } from '../../TextInput/TextInput';

const defaultProjectModalValue: ProjectModalValue = {
  name: '',
  description: '',
  obligationsEnabled: true,
  assetsEnabled: true,
  projectRulesAgreementId: null,
  archived: false,
  staffManagerId: null,
  collegeIds: [],
  departmentIds: [],
  programIds: [],
  members: [],
  createdAt: new Date().toISOString(),
};

const projectMemberRoleHelp: Record<string, string | undefined> = {
  Supervisor:
    'Supervisors are legally and fiscally responsible for overseeing project outcomes and deliverables, often due to the terms of a funding agreement (Obligation) and their formal role as a Principal Investigator. Typically, a Supervisor will be a Principal Investigator (PI), a co-PI or the head of a department, center or institute.',
  Manager:
    'Managers have delegated authority from the Supervisor(s) to manage project Members, decide whether Member-contributed Assets should be included in a project, and to add and update project Obligations to provide Members with visibility. The Supervisor retains ultimate responsibility for the Project.',
  Member:
    'Members are project participants expected to contribute content, conduct research, or otherwise participate under the terms of Project Rules Agreement (PRA).',
};

export function getProjectModalValueFromProject(
  project: RestrictedDetailedProject,
): ProjectModalValue {
  return {
    name: project.name,
    description: project.description,
    obligationsEnabled: project.obligationsEnabled,
    assetsEnabled: project.assetsEnabled,
    projectRulesAgreementId: project.projectRulesAgreementId,
    staffManagerId: project.staffManagerId,
    collegeIds: project.colleges.map((college) => college.id),
    departmentIds: project.departments.map((department) => department.id),
    programIds: project.programs.map((program) => program.id),
    archived: project.archived,
    members:
      project.members?.map((member) => ({
        userId: member.userId,
        name: member.user.name,
        email: member.user.email,
        projectMemberRoleId: member.projectMemberRoleId,
        userRoleId: member.user.roleId,
      })) ?? [],
    createdAt: project.createdAt,
  };
}

export interface ProjectModalValue {
  name: string;
  description: string;
  obligationsEnabled: boolean;
  assetsEnabled: boolean;
  archived: boolean;
  projectRulesAgreementId: number | null;
  staffManagerId: number | null;
  collegeIds: number[];
  departmentIds: number[];
  programIds: number[];
  members: ProjectMemberTableFormValue[];
  createdAt: string;
}

export interface ProjectModalProps extends ModalProps {
  title: string;
  submitButtonText: string;
  showSpinner?: boolean;
  showRulesAgreementInput?: boolean;
  showArchivedButtons?: boolean;
  value?: ProjectModalValue | null;
  onSubmit(value: ProjectModalValue): unknown;
}

export const ProjectModal: FC<ProjectModalProps> = ({
  title,
  submitButtonText,
  showSpinner,
  showRulesAgreementInput = true,
  showArchivedButtons = false,
  value: rawValue,
  onSubmit,
  ...rest
}) => {
  const api = useApi();
  const initialValue = rawValue || defaultProjectModalValue;
  const [showAddRulesModal, setShowAddRulesModal] = useState(false);
  const { user } = useAccount();
  const { colleges } = useCollegeList();
  const { departments } = useDepartmentList();
  const { programs } = useProgramList();
  const {
    publicProjectRulesAgreements,
    projectRulesAgreements,
  } = useProjectRulesAgreementList();
  const { staffManagers } = useStaffManagerList();
  const { projectMemberRoles } = useProjectMemberRoleList();

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [name, setName] = useState(initialValue.name);
  const [
    pendingRulesAgreementValue,
    setPendingRulesAgreementValue,
  ] = useState<ProjectRulesAgreementModalValue | null>(null);
  const [description, handleDescriptionChange] = useInputState(
    initialValue.description,
  );
  const [obligationsEnabled, setObligationsEnabled] = useState(
    initialValue.obligationsEnabled,
  );
  const [assetsEnabled, setAssetsEnabled] = useState(
    initialValue.assetsEnabled ?? false,
  );
  const [projectRulesAgreementId, setProjectRulesAgreementId] = useState<
    number | null
  >(initialValue.projectRulesAgreementId);
  const [staffManagerId, setStaffManagerId] = useState(
    initialValue.staffManagerId,
  );
  const [collegeIds, setCollegeIds] = useState(initialValue.collegeIds);
  const [departmentIds, setDepartmentIds] = useState(
    initialValue.departmentIds,
  );
  const [programIds, setProgramIds] = useState(initialValue.programIds);
  const [members, setMembers] = useState(initialValue.members);
  const [archived, setArchived] = useState(initialValue.archived);
  const createdAt = initialValue.createdAt;
  const canSubmit = !submitting && !isBlank(name);

  const canEditColleges = !!(user && canUserManageColleges(user));
  const canEditDepartments = !!(user && canUserManageDepartments(user));
  const canEditPrograms = !!(user && canUserManagePrograms(user));
  const canEditStaffManager = !!(user && canUserManageUsers(user));

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit({
        name,
        description,
        obligationsEnabled,
        assetsEnabled,
        staffManagerId,
        collegeIds,
        departmentIds,
        programIds,
        archived,
        members,
        createdAt,
        projectRulesAgreementId: pendingRulesAgreementValue
          ? (await api.createProjectRulesAgreement(pendingRulesAgreementValue))
              .id
          : projectRulesAgreementId,
      });
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  }, [
    onSubmit,
    api,
    name,
    description,
    obligationsEnabled,
    assetsEnabled,
    archived,
    projectRulesAgreementId,
    staffManagerId,
    collegeIds,
    departmentIds,
    programIds,
    members,
    createdAt,
    pendingRulesAgreementValue,
  ]);

  const handleNameChange = useCallback(
    (newName: string) => {
      setName(newName);
      if (pendingRulesAgreementValue) {
        setPendingRulesAgreementValue({
          ...pendingRulesAgreementValue,
          name: newName,
        });
      }
    },
    [pendingRulesAgreementValue],
  );

  const handleAddRulesAgreement = useCallback(() => {
    setShowAddRulesModal(true);
  }, []);

  return (
    <>
      <Modal {...rest} className="relative w-168 lg:w-200 text-left">
        {(submitting || showSpinner) && (
          <SpinnerOverlay
            message={submitting && `Saving project`}
            className="rounded-xl"
          />
        )}
        <div className="row items-center justify-center mb-16">
          <h1 className="text-4xl text-center">{title}</h1>
          <div className="relative ml-8">
            <Button backgroundColor="none" textColor="black">
              <HelpCircleIcon />
            </Button>
            <Flyout
              arrowSide="top-right"
              color="uo-yellow"
              className="adjacent-hover-visible absolute top-0 right-0 mt-16 w-168"
            >
              <h4 className="font-semibold">Project</h4>
              <p className="mt-3">
                A project is a primary platform for the management, development
                and dissemination of information assets (“Assets”) but may also
                be used to manage expectations with respect to professional
                behavior of a research group or to ensure regulatory and privacy
                issues are addressed upfront. This includes defining the roles
                and authority of each project participant (“Member”) and the
                ground rules governing the project, including an acknowledgement
                from each Member that establishes their commitment and the
                benefits the project offers to each (“Project Rules”).
                Additionally, most projects include a section on financial
                expectations in the Project Rules or reference a separate
                financial memorandum that connects the development and value
                capture of Assets back to the University and requires tracking
                obligations to sponsors funding the work (“Funds”). This Tool
                creates a centralized platform to organize Assets, Members,
                Project Rules and Funds.
              </p>
            </Flyout>
          </div>
        </div>

        {showArchivedButtons && (
          <div className="row justify-center mb-16">
            <Button
              onClick={() => setArchived(false)}
              backgroundColor={archived ? 'none' : 'uo-green'}
              textColor={archived ? 'gray' : 'white'}
              className="w-24"
            >
              Active
            </Button>
            <Button
              onClick={() => setArchived(true)}
              backgroundColor={archived ? 'uo-green' : 'none'}
              textColor={archived ? 'white' : 'gray'}
              className="w-24 ml-4"
            >
              Archived
            </Button>
          </div>
        )}

        <div className="col items-center mb-6">
          <label className="font-semibold mb-1">Project Title</label>
          <TextInput
            placeholder="New Project Name Here"
            className="text-3xl text-center w-full"
            value={name}
            onTextChange={handleNameChange}
          />
        </div>

        <div className="text-center mb-8 text-sm">
          <span className="font-semibold">Date Created:</span>{' '}
          <time>{formatDate(initialValue.createdAt)}</time>
        </div>

        <div className="row items-center justify-center mb-8">
          <label className="row items-center cursor-pointer">
            <div className="font-semibold mr-6">Obligations</div>
            <Switch
              checked={obligationsEnabled}
              onChange={setObligationsEnabled}
            />
          </label>
          <label className="row items-center cursor-pointer ml-24">
            <div className="font-semibold mr-6">Assets</div>
            <Switch checked={assetsEnabled} onChange={setAssetsEnabled} />
          </label>
        </div>

        <div className="col">
          <label className="font-semibold mb-3">Project Description</label>
          <TextArea
            className="mb-8"
            placeholder="Description Goes Here"
            value={description}
            onChange={handleDescriptionChange}
          />

          {showRulesAgreementInput && (
            <>
              <label className="row mb-3">
                <span className="font-semibold">PRA</span>
                <div className="relative ml-5">
                  <InfoIcon />
                  <Flyout
                    color="uo-yellow"
                    arrowSide="top-left"
                    className="w-100 absolute top-0 left-0 mt-10 -ml-4 adjacent-hover-visible"
                  >
                    A Project Rule Agreement (PRA) establishes ground rules
                    which govern the project including a participation
                    acknowledgement that details the commitments of project
                    participants and the benefits the project offers to each.
                    All projects require a PRA and every participant must agree
                    to the terms before accessing the particular project.
                  </Flyout>
                </div>
              </label>
              <Select
                className="mb-3"
                value={
                  pendingRulesAgreementValue
                    ? // Pending value is only used when a custom PRA is created but not saved
                      'PENDING'
                    : projectRulesAgreementId
                }
                onValueChange={(value) => {
                  if (!value) {
                    setPendingRulesAgreementValue(null);
                    setProjectRulesAgreementId(null);
                  } else if (value === 'CREATE_NEW_PRA') {
                    setPendingRulesAgreementValue(null);
                    setProjectRulesAgreementId(null);
                    handleAddRulesAgreement();
                  } else if (value !== 'PENDING') {
                    setProjectRulesAgreementId(value ? +value : null);
                  }
                }}
                options={[
                  {
                    value: 'CREATE_NEW_PRA',
                    label: 'Create Project Rules For My Project',
                  },
                  ...(pendingRulesAgreementValue
                    ? [
                        {
                          value: 'PENDING',
                          label: pendingRulesAgreementValue.name,
                        },
                      ]
                    : []),

                  /**
                   * Admins can pick from any PRA, but non-admins can only choose public PRAs.
                   */
                  ...(user && canUserManageProjectRulesAgreements(user)
                    ? projectRulesAgreements
                    : publicProjectRulesAgreements
                  ).map((projectRulesAgreement) => ({
                    value: projectRulesAgreement.id.toString(),
                    label: projectRulesAgreement.name,
                  })),
                ]}
              />
            </>
          )}

          <p className="text-gray-800 text-xs mb-8">
            Users will receive an invitation email with a link to sign the PRA*
          </p>

          {(canEditStaffManager || canEditColleges) && (
            <div className="row mb-8">
              {canEditStaffManager && (
                <div
                  className={`col w-0 flex-grow ${
                    canEditColleges ? 'mr-6' : ''
                  }`}
                >
                  <label className="font-semibold mb-3">IPS Staff</label>
                  <Select
                    value={staffManagerId}
                    onValueChange={(value) =>
                      setStaffManagerId(value ? +value : null)
                    }
                    options={staffManagers.map((staffManager) => ({
                      value: staffManager.id.toString(),
                      label: staffManager.name,
                    }))}
                  />
                </div>
              )}
              {canEditColleges && (
                <div className="col w-0 flex-grow">
                  <label className="font-semibold mb-3">College</label>
                  <SelectList
                    values={collegeIds}
                    onChange={setCollegeIds}
                    options={colleges.map((college) => ({
                      value: college.id.toString(),
                      label: college.name,
                    }))}
                  />
                </div>
              )}
            </div>
          )}

          {(canEditDepartments || canEditPrograms) && (
            <div className="row mb-8">
              {canEditDepartments && (
                <div
                  className={`col w-0 flex-grow ${
                    canEditPrograms ? 'mr-6' : ''
                  }`}
                >
                  <label className="font-semibold mb-3">Department</label>
                  <SelectList
                    values={departmentIds}
                    onChange={setDepartmentIds}
                    options={departments.map((department) => ({
                      value: department.id.toString(),
                      label: department.name,
                    }))}
                  />
                </div>
              )}
              {canEditPrograms && (
                <div className="col w-0 flex-grow">
                  <label className="font-semibold mb-3">Program</label>
                  <SelectList
                    values={programIds}
                    onChange={setProgramIds}
                    options={programs.map((program) => ({
                      value: program.id.toString(),
                      label: program.name,
                    }))}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {projectMemberRoles.map((projectMemberRole, i) => (
          <div
            key={projectMemberRole.id}
            className={`col ${i < projectMemberRoles.length - 1 ? 'mb-8' : ''}`}
          >
            <label className="row mb-3">
              <span className="font-semibold">{projectMemberRole.name}</span>
              {projectMemberRoleHelp.hasOwnProperty(projectMemberRole.name) && (
                <div className="relative ml-5">
                  <InfoIcon />
                  <Flyout
                    color="uo-yellow"
                    arrowSide="top-left"
                    className="w-100 absolute top-0 left-0 mt-10 -ml-4 adjacent-hover-visible"
                  >
                    {projectMemberRoleHelp[projectMemberRole.name]}
                  </Flyout>
                </div>
              )}
            </label>
            <ProjectMemberTableForm
              projectMemberRole={projectMemberRole}
              values={members}
              onChange={setMembers}
            />
          </div>
        ))}

        {submitError && (
          <p className="mb-8 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}

        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="self-center w-48 mt-16"
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
      </Modal>
      {showAddRulesModal && (
        <CreateProjectRulesAgreementModal
          forcePublic
          forceName={name}
          autoCreate={false}
          onClose={() => {
            setShowAddRulesModal(false);
          }}
          onSubmit={setPendingRulesAgreementValue}
        />
      )}
    </>
  );
};
