import { FC, FormEvent, useState, useCallback } from 'react';
import RichTextEditor, { EditorValue } from 'react-rte';
import { isBlank } from 'shared/lib/utils/isBlank';
import { PraBuilderOptions } from 'shared/lib/types/PraBuilder';
import { Modal, ModalProps } from '../../Modal/Modal';
import { Button } from '../../Button/Button';
import { TextInput } from '../../TextInput/TextInput';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { Switch } from '../../Switch/Switch';
import { autoFocus } from '../../../utils/autoFocus';
import { ProjectRulesAgreementBuilderModal } from '../ProjectRulesAgreementBuilderModal/ProjectRulesAgreementBuilderModal';

export interface ProjectRulesAgreementModalValue {
  name: string;
  description: string;
  public: boolean;
  praBuilderOptions: PraBuilderOptions | null;
}

export interface ProjectRulesAgreementModalProps extends ModalProps {
  title: string;
  forceName?: string;
  forcePublic?: boolean;
  initialValue?: ProjectRulesAgreementModalValue;
  showSpinner?: boolean;
  submitButtonText?: string;
  onSubmit?(value: ProjectRulesAgreementModalValue): unknown;
}

export const ProjectRulesAgreementModal: FC<ProjectRulesAgreementModalProps> = ({
  title,
  forceName,
  forcePublic,
  initialValue,
  showSpinner,
  submitButtonText,
  onSubmit,
  ...rest
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [name, setName] = useState(forceName ?? initialValue?.name ?? '');
  const [description, setDescription] = useState(() =>
    EditorValue.createFromString(initialValue?.description ?? '', 'markdown'),
  );
  const [usePraBuilder, setUsePraBuilder] = useState(
    initialValue ? !!initialValue.praBuilderOptions : true,
  );
  const [showPraBuilder, setShowPraBuilder] = useState(false);
  const [isPublic, setPublic] = useState(
    forcePublic ?? initialValue?.public ?? false,
  );

  const canSubmit = typeof forceName === 'string' || !isBlank(name);

  const handlePlainTextSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setSubmitting(true);
      try {
        if (onSubmit) {
          await onSubmit({
            name,
            description: description.toString('markdown'),
            praBuilderOptions: null,
            public: isPublic,
          });
        }
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, name, description, isPublic],
  );

  const handleLaunchPraBuilder = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setShowPraBuilder(true);
    },
    [],
  );

  if (showPraBuilder) {
    return (
      <ProjectRulesAgreementBuilderModal
        fadeIn={false}
        initialOptions={initialValue?.praBuilderOptions ?? undefined}
        projectName={forceName ?? name}
        onClose={rest.onClose}
        onBack={() => setShowPraBuilder(false)}
        submitButtonText={submitButtonText}
        onSubmit={async (result) => {
          if (onSubmit) {
            await onSubmit({
              name,
              description: result.praMarkdown,
              praBuilderOptions: result.praBuilderOptions,
              public: isPublic,
            });
          }
        }}
      />
    );
  }

  return (
    <Modal
      {...rest}
      className="relative w-168 text-left"
      fadeIn={false}
      showSpinner={submitting}
    >
      <form
        className="col"
        onSubmit={
          usePraBuilder ? handleLaunchPraBuilder : handlePlainTextSubmit
        }
      >
        <h1 className="text-4xl text-center font-normal">{title}</h1>

        <div className="row justify-center">
          <label className="row items-center cursor-pointer mt-8">
            <div className="mr-6">Use PRA Builder</div>
            <Switch checked={usePraBuilder} onChange={setUsePraBuilder} />
          </label>
          {!forcePublic && (
            <label className="row items-center cursor-pointer mt-8 ml-24">
              <div className="mr-6">Public</div>
              <Switch checked={isPublic} onChange={setPublic} />
            </label>
          )}
        </div>

        <label className="font-semibold mt-8">PRA Title</label>
        <TextInput
          value={name}
          disabled={typeof forceName === 'string'}
          onTextChange={setName}
          inputRef={autoFocus}
          className="mt-3"
        />

        {!usePraBuilder && (
          <>
            <label className="font-semibold mt-8">PRA Outline</label>
            <RichTextEditor
              value={description}
              onChange={setDescription}
              placeholder="Description Goes Here"
              className="mt-1"
            />

            {submitError && (
              <p className="mt-8 text-red text-center text-lg">
                {getErrorMessage(submitError)}
              </p>
            )}
          </>
        )}

        {usePraBuilder ? (
          <Button
            key="launch-pra-builder-button"
            backgroundColor="uo-yellow"
            textColor="black"
            className="mt-16 w-48 self-center"
          >
            Launch PRA Builder
          </Button>
        ) : (
          <Button
            key="add-pra-button"
            backgroundColor="uo-green"
            textColor="white"
            className="mt-16 w-48 self-center"
            disabled={!canSubmit}
          >
            {submitButtonText}
          </Button>
        )}
      </form>
    </Modal>
  );
};
