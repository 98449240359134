import React, { FC } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { showConfirm } from '../ConfirmModal/showConfirm';
import { Flyout } from '../Flyout/Flyout';
import useToggleState from '../../utils/react/useToggleState';
import { Link } from 'react-router-dom';
import { Program } from 'shared/lib/types/Program';
import { useProgramList } from '../../contexts/ProgramListContext';

export interface ProgramListItemProps {
  program: Program;
  className?: string;
}

export const ProgramListItem: FC<ProgramListItemProps> = ({
  program,
  className = '',
  ...rest
}) => {
  const { deleteProgram } = useProgramList();
  const [showMenu, toggleMenu] = useToggleState();

  return (
    <li
      {...rest}
      className={`row items-center justify-between py-3 ${className}`}
    >
      <div className="text-sm font-semibold text-gray-800 flex-grow">
        {program.name}
      </div>
      <div className="relative flex-shrink-0">
        <Button
          backgroundColor="none"
          textColor="uo-green"
          size="small"
          onClick={toggleMenu}
        >
          <MoreIcon />
        </Button>
        {showMenu && (
          <Flyout
            arrowSide="top-right"
            color="uo-green"
            borderRadius="xl"
            className="w-52 top-0 right-0 mt-12 -mr-2"
            onClickOutside={toggleMenu}
          >
            <Link to={`/admin/program/${program.id}/edit`}>
              <Button
                backgroundColor="none"
                textColor="white"
                size="small"
                className="row items-center px-0 mb-3"
                onClick={toggleMenu}
              >
                <EditIcon className="mr-3" /> Edit Program
              </Button>
            </Link>
            <Button
              backgroundColor="none"
              textColor="white"
              size="small"
              className="row items-center px-0"
              onClick={async () => {
                toggleMenu();
                if (
                  await showConfirm({
                    title: 'Delete Program',
                    message: `You are about to delete program “${program.name}.” Are you sure you wish to delete this program?`,
                    confirmButtonText: 'Delete',
                  })
                ) {
                  await deleteProgram({ programId: program.id });
                }
              }}
            >
              <DeleteIcon className="mr-3" /> Delete Program
            </Button>
          </Flyout>
        )}
      </div>
    </li>
  );
};
