import React, { FC, useCallback } from 'react';
import { CollegeModal, CollegeModalValue } from './CollegeModal';
import { useCollegeList } from '../../../contexts/CollegeListContext';

export interface CreateCollegeModalProps {
  onClose(): unknown;
}

export const CreateCollegeModal: FC<CreateCollegeModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createCollege } = useCollegeList();

  const handleSubmit = useCallback(
    async (value: CollegeModalValue) => {
      await createCollege(value);
      onClose();
    },
    [createCollege, onClose],
  );

  return (
    <CollegeModal
      {...rest}
      title="Add College"
      submitButtonText="Add College"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
