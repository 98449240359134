import React, { FC, useCallback } from 'react';
import { DepartmentModal, DepartmentModalValue } from './DepartmentModal';
import { useDepartmentList } from '../../../contexts/DepartmentListContext';

export interface CreateDepartmentModalProps {
  onClose(): unknown;
}

export const CreateDepartmentModal: FC<CreateDepartmentModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createDepartment } = useDepartmentList();

  const handleSubmit = useCallback(
    async (value: DepartmentModalValue) => {
      await createDepartment(value);
      onClose();
    },
    [createDepartment, onClose],
  );

  return (
    <DepartmentModal
      {...rest}
      title="Add Department"
      submitButtonText="Add Department"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
