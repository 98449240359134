import {
  FC,
  FormEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import {
  PraBuilderOptions,
  PraRevenuePolicy,
} from 'shared/lib/types/PraBuilder';
import { ReactComponent as ArrowLeftIcon } from '../../../images/icons/arrow-left.svg';
import { isString } from '../../../utils/isString';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { Button } from '../../Button/Button';
import { Modal, ModalProps } from '../../Modal/Modal';
import { RadioListInput } from '../../RadioListInput/RadioListInput';
import { Select } from '../../Select/Select';
import { SpinnerOverlay } from '../../SpinnerOverlay/SpinnerOverlay';
import { TextArea } from '../../TextArea/TextArea';
import { TextInput } from '../../TextInput/TextInput';
import { escapeMarkdown } from '../../../utils/escapeMarkdown';
import { PraText } from '../../PraText/PraText';

enum PraBuilderModalPage {
  PROJECT_OVERVIEW_1 = 0,
  PROJECT_OVERVIEW_2 = 1,
  PARTICIPANTS = 2,
  REVENUE_DISTRIBUTION = 3,
  USE_OF_WORK = 4,
  REVIEW = 5,
}

const revenuePolicyOptions = [
  {
    value: PraRevenuePolicy.WAIVE_SHARE,
    label:
      'a) Have all project participants waive any personal royalty share back to the center, institute or department primarily associated with this project.',
  },
  {
    value: PraRevenuePolicy.EQUAL_SHARE,
    label:
      'b) Have all project participants share equally in any revenue generated and allocated to personal shares in accordance with UO policy.',
  },
  {
    value: PraRevenuePolicy.STANDARD_RSA,
    label:
      'c) Have all project participants receive personal royalty shares as determined by a future royalty sharing agreement to be created in accordance with UO policy.',
  },
];

interface PraBuilderModalPageError {
  error: Error;
  page: PraBuilderModalPage;
}
interface ProjectRulesAgreementBuilderModalValue {
  praMarkdown: string;
  praBuilderOptions: PraBuilderOptions;
}

export const ProjectRulesAgreementBuilderModal: FC<
  ModalProps & {
    projectName?: string;
    initialOptions?: PraBuilderOptions;
    submitButtonText?: ReactNode;
    onSubmit(value: ProjectRulesAgreementBuilderModalValue): unknown;
    onBack?(): unknown;
  }
> = ({
  projectName = 'Project',
  submitButtonText = 'Add PRA',
  initialOptions,
  onClose,
  onSubmit,
  onBack,
  ...rest
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    PraBuilderModalPageError[]
  >([]);
  const [pageNumber, setPageNumber] = useState(
    PraBuilderModalPage.PROJECT_OVERVIEW_1,
  );
  const currentPageValidationErrors = validationErrors.filter(
    ({ page }) => page === pageNumber,
  );
  const [departmentName, setDepartmentName] = useState(
    initialOptions?.departmentName ?? '',
  );
  const [departmentAbbreviation, setDepartmentAbbreviation] = useState(
    initialOptions?.departmentAbbreviation ?? '',
  );
  const [departmentMission, setDepartmentMission] = useState(
    initialOptions?.departmentMission ?? '',
  );
  const [projectGoal, setProjectGoal] = useState(
    initialOptions?.projectGoal ?? '',
  );
  const [
    participantContributionActions,
    setParticipantContributionActions,
  ] = useState(initialOptions?.participantContributionActions ?? '');
  const [
    participantContributionTangible,
    setParticipantContributionTangible,
  ] = useState(initialOptions?.participantContributionTangible ?? '');
  const [nonUoParticipants, setNonUoParticipants] = useState<boolean | null>(
    initialOptions?.nonUoParticipants ?? null,
  );
  const [studentParticipants, setStudentParticipants] = useState<
    boolean | null
  >(initialOptions?.studentParticipants ?? null);
  const [
    revenueDistributionPolicy,
    setRevenueDistributionPolicy,
  ] = useState<PraRevenuePolicy | null>(
    initialOptions?.revenueDistributionPolicy ?? null,
  );
  const [confidential, setConfidential] = useState<boolean | null>(
    initialOptions?.confidential ?? null,
  );

  const praBuilderOptions: PraBuilderOptions = useMemo(
    () => ({
      departmentName,
      departmentAbbreviation,
      departmentMission,
      projectGoal,
      participantContributionActions,
      participantContributionTangible,
      nonUoParticipants,
      studentParticipants,
      revenueDistributionPolicy,
      confidential,
    }),
    [
      confidential,
      departmentAbbreviation,
      departmentMission,
      departmentName,
      nonUoParticipants,
      participantContributionActions,
      participantContributionTangible,
      projectGoal,
      revenueDistributionPolicy,
      studentParticipants,
    ],
  );

  const praMarkdown = useMemo(
    () => renderRulesAgreementMarkdown(praBuilderOptions, projectName),
    [praBuilderOptions, projectName],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();

        /**
         * Validate various inputs. If any fields are missing, the form jumps back
         * to the page that field is on and displays all validation errors.
         */
        const pendingValidationErrors: PraBuilderModalPageError[] = [];

        if (isBlank(praBuilderOptions.departmentName)) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PROJECT_OVERVIEW_1,
            error: new Error('Department name is required.'),
          });
        }

        if (isBlank(praBuilderOptions.departmentAbbreviation)) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PROJECT_OVERVIEW_1,
            error: new Error('Department abbreviation is required.'),
          });
        }

        if (isBlank(praBuilderOptions.departmentMission)) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PROJECT_OVERVIEW_1,
            error: new Error('Department mission is required.'),
          });
        }

        if (isBlank(praBuilderOptions.projectGoal)) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PROJECT_OVERVIEW_2,
            error: new Error('Project goal is required.'),
          });
        }

        if (isBlank(praBuilderOptions.participantContributionActions)) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PROJECT_OVERVIEW_2,
            error: new Error('Participant contribution actions are required.'),
          });
        }

        if (isBlank(praBuilderOptions.participantContributionTangible)) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PROJECT_OVERVIEW_2,
            error: new Error(
              'Participant contribution tangibles are required.',
            ),
          });
        }

        if (praBuilderOptions.nonUoParticipants === null) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PARTICIPANTS,
            error: new Error(
              'You must specify whether or not non-UO participants will be allowed.',
            ),
          });
        }

        if (praBuilderOptions.studentParticipants === null) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.PARTICIPANTS,
            error: new Error(
              'You must specify whether or not student participants will be allowed.',
            ),
          });
        }

        if (praBuilderOptions.revenueDistributionPolicy === null) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.REVENUE_DISTRIBUTION,
            error: new Error('You must choose a revenue policy.'),
          });
        }

        if (praBuilderOptions.confidential === null) {
          pendingValidationErrors.push({
            page: PraBuilderModalPage.USE_OF_WORK,
            error: new Error(
              'You specify whether or not the project has a confidentiality obligation.',
            ),
          });
        }

        // If there are any validation errors, display them all and jump to the first page with an error
        if (pendingValidationErrors.length > 0) {
          setValidationErrors(pendingValidationErrors);
          setPageNumber(pendingValidationErrors[0].page);
          return;
        }

        setSubmitting(true);
        await onSubmit({
          praMarkdown,
          praBuilderOptions,
        });
        onClose();
      } catch (error) {
        setSubmitError(error);
        setSubmitting(false);
      }
    },
    [praMarkdown, praBuilderOptions, onSubmit, onClose],
  );

  const handleNext = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (pageNumber !== PraBuilderModalPage.REVIEW) {
        setPageNumber(pageNumber + 1);
      }
    },
    [pageNumber],
  );

  const handleBack = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (pageNumber === PraBuilderModalPage.PROJECT_OVERVIEW_1) {
        if (onBack) {
          onBack();
        }
      } else {
        setPageNumber(pageNumber - 1);
      }
    },
    [onBack, pageNumber],
  );

  const title = <h1 className="text-4xl text-center">{projectName}</h1>;

  const validationErrorList =
    currentPageValidationErrors.length > 0 ? (
      <ul className="mt-6 text-red">
        {currentPageValidationErrors.map(({ error }) => (
          <li key={error.message}>{error.message}</li>
        ))}
      </ul>
    ) : null;

  const banner = (
    <div className="absolute left-0 top-0 right-0 font-semibold bg-uo-yellow row justify-center items-center text-center py-2 rounded-lg rounded-b-none">
      PRA Builder
    </div>
  );

  const breadcrumbs = (
    <div className="mt-5 row justify-center">
      <Breadcrumbs
        count={PraBuilderModalPage.REVIEW + 1}
        index={pageNumber}
        onChange={setPageNumber}
      />
    </div>
  );

  const backButton = (
    <Button
      onClick={handleBack}
      backgroundColor="uo-green"
      textColor="white"
      className="w-14 absolute left-6 top-20"
    >
      <ArrowLeftIcon title="Back" />
    </Button>
  );

  if (pageNumber === PraBuilderModalPage.PROJECT_OVERVIEW_1) {
    return (
      <Modal
        {...rest}
        onClose={onClose}
        className="pt-20"
        closeButtonClassName="top-10"
      >
        {onBack ? backButton : null}
        {banner}
        <form
          key={pageNumber}
          className="col max-w-2xl w-screen"
          onSubmit={handleNext}
        >
          {title}
          {breadcrumbs}
          <h2 className="text-uo-green text-center font-bold mt-5">
            A. Overview of the Project (Project Scope) - part 1
          </h2>

          {validationErrorList}

          <h3 className="font-semibold mt-6">
            1. Department, Center or Institute
          </h3>
          <label className="mt-1">
            What UO center, institute or department is this project most closely
            affiliated with? If the answer is a department, please include the
            word “department” after the name, for instance, “Physics
            department.”
          </label>
          <TextInput
            placeholder="Enter Department, Center or Institute"
            className="mt-3"
            value={departmentName}
            onTextChange={setDepartmentName}
          />

          <h3 className="font-semibold mt-6">
            2. Preferred Acronym or Abbreviation
          </h3>
          <label className="mt-1">
            Please provide a 3 to 8 letter acronym or abbreviation (in caps) for
            this center, institute or department.
          </label>
          <TextInput
            placeholder="Enter Preferred Acronym or Abbreviation"
            className="mt-3"
            value={departmentAbbreviation}
            onTextChange={setDepartmentAbbreviation}
          />

          <h3 className="font-semibold mt-6">
            3. Mission of the Center, Institute or Department
          </h3>
          <label className="mt-1">
            Please think about (or look up) the mission of your center,
            institute or department, and complete the following sentence:“My
            center, institute or department aims to ___________.”
          </label>
          <TextInput
            placeholder="Enter Mission"
            className="mt-3"
            value={departmentMission}
            onTextChange={setDepartmentMission}
          />

          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="w-48 mt-10 self-center"
          >
            Next
          </Button>
        </form>
      </Modal>
    );
  }

  if (pageNumber === PraBuilderModalPage.PROJECT_OVERVIEW_2) {
    return (
      <Modal
        {...rest}
        onClose={onClose}
        className="pt-20"
        closeButtonClassName="top-10"
      >
        {backButton}
        {banner}
        {title}
        {breadcrumbs}
        <h2 className="text-uo-green text-center font-bold mt-5">
          A. Overview of the Project (Project Scope) - part 2
        </h2>
        <form
          key={pageNumber}
          className="col max-w-2xl w-screen"
          onSubmit={handleNext}
        >
          {validationErrorList}

          <h3 className="font-semibold mt-6">4. Goal of the Project</h3>
          <label className="mt-1">
            Please think about the goal of the Project and complete the
            following sentence: “The goal of the Project is to ________.”
          </label>
          <TextArea
            placeholder="Enter Goal of the Project"
            className="mt-3"
            value={projectGoal}
            onTextChange={setProjectGoal}
          />

          <h3 className="font-semibold mt-6">
            5. Expectations for Project Participant Contributions
          </h3>
          <label className="mt-1">
            Please complete this sentence by describing the role project
            participants will play in the project, and the specific works
            project participants will contribute to the project, being as
            precise and descriptive as possible: “Project participants will
            contribute to the project by __________________,
          </label>
          <TextArea
            placeholder="Project participants will contribute to the project by:"
            className="mt-3"
            value={participantContributionActions}
            onTextChange={setParticipantContributionActions}
          />

          <label className="mt-3">
            and through this participation it is likely the following content
            and/or items will be contributed to the project: _____________”.
          </label>
          <TextArea
            placeholder="the following content and/or items will be contributed to the project: "
            className="mt-3"
            value={participantContributionTangible}
            onTextChange={setParticipantContributionTangible}
          />

          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="w-48 mt-10 self-center"
          >
            Next
          </Button>
        </form>
      </Modal>
    );
  }

  if (pageNumber === PraBuilderModalPage.PARTICIPANTS) {
    return (
      <Modal
        {...rest}
        onClose={onClose}
        className="pt-20"
        closeButtonClassName="top-10"
      >
        {backButton}
        {banner}
        {title}
        {breadcrumbs}
        <h2 className="text-uo-green text-center font-bold mt-5">
          B. Who Will Be Participating
        </h2>
        <form
          key={pageNumber}
          className="col max-w-2xl w-screen"
          onSubmit={handleNext}
        >
          {validationErrorList}

          <h3 className="font-semibold mt-6">
            6. Non-UO Project Participants?
          </h3>
          <label className="mt-1">
            Will project participants from outside the University of Oregon be
            included in this project?
          </label>
          <Select
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={
              nonUoParticipants === null ? null : nonUoParticipants.toString()
            }
            onValueChange={(v) => setNonUoParticipants(v === 'true')}
            className="mt-3"
          />

          <h3 className="font-semibold mt-6">7. Student participants?</h3>
          <label className="mt-1">
            Will UO students and/or students from any other institute(s) be
            participating in this project?
          </label>
          <Select
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={
              studentParticipants === null
                ? null
                : studentParticipants.toString()
            }
            onValueChange={(v) => setStudentParticipants(v === 'true')}
            className="mt-3"
          />

          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="w-48 mt-10 self-center"
          >
            Next
          </Button>
        </form>
      </Modal>
    );
  }

  if (pageNumber === PraBuilderModalPage.REVENUE_DISTRIBUTION) {
    return (
      <Modal
        {...rest}
        onClose={onClose}
        className="pt-20"
        closeButtonClassName="top-10"
      >
        {backButton}
        {banner}
        {title}
        {breadcrumbs}
        <h2 className="text-uo-green text-center font-bold mt-5">
          C. What to Exclude (no questions)
          <br />
          D. Revenue Distribution
        </h2>
        <form
          key={pageNumber}
          className="col max-w-2xl w-screen"
          onSubmit={handleNext}
        >
          {validationErrorList}

          <h3 className="font-semibold mt-6">8. Revenue Distribution</h3>
          <label className="mt-1">
            UO has sole administrative control over revenue or other benefit
            that may arise in association with or as a result of licensing or
            other disposition of property created pursuant to UO projects. If
            revenue is generated, you seek to:
          </label>

          <div className="pr-24 mt-10">
            <RadioListInput
              groupId="revenue-distribution"
              value={revenueDistributionPolicy}
              onChange={(v) =>
                setRevenueDistributionPolicy(v as PraRevenuePolicy)
              }
              options={revenuePolicyOptions}
            />
          </div>

          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="w-48 mt-32 self-center"
          >
            Next
          </Button>
        </form>
      </Modal>
    );
  }

  if (pageNumber === PraBuilderModalPage.USE_OF_WORK) {
    return (
      <Modal
        {...rest}
        onClose={onClose}
        className="pt-20"
        closeButtonClassName="top-10"
      >
        {backButton}
        {banner}
        {title}
        {breadcrumbs}
        <h2 className="text-uo-green text-center font-bold mt-5">
          E. Use of Project Work
        </h2>
        <form
          key={pageNumber}
          className="col max-w-2xl w-screen"
          onSubmit={handleNext}
        >
          {validationErrorList}

          <h3 className="font-semibold mt-6">9. Confidentiality</h3>
          <label className="mt-1">
            Is there a contractual obligation to an industry research sponsor to
            maintain the confidentiality of information that will be shared, or
            outputs that will be delivered, pursuant to this project?
          </label>
          <Select
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={confidential === null ? null : confidential.toString()}
            onValueChange={(v) => setConfidential(v === 'true')}
            className="mt-3"
          />

          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="w-48 mt-44 self-center"
          >
            Next
          </Button>
        </form>
      </Modal>
    );
  }

  if (pageNumber === PraBuilderModalPage.REVIEW) {
    return (
      <Modal
        {...rest}
        onClose={onClose}
        className="pt-20"
        closeButtonClassName="top-10"
      >
        {backButton}
        {banner}
        {title}
        {breadcrumbs}
        <h2 className="text-uo-green text-center font-bold mt-5">Review</h2>
        <form
          key={pageNumber}
          className="col max-w-2xl w-screen"
          onSubmit={handleSubmit}
        >
          {validationErrorList}

          {submitError && (
            <p className="my-4 text-red">{submitError.message}</p>
          )}

          <div className="flex-shrink-0 h-100 overflow-y-auto bg-light-gray text-sm p-3 mt-5">
            <PraText
              projectRulesAgreement={{
                praBuilderOptions,
                description: praMarkdown,
              }}
              className="mt-5"
            />
          </div>

          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="w-48 mt-10 self-center"
          >
            {submitButtonText}
          </Button>
        </form>
        {submitting && <SpinnerOverlay className="rounded-lg" />}
      </Modal>
    );
  }

  return null; // Shouldn't be possible
};

function renderRulesAgreementMarkdown(
  value: PraBuilderOptions,
  projectName: string,
): string {
  // Bold variables and use placeholders when blank strings are passed.
  projectName = `**${escapeMarkdown(projectName || '#project')}**`;
  const departmentName = `**${escapeMarkdown(
    value.departmentName || '#department',
  )}**`;
  const departmentAbbreviation = `**${escapeMarkdown(
    value.departmentAbbreviation || '#dept',
  )}**`;
  const departmentMission = `**${escapeMarkdown(
    value.departmentMission || '#dept_mission',
  )}**`;
  const projectGoal = `**${escapeMarkdown(
    value.projectGoal || '#project_goal',
  )}**`;
  const participantContributionActions = `**${escapeMarkdown(
    value.participantContributionActions ||
      '#participant_contributions_actions',
  )}**`;
  const participantContributionTangible = `**${escapeMarkdown(
    value.participantContributionTangible ||
      '#participant_contributions_tangible',
  )}**`;

  return [
    '## Overview of the Project',
    `The University of Oregon (“UO”) is the home of the ${departmentName} (“${departmentAbbreviation}”) and the ${projectName}. ${departmentAbbreviation} aims to ${departmentMission}. The goal of the project is to ${projectGoal}. Project participants will contribute to the project by ${participantContributionActions}, and through this participation it is likely the following content and/or items with be 1 contributed to the project (this list is not intended to be exhaustive of all items a participant may contribute): ${participantContributionTangible}.`,
    `\n\n${departmentAbbreviation} has certain responsibilities with respect to disseminating and licensing of intellectual property created in Project that are important to manage. Project has designated a principal investigator(s) responsible for supervising the research and content creation (“Project Supervisor”). As a condition of participation in Project, you agree to cooperate with UO through the Project Supervisor in fulfilling its obligations pertaining to work in Project that you participate in. You agree to follow all UO policies and procedures as directed by the Project Supervisor when participating in Project activities.`,

    `## Ownership of Project Work – UO Employees`,
    `If you are a UO employee, your works created or developed in Project are works made for hire and owned by the UO, and you hereby assign ownershipof these works, and any patents, copyrights, or other rights, to the UO. You agree to execute confirmatory assignments as requested by the UO. You agree to disclose promptly to UO any and all Project works that you develop or that you become aware of in working with Project. For works and rights you own personally, but which you voluntarily provide to, or include in, work for the Project, you hereby grant to UO a paid-up, royalty-free, non-exclusive,worldwide right to use such works and rights, including but not limited to creation of derivative works, reproduction of such work and derivative works in copies, distribution of such work and derivative works, public display and performance of such work and derivative works, and sale and licensing of such work and derivative works to others as part of Project-related materials.`,

    ...(value.nonUoParticipants
      ? [
          '## Ownership of Project Work – Non-UO Employees',
          `If you are not a UO employee, in consideration of your participation in the Project and the opportunity to work on cutting edge research with UO and community professionals, you agree to disclose promptly to UO any and all Project works that you create or develop, or that you become aware of in working with Project. You hereby assign ownership of these works, and any patents, copyrights, or other rights, to the UO in consideration of your inclusion in the inventor/author pool revenue sharing available under UO’s IP 2 Policy. You agree to execute confirmatory assignments as requested by the UO. You agree to disclose promptly to UO any and all Project works that you develop or that you become aware of in working with Project. For works and rights you own personally, but which you voluntarily provide to, or include in,work for the Project, you hereby grant to UO a paid-up, royalty-free, non-exclusive, worldwide right to use such works and rights, including but not limited to creation of derivative works, reproduction of such work and derivative works in copies, distribution of such work and derivative works, public display and performance of such work and derivative works, and sale and licensing of such work and derivative works to others as part of Project-related materials.`,
        ]
      : []),

    value.studentParticipants
      ? `If you are an enrolled student at the UO, information and works you contribute to Project may be considered educational records under the Family Educational Rights and Privacy Act (“FERPA”), 20 USC §1232g, and itsimplementing regulations, 34 CFR part 99. Educational records are protectedfrom disclosure by UO policy and FERPA. By participating in Project and agreeing to these Project Rules, you hereby permit the UO’s research collaborators and the UO to publicly identify you as a member of the Project, identify you as an author of the works you contribute to Project, and you waive any right to privacy that may be afforded you under FERPA with respect to Project. You also agree to release the information and works you contribute to Project to the UO’s research collaborators, Project financial sponsors, and the UO. You agree that these permissions are voluntarily givenand that you have had an opportunity to talk with your Project Supervisor and any other independent advisors regarding this permission.`
      : null,

    `## What to Exclude without Permission from Project Supervisor\nIn your works for Project, you agree not to include code, pictures, content, ideas, or other material that you think might be subject to patent, copyright, trademark, rights of publicity or trade secret claims owned by others (whether or not the owner is associated with the UO) unless you have approval from the Project Supervisor.`,
    value.revenueDistributionPolicy
      ? getRevenuePolicyParagraphs(
          value.revenueDistributionPolicy,
          departmentAbbreviation,
        )
      : null,
    value.confidential
      ? `## Confidentiality\nUO has contractual obligations to maintain the confidentiality of information shared by project participants and delivered to stakeholders. The results, outputs and deliverables created by this Project also have confidentiality obligations. You agree to obtain approval from the Project Supervisor prior todistributing or publishing any work under this Project in order to meet UO’s contractual obligations and the long term goals of the ${departmentAbbreviation}.`
      : `## Use of Project Work\nUO encourages publication of innovative works by students, staff and faculty. However, UO through the Project may also have contractual and other obligations that constrain the manner and timing of publications and works relating to Project. You agree to obtain approval from the Project Supervisor prior to distributing or publishing Project works in order to meet UO’s contractual obligations and the long-term goals of ${departmentAbbreviation}. ${departmentAbbreviation} encourages you to use the parts of the Project you worked on in collaboration with other participants, outside of Project. Contact the Project Supervisor to clearly define the parts of Project available for your use outsideof Project to help the ${departmentAbbreviation} manage everyone’s expectations.`,

    `## No Conflicts\nYou represent that you have no agreements with or obligations to others in conflict with the foregoing, including but not limited to: participation under other UO Projects, sponsored research agreements, or technology licenses with terms and conditions incompatible with your participation in the Project; employment or post-employment agreements with present and prior employers other than UO; consulting contracts; service provided as a member of a technical or advisory board to a third party; and any other external commitments.`,

    `\n\n**If these Project Rules are acceptable to you, please type your name and click the "Sign" button below to indicate that you have read these Project Rules, understand them (and have had the opportunity to discuss them-please contact Innovation Partnership Services at techtran@uoregon.edu or call 541-346-3176 with any questions or concerns) and that you agree to comply with these Project Rules as a condition of participating in this Project.**`,
  ]
    .filter(isString)
    .join('\n');
}

function getRevenuePolicyParagraphs(
  revenuePolicy: PraRevenuePolicy,
  departmentAbbreviation: string,
): string {
  switch (revenuePolicy) {
    case PraRevenuePolicy.WAIVE_SHARE:
      return `## Revenue\nYou acknowledge and agree that UO has sole administrative control over revenue or other benefit that may arise in association with or as a result of licensing or other disposition of Project works. You understand and agree that you will receive no personal share of any revenue derived from your contributions to the Project and irrevocably waive any personal royalty sharein favor of the ${departmentAbbreviation}. ${departmentAbbreviation} uses these funds to support its education and research projects.`;

    case PraRevenuePolicy.EQUAL_SHARE:
      return `## Revenue\nYou understand that under UO policies certain inventors and authors (that have assigned intellectual property rights to UO) are eligible to share in royalties obtained from licensing opportunities associated with intellectual property to which they have contributed (“Author Shares”). You hereby agree that all signatories to this project rules agreement, including the Project Supervisor(s), shall share equally in any Author Shares. You acknowledge and agree that UO has sole administrative controlover revenue or other benefit that may arise in association with or as a resultof licensing or other disposition of Projects works. You acknowledge that UO has no obligation to maximize your share of revenue or other benefit, if any, made available for sharing with inventors or authors, to seek recovery of revenue or other benefit in the event of default of any third party, or otherwise to act responsive to your personal interest in any such revenue or other benefit. You have no claim, and shall assert no claim, to revenue or other benefit due or received or designated by UO as research or other non-royalty funding. You agree the UO may establish a cost recovery budget drawn from some or all of the available licensing revenue at the request of the Project Supervisor.`;

    case PraRevenuePolicy.STANDARD_RSA:
      return `## Revenue\nYou understand that under UO policies certain inventors and authors (that have assigned intellectual property rights to UO) are eligible to share in royalties obtained from licensing opportunities associated with intellectual property to which they have contributed. You acknowledge and agree that UO has sole administrative control over revenue or other benefit that may arise in association with or as a result of licensing or other disposition of Projects works. You acknowledge that UO has no obligation to maximize yourshare of revenue or other benefit, if any, made available for sharing with inventors or authors, to seek recovery of revenue or other benefit in the event of default of any third party, or otherwise to act responsive to your personal interest in any such revenue or other benefit. You have no claim, and shall assert no claim, to revenue or other benefit due or received or designated by UO as research or other non-royalty funding. You agree the UO may establish a cost recovery budget drawn from some or all of the available licensing revenue at the request of the Project Supervisor.`;
  }
}
