import useAsyncEffect from '../../../utils/react/useAsyncEffect';
import React, { FC, useCallback, useState } from 'react';
import { useUserList } from '../../../contexts/UserListContext';
import { UserModal, UserModalValue } from './UserModal';

export interface EditUserModalProps {
  userId: number;
  onClose(): unknown;
}

export const EditUserModal: FC<EditUserModalProps> = ({
  userId,
  onClose,
  ...rest
}) => {
  const { getUserById, updateUser } = useUserList();
  const [initialValue, setInitialValue] = useState<
    UserModalValue | undefined
  >();

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedUser = await getUserById({ userId });
      if (!isCancelled()) {
        setInitialValue({
          name: fetchedUser.name,
          email: fetchedUser.email,
          roleId: fetchedUser.roleId,
          affiliation: fetchedUser.affiliation,
        });
      }
    },
    [userId],
  );

  const handleSubmit = useCallback(
    async (value: UserModalValue) => {
      await updateUser({ ...value, userId });
      onClose();
    },
    [onClose, updateUser, userId],
  );

  return (
    <UserModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit User"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
