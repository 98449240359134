import React, { FC, ComponentPropsWithoutRef } from 'react';

interface SwitchProps
  extends Pick<
    ComponentPropsWithoutRef<'input'>,
    'value' | 'checked' | 'name' | 'alt' | 'autoComplete' | 'placeholder'
  > {
  className?: string;
  onChange(checked: boolean): unknown;
}

export const Switch: FC<SwitchProps> = ({
  value,
  checked,
  onChange,
  placeholder,
  name,
  alt,
  autoComplete,
  className,
  ...rest
}) => {
  return (
    <div {...rest} className={className}>
      <input
        className="hidden"
        type="checkbox"
        onChange={(event) => {
          onChange(event.currentTarget.checked);
        }}
        checked={checked}
        name={name}
        alt={alt}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
      <div
        className={` w-12 h-6 rounded-xl flex-shrink-0 relative cursor-pointer transition-all duration-200 row ${
          checked
            ? 'pl-6 bg-uo-green text-uo-green'
            : 'bg-gray-400 text-gray-400'
        }`}
        role="switch"
        aria-checked={checked}
        tabIndex={0}
      >
        <div
          className={`rounded-full w-6 h-6 bg-white border-current border-2 box-border`}
        />
      </div>
    </div>
  );
};
