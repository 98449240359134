import React, { FC, useCallback } from 'react';
import { ProgramModal, ProgramModalValue } from './ProgramModal';
import { useProgramList } from '../../../contexts/ProgramListContext';

export interface CreateProgramModalProps {
  onClose(): unknown;
}

export const CreateProgramModal: FC<CreateProgramModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createProgram } = useProgramList();

  const handleSubmit = useCallback(
    async (value: ProgramModalValue) => {
      await createProgram(value);
      onClose();
    },
    [createProgram, onClose],
  );

  return (
    <ProgramModal
      {...rest}
      title="Add Program"
      submitButtonText="Add Program"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
