import React, { FC } from 'react';
import classnames from 'classnames';

export interface UserCircleProps {
  color: 'lime-green' | 'uo-green';
  className?: string;
}

export const UserCircle: FC<UserCircleProps> = ({
  color,
  className,
  ...rest
}) => (
  <div
    {...rest}
    className={classnames(
      'h-8 w-8 col flex-shrink-0 rounded-full text-2xs text-white border-white border-2 items-center justify-center',
      {
        'bg-lime-green': color === 'lime-green',
        'bg-uo-green': color === 'uo-green',
      },
      className,
    )}
  />
);

export function formatUserCircleName(name: string): string {
  const [firstPart, ...rest] = name
    .trim()
    .split(/\s/g)
    .filter((part) => part.length > 0);

  if (!firstPart) {
    return '';
  } else if (!rest.length) {
    return firstPart[0].toUpperCase();
  } else {
    const lastPart = rest[rest.length - 1];
    return firstPart[0].toUpperCase() + lastPart[0].toUpperCase();
  }
}
