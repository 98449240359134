import { FC, FormEvent, useCallback, useState } from 'react';
import useInputState from '../../../utils/react/useInputState';
import { isBlank } from 'shared/lib/utils/isBlank';
import { Button } from '../../Button/Button';
import { TextInput } from '../../TextInput/TextInput';
import { Select } from '../../Select/Select';
import { useUserRoleList } from '../../../contexts/UserRoleListContext';
import { Modal } from '../../Modal/Modal';
import { useProjectMemberRoleList } from '../../../contexts/ProjectMemberRoleListContext';
import { useApi } from '../../../contexts/ApiContext';
import { useProject } from '../../../contexts/ProjectContext';
import { getErrorMessage } from '../../../utils/getErrorMessage';

export interface AddProjectMemberModalValue {
  userId: number | null;
  userRoleId: number;
  projectMemberRoleId: number | null;
  name: string;
  email: string;
  affiliation: string;
}

export interface PendingAddProjectMemberModalValue
  extends Omit<AddProjectMemberModalValue, 'userRoleId'> {
  userRoleId: number | null;
}

export interface AddProjectMemberModalProps {
  className?: string;
  onClose(): unknown;
}

export const AddProjectMemberModal: FC<AddProjectMemberModalProps> = ({
  className,
  onClose,
  ...rest
}) => {
  const { findUserByEmail } = useApi();
  const { createProjectMember } = useProject();
  const { selectableUserRoles } = useUserRoleList();
  const { projectMemberRoles } = useProjectMemberRoleList();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [email, handleEmailChange, setEmail] = useInputState();
  const [addNew, setAddNew] = useState(false);
  const [projectMemberRoleId, setProjectMemberRoleId] = useState<number | null>(
    null,
  );
  const [
    pendingMember,
    setPendingMember,
  ] = useState<PendingAddProjectMemberModalValue | null>(null);
  const pendingUserRole =
    pendingMember && pendingMember.userRoleId
      ? selectableUserRoles.find((role) => role.id === pendingMember.userRoleId)
      : null;
  const requireAffiliation = pendingUserRole?.requireAffiliation ?? false;

  const handleFindSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (addNew) {
        setPendingMember({
          userId: null,
          projectMemberRoleId,
          userRoleId: null,
          name: '',
          affiliation: '',
          email,
        });
        setAddNew(false);
        setEmail('');
      } else {
        const foundUser = await findUserByEmail({ email });

        if (foundUser && projectMemberRoleId) {
          try {
            setSubmitting(true);
            await createProjectMember({
              userId: foundUser.id,
              projectMemberRoleId,
              userRoleId: foundUser.roleId,
              name: foundUser.name,
              email: foundUser.email,
              affiliation: foundUser.affiliation ?? '',
            });
            onClose();
          } catch (error) {
            setSubmitError(error);
            setSubmitting(false);
          }
        } else {
          setAddNew(true);
        }
      }
    },
    [
      findUserByEmail,
      setEmail,
      createProjectMember,
      onClose,
      projectMemberRoleId,
      email,
      addNew,
    ],
  );

  const handleCreateSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!pendingMember) {
        return;
      }

      const { userRoleId } = pendingMember;
      if (userRoleId && projectMemberRoleId) {
        await createProjectMember({
          userId: null,
          userRoleId,
          projectMemberRoleId,
          name: pendingMember.name,
          email: pendingMember.email,
          affiliation: pendingMember.affiliation,
        });
        onClose();
      }
      setPendingMember(null);
    },
    [onClose, createProjectMember, pendingMember, projectMemberRoleId],
  );

  return (
    <Modal
      {...rest}
      onClose={onClose}
      className={`col w-168 lg:w-200 ${className}`}
      showSpinner={submitting}
      spinnerMessage="Adding member"
    >
      <h1 className="text-4xl text-center font-normal">Add Project Member</h1>
      <form className="row mt-16" onSubmit={handleFindSubmit}>
        <TextInput
          className="flex-grow mr-6"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        <Select
          className="flex-shrink-0 w-48 mr-6"
          value={projectMemberRoleId}
          onValueChange={(value) => {
            setProjectMemberRoleId(value ? +value : null);
          }}
          options={projectMemberRoles.map((role) => ({
            value: role.id.toString(),
            label: role.name,
          }))}
        />
        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="h-12 w-48 py-0"
          disabled={!/\S+@\S+/.test(email) || !projectMemberRoleId}
        >
          {addNew ? 'Add New User' : 'Add Existing User'}
        </Button>
      </form>
      {submitError && (
        <p className="my-4 text-red text-center text-lg">
          {getErrorMessage(submitError)}
        </p>
      )}
      {addNew && (
        <p className="text-berry text-xs mb-5">
          Email not recognized. Press “Add New User” to create new user.
        </p>
      )}
      {pendingMember && (
        <form onSubmit={handleCreateSubmit}>
          <div className="row mt-6">
            <TextInput
              value={pendingMember.name}
              placeholder="Name"
              onChange={(event) =>
                setPendingMember({
                  ...pendingMember,
                  name: event.currentTarget.value,
                })
              }
              className="w-0 flex-grow mr-6"
            />
            <TextInput
              value={pendingMember.email}
              placeholder="Email"
              onChange={(event) =>
                setPendingMember({
                  ...pendingMember,
                  email: event.currentTarget.value,
                })
              }
              className="w-0 flex-grow mr-6"
            />
            <Select
              className="flex-shrink-0 w-48 mr-6"
              value={pendingMember?.userRoleId}
              onValueChange={(value) =>
                setPendingMember({
                  ...pendingMember,
                  userRoleId: value ? +value : null,
                })
              }
              options={selectableUserRoles.map((role) => ({
                value: role.id.toString(),
                label: role.name,
              }))}
            />
            {requireAffiliation && (
              <TextInput
                placeholder="Affiliation"
                className="w-0 flex-grow mr-6"
                value={pendingMember.affiliation}
                onTextChange={(affiliation) =>
                  setPendingMember({ ...pendingMember, affiliation })
                }
              />
            )}
          </div>
          <div className="row mt-6 justify-center">
            <Button
              backgroundColor="uo-green"
              textColor="white"
              className="w-48 order-2"
              disabled={
                isBlank(pendingMember.name) ||
                isBlank(pendingMember.email) ||
                !pendingMember.userRoleId ||
                (requireAffiliation && isBlank(pendingMember.affiliation))
              }
            >
              Add New User
            </Button>
            <Button
              backgroundColor="red"
              textColor="white"
              className="w-48 mr-6 order-1"
              onClick={(event) => {
                event.stopPropagation();
                setPendingMember(null);
              }}
            >
              Cancel New User
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
