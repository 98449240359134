import { FC } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { ReactComponent as ClipboardIcon } from '../../images/icons/clipboard.svg';
import { ProjectRulesAgreement } from 'shared/lib/types/ProjectRulesAgreement';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { showConfirm } from '../ConfirmModal/showConfirm';
import { Flyout } from '../Flyout/Flyout';
import useToggleState from '../../utils/react/useToggleState';
import { Link } from 'react-router-dom';
import { useProjectRulesAgreementList } from '../../contexts/ProjectRulesAgreementListContext';

export interface ProjectRulesAgreementTableRowProps {
  projectRulesAgreement: ProjectRulesAgreement;
  className?: string;
}

export const ProjectRulesAgreementTableRow: FC<ProjectRulesAgreementTableRowProps> = ({
  projectRulesAgreement,
  className = '',
  ...rest
}) => {
  const { deleteProjectRulesAgreement } = useProjectRulesAgreementList();
  const [showMenu, toggleMenu] = useToggleState();

  return (
    <li {...rest} className={`row items-center py-8 ${className}`}>
      <div className="w-0 flex-grow-3 mr-4 font-semibold">
        {projectRulesAgreement.name}
      </div>
      <div className="w-0 flex-grow mr-4 row justify-center">
        <Link
          to={`/admin/projectRulesAgreement/${projectRulesAgreement.id}/view`}
        >
          <Button backgroundColor="none" textColor="black" size="small">
            <ClipboardIcon />
          </Button>
        </Link>
      </div>
      <div className="w-0 flex-grow mr-4 text-center">
        {projectRulesAgreement.public ? 'Public' : 'Private'}
      </div>
      <div className="w-0 flex-grow mr-4 text-center text-3xl">
        {projectRulesAgreement.assignedProjectCount}
      </div>
      <div className="w-0 flex-grow row justify-end">
        {projectRulesAgreement.assignedProjectCount === 0 && (
          <div className="relative">
            <Button
              backgroundColor="none"
              textColor="uo-green"
              size="small"
              onClick={toggleMenu}
            >
              <MoreIcon />
            </Button>
            {showMenu && (
              <Flyout
                arrowSide="top-right"
                color="uo-green"
                borderRadius="xl"
                className="w-52 top-0 right-0 mt-12 -mr-2"
                onClickOutside={toggleMenu}
              >
                <Link
                  to={`/admin/projectRulesAgreement/${projectRulesAgreement.id}/edit`}
                >
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    size="small"
                    className="row items-center px-0 mb-3"
                  >
                    <EditIcon className="mr-3" /> Edit PRA
                  </Button>
                </Link>
                <Button
                  backgroundColor="none"
                  textColor="white"
                  size="small"
                  className="row items-center px-0"
                  onClick={async () => {
                    if (
                      await showConfirm({
                        title: 'Delete PRA',
                        message: `You are about to delete PRA “${projectRulesAgreement.name}.” Are you sure you wish to delete this PRA?`,
                        confirmButtonText: 'Delete',
                      })
                    ) {
                      await deleteProjectRulesAgreement({
                        projectRulesAgreementId: projectRulesAgreement.id,
                      });
                    }
                  }}
                >
                  <DeleteIcon className="mr-3" /> Delete PRA
                </Button>
              </Flyout>
            )}
          </div>
        )}
      </div>
    </li>
  );
};
