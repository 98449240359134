import React, { FC } from 'react';
import { AssetCategoryListProvider } from './AssetCategoryListContext';
import { CollegeListProvider } from './CollegeListContext';
import { DepartmentListProvider } from './DepartmentListContext';
import { ProgramListProvider } from './ProgramListContext';
import { ProjectMemberRoleListProvider } from './ProjectMemberRoleListContext';
import { ProjectRulesAgreementListProvider } from './ProjectRulesAgreementListContext';
import { StaffManagerListProvider } from './StaffManagerContext';
import { UserRoleListProvider } from './UserRoleListContext';

/**
 * Provides data needed for various forms throughout the app.
 * Includes:
 *  - Asset categories
 *  - User roles
 *  - Project member roles
 *  - Staff manager list
 *  - Project rules agreement list
 *  - College/Department/Program lists
 */
export const GlobalDataProvider: FC = ({ children }) => (
  <AssetCategoryListProvider>
    <UserRoleListProvider>
      <ProjectMemberRoleListProvider>
        <ProjectRulesAgreementListProvider>
          <StaffManagerListProvider>
            <CollegeListProvider>
              <DepartmentListProvider>
                <ProgramListProvider>
                  <ProjectRulesAgreementListProvider>
                    {children}
                  </ProjectRulesAgreementListProvider>
                </ProgramListProvider>
              </DepartmentListProvider>
            </CollegeListProvider>
          </StaffManagerListProvider>
        </ProjectRulesAgreementListProvider>
      </ProjectMemberRoleListProvider>
    </UserRoleListProvider>
  </AssetCategoryListProvider>
);
