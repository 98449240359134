import useToggleState from '../../utils/react/useToggleState';
import React, { FC, useCallback } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import { AdminToolsNav } from './AdminToolsNav';
import { Button } from '../Button/Button';
import { CollegeList } from '../CollegeList/CollegeList';
import { DepartmentList } from '../DepartmentList/DepartmentList';
import { Flyout } from '../Flyout/Flyout';
import { CreateCollegeModal } from '../modals/collegeModals/CreateCollegeModal';
import { EditCollegeModal } from '../modals/collegeModals/EditCollegeModal';
import { CreateDepartmentModal } from '../modals/departmentModals/CreateDepartmentModal';
import { EditDepartmentModal } from '../modals/departmentModals/EditDepartmentModal';
import { CreateProgramModal } from '../modals/programModals/CreateProgramModal';
import { EditProgramModal } from '../modals/programModals/EditProgramModal';
import { Nav } from '../Nav/Nav';
import { ProgramList } from '../ProgramList/ProgramList';

export const ManageCollegesPage: FC = (props) => {
  const history = useHistory();
  const [showingAddMenu, toggleAddMenu] = useToggleState();

  const closeModals = useCallback(() => {
    history.push('/admin/colleges');
  }, [history]);

  return (
    <div {...props} className="min-h-screen pt-16 pb-48 px-8 lg:px-20">
      <div className="container mx-auto max-w-full">
        <Nav tab="admin tools" />

        {/* Heading */}
        <div className="col md:flex-row md:items-center md:justify-between">
          <h1 className="text-6xl">Admin Tools</h1>
          <div className="relative">
            <Button
              className="w-48"
              backgroundColor="uo-green"
              textColor="white"
              size="large"
              onClick={toggleAddMenu}
            >
              Add College Info
            </Button>
            {showingAddMenu && (
              <Flyout
                arrowSide="top"
                color="uo-green"
                borderRadius="xl"
                className="w-full top-0 left-0 mt-22"
                onClickOutside={toggleAddMenu}
              >
                <Link to="/admin/colleges/add">
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    className="row items-center px-0"
                  >
                    Add College
                  </Button>
                </Link>
                <Link to="/admin/departments/add">
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    className="row items-center px-0"
                  >
                    Add Department
                  </Button>
                </Link>
                <Link to="/admin/programs/add">
                  <Button
                    backgroundColor="none"
                    textColor="white"
                    className="row items-center px-0"
                  >
                    Add Program
                  </Button>
                </Link>
              </Flyout>
            )}
          </div>
        </div>

        <AdminToolsNav tab="college info" className="mt-8" />
        <div className="flex flex-col md:flex-row mt-12">
          <div className="col md:w-0 md:flex-grow md:mr-6">
            <h2 className="text-xl font-bold border-b-2 border-black pb-1 w-full">
              College
            </h2>
            <CollegeList className="w-full mt-3" />
          </div>
          <div className="col mt-8 md:mt-0 md:w-0 md:flex-grow md:mr-6">
            <h2 className="text-xl font-bold border-b-2 border-black pb-1 w-full">
              Department
            </h2>
            <DepartmentList className="w-full mt-3" />
          </div>
          <div className="col mt-8 md:mt-0 md:w-0 md:flex-grow">
            <h2 className="text-xl font-bold border-b-2 border-black pb-1 w-full">
              Program
            </h2>
            <ProgramList className="w-full mt-3" />
          </div>
        </div>
      </div>

      {/* College modals */}
      <Route path="/admin/colleges/add">
        <CreateCollegeModal onClose={closeModals} />
      </Route>

      <Route path="/admin/college/:collegeId/edit">
        {({ match }) =>
          match && (
            <EditCollegeModal
              onClose={closeModals}
              collegeId={+match.params.collegeId}
            />
          )
        }
      </Route>

      {/* Department modals */}
      <Route path="/admin/departments/add">
        <CreateDepartmentModal onClose={closeModals} />
      </Route>

      <Route path="/admin/department/:departmentId/edit">
        {({ match }) =>
          match && (
            <EditDepartmentModal
              onClose={closeModals}
              departmentId={+match.params.departmentId}
            />
          )
        }
      </Route>

      {/* Program modals */}
      <Route path="/admin/programs/add">
        <CreateProgramModal onClose={closeModals} />
      </Route>

      <Route path="/admin/program/:programId/edit">
        {({ match }) =>
          match && (
            <EditProgramModal
              onClose={closeModals}
              programId={+match.params.programId}
            />
          )
        }
      </Route>
    </div>
  );
};
