import { FC, FormEvent, useState, useEffect, useCallback } from 'react';
import { areMembersLoaded } from 'shared/lib/utils/projectUtils';
import { useProject } from '../../../contexts/ProjectContext';
import { useProjectMemberRoleList } from '../../../contexts/ProjectMemberRoleListContext';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { Button } from '../../Button/Button';
import { Modal } from '../../Modal/Modal';
import { Select } from '../../Select/Select';

export interface EditProjectMemberModalProps {
  userId: number;
  onClose(): unknown;
  className?: string;
}

export const EditProjectMemberModal: FC<EditProjectMemberModalProps> = ({
  userId,
  className = '',
  onClose,
  ...rest
}) => {
  const { projectMemberRoles } = useProjectMemberRoleList();
  const { project, updateProjectMember } = useProject();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [projectMemberRoleId, setProjectMemberRoleId] = useState<number | null>(
    null,
  );
  const member = project?.members?.find((member) => member.userId === userId);

  useEffect(() => {
    if (member) {
      setProjectMemberRoleId(member.projectMemberRoleId);
    }
  }, [member]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        if (!projectMemberRoleId) {
          return;
        }
        setSubmitting(true);
        await updateProjectMember({ userId, projectMemberRoleId });
        onClose();
      } catch (error) {
        setSubmitError(error);
        setSubmitting(false);
      }
    },
    [updateProjectMember, onClose, projectMemberRoleId, userId],
  );

  if (project && !areMembersLoaded(project)) {
    return null;
  }

  return (
    <Modal
      {...rest}
      className={`col w-168 ${className}`}
      showSpinner={submitting || !project}
      onClose={onClose}
    >
      <h1 className="text-4xl text-center font-normal">Edit Project Member</h1>
      <form className="col mt-16" onSubmit={handleSubmit}>
        <label className="font-semibold">Project role</label>
        <Select
          className="mt-3"
          value={projectMemberRoleId}
          onValueChange={(value) =>
            setProjectMemberRoleId(value ? +value : null)
          }
          options={projectMemberRoles.map((memberRole) => ({
            value: memberRole.id.toString(),
            label: memberRole.name,
          }))}
        />

        {submitError && (
          <p className="mt-12 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}

        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="w-48 self-center mt-12"
          disabled={!projectMemberRoleId}
        >
          Save Member
        </Button>
      </form>
    </Modal>
  );
};
