import { FC, FormEvent, useState, useCallback } from 'react';
import useAsyncEffect from '../../../utils/react/useAsyncEffect';
import { isBlank } from 'shared/lib/utils/isBlank';
import { ProjectRulesAgreement } from 'shared/lib/types/ProjectRulesAgreement';
import { Button } from '../../Button/Button';
import { Modal } from '../../Modal/Modal';
import { TextInput } from '../../TextInput/TextInput';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { useNotifications } from '../../../contexts/NotificationContext';
import { useApi } from '../../../contexts/ApiContext';
import { NotificationKind } from 'shared/lib/types/Notification';
import { PraText } from '../../PraText/PraText';

export interface SignProjectRulesAgreementModalProps {
  projectId: number;
  projectRulesAgreementId: number;
  onCancel(): unknown;
  onSubmit(signature: string): unknown;
}

export const SignProjectRulesAgreementModal: FC<SignProjectRulesAgreementModalProps> = ({
  projectId,
  projectRulesAgreementId,
  onCancel,
  onSubmit,
  ...rest
}) => {
  const api = useApi();
  const { notifications, dismissNotification } = useNotifications();
  const [loading, setLoading] = useState(true);
  const [
    projectRulesAgreement,
    setProjectRulesAgreement,
  ] = useState<ProjectRulesAgreement | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [signature, setSignature] = useState('');

  useAsyncEffect(
    async (isCancelled) => {
      const pra = await api.getProjectRulesAgreementById({
        projectRulesAgreementId,
      });
      if (!isCancelled()) {
        setProjectRulesAgreement(pra);
        setLoading(false);
      }
    },
    [api, projectRulesAgreementId],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      try {
        setSubmitting(true);
        await onSubmit(signature);

        // Dismiss the notification for this PRA if it exists
        const notification = notifications.find(
          (n) =>
            n.kind === NotificationKind.PRA_SIGNATURE_REQUEST &&
            n.projectId === projectId &&
            n.projectRulesAgreementId === projectRulesAgreementId,
        );
        if (notification) {
          dismissNotification(notification.id);
        }
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [
      onSubmit,
      dismissNotification,
      signature,
      notifications,
      projectId,
      projectRulesAgreementId,
    ],
  );

  return (
    <Modal
      {...rest}
      onClose={onCancel}
      showSpinner={submitting || loading}
      fadeIn={false}
      spinnerMessage={submitting ? 'Signing' : undefined}
      roundedSize="6xl"
      className="w-168"
    >
      <form className="col" onSubmit={handleSubmit}>
        <h1 className="text-4xl text-center">PRA</h1>
        {projectRulesAgreement && (
          <PraText
            projectRulesAgreement={projectRulesAgreement}
            className="mt-5"
          />
        )}
        <label className="font-semibold mt-16 mb-3">User Signature</label>
        <TextInput
          placeholder="Signature Goes Here"
          value={signature}
          onTextChange={setSignature}
        />
        {submitError && (
          <p className="my-4 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}
        <Button
          backgroundColor="uo-green"
          textColor="white"
          className="self-center w-48 mt-16"
          disabled={isBlank(signature)}
        >
          Sign
        </Button>
      </form>
    </Modal>
  );
};
