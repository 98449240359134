import { FC } from 'react';
import Markdown from 'react-markdown';
import { ProjectRulesAgreement } from 'shared/lib/types/ProjectRulesAgreement';

export interface PraTextProps {}

export const PraText: FC<{
  className?: string;
  projectRulesAgreement: Pick<
    ProjectRulesAgreement,
    'description' | 'praBuilderOptions'
  >;
}> = ({ className = '', projectRulesAgreement, ...rest }) => {
  return (
    <div
      className={`flex-shrink-0 h-100 overflow-y-auto bg-light-gray text-sm p-3 ${className}`}
    >
      <Markdown
        {...rest}
        className={`pra-markdown ${
          projectRulesAgreement.praBuilderOptions ? 'pra-builder-markdown' : ''
        }`}
      >
        {projectRulesAgreement?.description ?? ''}
      </Markdown>
    </div>
  );
};
