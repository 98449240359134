import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { ProjectMemberRole } from 'shared/lib/types/ProjectMemberRole';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface ProjectMemberRoleListContextValue {
  loading: boolean;
  projectMemberRoles: ProjectMemberRole[];
}

const ProjectMemberRoleListContext = createContext<ProjectMemberRoleListContextValue | null>(
  null,
);

export const ProjectMemberRoleListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [projectMemberRoles, setProjectMemberRoles] = useState<
    ProjectMemberRoleListContextValue['projectMemberRoles']
  >([]);
  const sortedProjectMemberRoles = useMemo(
    () =>
      [...projectMemberRoles].sort((a, b) =>
        sortAlphabetically(a.name, b.name),
      ),
    [projectMemberRoles],
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedProjectMemberRoles = await api.listProjectMemberRoles();

      if (!isCancelled()) {
        setProjectMemberRoles(fetchedProjectMemberRoles);
        setLoading(false);
      }
    },
    [api],
  );

  const value = useMemo<ProjectMemberRoleListContextValue>(
    () => ({
      loading,
      projectMemberRoles: sortedProjectMemberRoles,
    }),
    [loading, sortedProjectMemberRoles],
  );

  return (
    <ProjectMemberRoleListContext.Provider value={value}>
      {children}
    </ProjectMemberRoleListContext.Provider>
  );
};

export function useProjectMemberRoleList(): ProjectMemberRoleListContextValue {
  return (
    useContext(ProjectMemberRoleListContext) ??
    missingReactContext(
      'ProjectMemberRoleListProvider',
      'useProjectMemberRoleList',
    )
  );
}
