import useAsyncEffect from '../../utils/react/useAsyncEffect';
import { FC } from 'react';
import { useNotifications } from '../../contexts/NotificationContext';
import { Flyout } from '../Flyout/Flyout';
import { SpinnerOverlay } from '../SpinnerOverlay/SpinnerOverlay';
import { NotificationListItem } from './NotificationListItem';

export const NotificationList: FC<{
  onClose(): unknown;
  className?: string;
}> = ({ onClose, children, className = '', ...rest }) => {
  const {
    loading,
    notifications,
    markNotificationsViewed,
    dismissNotification,
  } = useNotifications();

  /**
   * Immediately mark all notifications as viewed when the notification
   * list is opened.
   */
  useAsyncEffect(() => markNotificationsViewed(), []);

  if (loading) {
    return (
      <Flyout
        {...rest}
        className={`shadow-6xl ${className}`}
        color="white"
        arrowSide="top-right"
        onClickOutside={onClose}
      >
        <div className="w-80 h-40">
          <SpinnerOverlay />
        </div>
      </Flyout>
    );
  }

  if (notifications.length === 0) {
    return (
      <Flyout
        {...rest}
        className={`shadow-6xl ${className}`}
        color="white"
        arrowSide="top-right"
        onClickOutside={onClose}
      >
        <div className="w-80">
          <p className="py-8 text-gray-600 text-center">
            You have no notifications.
          </p>
        </div>
      </Flyout>
    );
  }

  return (
    <Flyout
      {...rest}
      className={`shadow-6xl ${className}`}
      color="white"
      arrowSide="top-right"
      onClickOutside={onClose}
    >
      <ul className="flex flex-col w-80">
        {notifications.map((notification, i) => (
          <NotificationListItem
            key={notification.id}
            index={i}
            notification={notification}
            onDismiss={() => dismissNotification(notification.id)}
          />
        ))}
      </ul>
    </Flyout>
  );
};
