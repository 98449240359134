import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { AssetCategory } from 'shared/lib/types/AssetCategory';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';

interface AssetCategoryListContextValue {
  loading: boolean;
  assetCategories: AssetCategory[];
}

const AssetCategoryListContext = createContext<AssetCategoryListContextValue | null>(
  null,
);

export const AssetCategoryListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [assetCategories, setAssetCategorys] = useState<
    AssetCategoryListContextValue['assetCategories']
  >([]);

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedAssetCategorys = await api.listAssetCategories();

      if (!isCancelled()) {
        setAssetCategorys(fetchedAssetCategorys);
        setLoading(false);
      }
    },
    [api],
  );

  const value = useMemo<AssetCategoryListContextValue>(
    () => ({
      loading,
      assetCategories,
    }),
    [loading, assetCategories],
  );

  return (
    <AssetCategoryListContext.Provider value={value}>
      {children}
    </AssetCategoryListContext.Provider>
  );
};

export function useAssetCategoryList(): AssetCategoryListContextValue {
  return (
    useContext(AssetCategoryListContext) ??
    missingReactContext('AssetCategoryListProvider', 'useAssetCategoryList')
  );
}
