import { FC, useState } from 'react';
import useAsyncEffect from '../../../utils/react/useAsyncEffect';
import { ProjectRulesAgreement } from 'shared/lib/types/ProjectRulesAgreement';
import { useApi } from '../../../contexts/ApiContext';
import { Modal } from '../../Modal/Modal';
import { SpinnerOverlay } from '../../SpinnerOverlay/SpinnerOverlay';
import { PraText } from '../../PraText/PraText';

export interface ViewProjectRulesAgreementModalProps {
  projectRulesAgreementId: number;
  onClose(): unknown;
}

export const ViewProjectRulesAgreementModal: FC<ViewProjectRulesAgreementModalProps> = ({
  projectRulesAgreementId,
  onClose,
  ...rest
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [
    projectRulesAgreement,
    setProjectRulesAgreement,
  ] = useState<ProjectRulesAgreement | null>(null);

  useAsyncEffect(
    async (isCancelled) => {
      const pra = await api.getProjectRulesAgreementById({
        projectRulesAgreementId,
      });
      if (!isCancelled()) {
        setProjectRulesAgreement(pra);
        setLoading(false);
      }
    },
    [api, projectRulesAgreementId],
  );

  return (
    <Modal {...rest} onClose={onClose} roundedSize="6xl" className="w-168">
      {loading && <SpinnerOverlay />}
      <h1 className="text-4xl text-center">PRA</h1>
      {projectRulesAgreement && (
        <PraText
          projectRulesAgreement={projectRulesAgreement}
          className="mt-5"
        />
      )}
    </Modal>
  );
};
