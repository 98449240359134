import { FC, useCallback, useMemo } from 'react';
import { areObligationsLoaded } from 'shared/lib/utils/projectUtils';
import { useProject } from '../../../contexts/ProjectContext';
import { ObligationModal, ObligationModalValue } from './ObligationModal';

export interface EditObligationModalProps {
  obligationId: number;
  onClose(): unknown;
}

export const EditObligationModal: FC<EditObligationModalProps> = ({
  obligationId,
  onClose,
  ...rest
}) => {
  const { project, updateObligation } = useProject();
  const initialValue: ObligationModalValue | undefined = useMemo(() => {
    if (project && areObligationsLoaded(project)) {
      return project.obligations.find(
        (obligation) => obligation.id === obligationId,
      );
    }
  }, [project, obligationId]);

  const handleSubmit = useCallback(
    async (value: ObligationModalValue) => {
      await updateObligation({ ...value, obligationId });
      onClose();
    },
    [updateObligation, onClose, obligationId],
  );

  if (project && !areObligationsLoaded(project)) {
    // Cannot edit obligations if the PRA is unsigned
    // TODO: add an error modal?
    return null;
  }

  return (
    <ObligationModal
      {...rest}
      key={initialValue ? 'loaded' : 'loading'}
      initialValue={initialValue}
      showSpinner={!initialValue}
      title="Edit Obligation"
      submitButtonText="Save Changes"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
