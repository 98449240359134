import { FC, useCallback, useState } from 'react';
import { ReactComponent as LinkIcon } from '../../images/icons/link-2.svg';
import { ReactComponent as ErrorPageIcon } from '../../images/error-page.svg';
import {
  NotificationWithMessage,
  PraSignatureRequestNotification,
  isPraSignatureRequestNotification,
  isAssetApprovalRequestNotification,
  AssetApprovalRequestNotification,
} from 'shared/lib/types/Notification';
import { Button } from '../Button/Button';
import { formatUserCircleName, UserCircle } from '../UserCircle/UserCircle';
import { useProjectOptional } from '../../contexts/ProjectContext';
import { AssetStatus } from 'shared/lib/types/AssetStatus';
import { useApi } from '../../contexts/ApiContext';
import { showRejectAssetModal } from '../modals/assetModals/RejectAssetModal';
import { SignProjectRulesAgreementModal } from '../modals/projectRulesAgreementModals/SignProjectRulesAgreementModal';
import useToggleState from '../../utils/react/useToggleState';
import { MultiFileDownloadLink } from '../MultiFileDownloadLink/MultiFileDownloadLink';
import { getAssetFileUrl } from 'shared/lib/utils/getAssetFileUrl';

export const NotificationListItem: FC<{
  notification: NotificationWithMessage;
  index: number;
  className?: string;
  onDismiss(): unknown;
}> = ({
  notification,
  index,
  onDismiss,
  className = '',
  children,
  ...rest
}) => {
  const { fromUserName } = notification;

  return (
    <li
      {...rest}
      className={`col py-6 ${
        index > 0 ? 'border-t border-gray-300' : ''
      } ${className}`}
    >
      <div className="row mb-6">
        <UserCircle color="uo-green">
          {fromUserName && formatUserCircleName(fromUserName)}
        </UserCircle>
        <p className="ml-6">{notification.message}</p>
      </div>
      <NotificationListItemControls
        notification={notification}
        onDismiss={onDismiss}
      />
    </li>
  );
};

const NotificationListItemControls: FC<{
  notification: NotificationWithMessage;
  onDismiss(): unknown;
}> = ({ notification, ...rest }) => {
  if (isPraSignatureRequestNotification(notification)) {
    return (
      <PraSignatureRequestControls {...rest} notification={notification} />
    );
  }

  if (isAssetApprovalRequestNotification(notification)) {
    return (
      <AssetApprovalRequestControls {...rest} notification={notification} />
    );
  }

  return null;
};

const PraSignatureRequestControls: FC<{
  notification: PraSignatureRequestNotification;
  onDismiss(): unknown;
}> = ({ notification, onDismiss, ...rest }) => {
  const { signProjectRulesAgreement } = useApi();
  const projectContext = useProjectOptional();
  const [showSignPraModal, toggleSignPraModal] = useToggleState(false);
  const currentProjectId = projectContext?.projectId;
  const signCurrentProjectPra = projectContext?.signProjectRulesAgreement;
  const { projectId, projectRulesAgreementId } = notification;

  const handleSignPra = useCallback(
    async (signature: string) => {
      if (signature) {
        // If viewing a project, use the project context to sign the PRA
        if (signCurrentProjectPra && projectId === currentProjectId) {
          await signCurrentProjectPra({ signature });
        } else {
          await signProjectRulesAgreement({ projectId, signature });
        }
        onDismiss();
      }
    },
    [
      signProjectRulesAgreement,
      signCurrentProjectPra,
      onDismiss,
      projectId,
      currentProjectId,
    ],
  );

  return (
    <div {...rest} className="row justify-center">
      <Button
        className="w-28"
        size="small"
        backgroundColor="uo-green"
        textColor="white"
        onClick={toggleSignPraModal}
      >
        View PRA
      </Button>
      {showSignPraModal && (
        <SignProjectRulesAgreementModal
          onCancel={toggleSignPraModal}
          onSubmit={handleSignPra}
          projectId={projectId}
          projectRulesAgreementId={projectRulesAgreementId}
        />
      )}
    </div>
  );
};

const AssetApprovalRequestControls: FC<{
  notification: AssetApprovalRequestNotification;
  onDismiss(): unknown;
}> = ({ notification, onDismiss, ...rest }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const {
    projectId: notificationProjectId,
    projectName: notificationProjectName,
    assetId,
    assetOriginStory,
  } = notification;
  const projectContext = useProjectOptional();
  const currentProjectId = projectContext?.projectId;
  const updateCurrentProjectAsset = projectContext?.updateAsset;
  const { updateAsset } = useApi();

  const handleApprove = useCallback(async () => {
    try {
      // Update the project currently being viewed if it's the same as the notification project
      if (
        updateCurrentProjectAsset &&
        notificationProjectId === currentProjectId
      ) {
        await updateCurrentProjectAsset({
          assetId,
          status: AssetStatus.APPROVED,
        });
      } else {
        await updateAsset({
          projectId: notificationProjectId,
          assetId,
          status: AssetStatus.APPROVED,
        });
      }
      onDismiss();
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  }, [
    updateCurrentProjectAsset,
    updateAsset,
    onDismiss,
    currentProjectId,
    notificationProjectId,
    assetId,
  ]);

  const handleReject = useCallback(async () => {
    try {
      setSubmitting(true);
      const rejectMessage = await showRejectAssetModal({
        projectName: notificationProjectName,
        asset: { originStory: assetOriginStory },
      });
      if (!rejectMessage) {
        return;
      }

      // Update the project currently being viewed if it's the same as the notification project
      if (
        updateCurrentProjectAsset &&
        notificationProjectId === currentProjectId
      ) {
        await updateCurrentProjectAsset({
          assetId,
          status: AssetStatus.REJECTED,
        });
      } else {
        await updateAsset({
          projectId: notificationProjectId,
          assetId,
          status: AssetStatus.REJECTED,
        });
      }
      onDismiss();
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  }, [
    updateCurrentProjectAsset,
    updateAsset,
    onDismiss,
    currentProjectId,
    notificationProjectId,
    assetId,
    notificationProjectName,
    assetOriginStory,
  ]);

  return (
    <div {...rest} className="col">
      <div className="row items-center justify-between">
        <a
          href={notification.assetUrl}
          target="_blank"
          rel="noreferrer"
          className="p-4"
        >
          <LinkIcon />
        </a>
        <MultiFileDownloadLink
          filePaths={notification.assetFilePaths.map((filePath) =>
            getAssetFileUrl(
              { id: notification.assetId, projectId: notification.projectId },
              filePath,
            ),
          )}
        />
        <Button backgroundColor="none" textColor="black">
          <ErrorPageIcon />
        </Button>
      </div>
      {!!submitError && (
        <p className="text-red text-center mt-6">{submitError.message}</p>
      )}
      <div className="row mt-6">
        <Button
          className="w-0 flex-grow"
          backgroundColor="uo-green"
          textColor="white"
          size="small"
          disabled={submitting}
          onClick={handleApprove}
        >
          Approve
        </Button>
        <Button
          className="w-0 flex-grow ml-3"
          backgroundColor="red"
          textColor="white"
          size="small"
          disabled={submitting}
          onClick={handleReject}
        >
          Reject
        </Button>
      </div>
    </div>
  );
};
