import React, { FC, useCallback } from 'react';
import { useProject } from '../../../contexts/ProjectContext';
import { USE_LOCAL_FILE_UPLOAD } from '../../../env';
import { AssetModal, AssetModalValue } from './AssetModal';

export interface CreateAssetModalProps {
  onClose(): unknown;
}

export const CreateAssetModal: FC<CreateAssetModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createAsset, updateAsset, uploadAssetFile } = useProject();

  const handleSubmit = useCallback(
    async ({ files, ...rest }: AssetModalValue) => {
      const { id: assetId } = await createAsset({ ...rest, filePaths: [] });
      const filesToUpload = files.filter(
        (file): file is File => file instanceof File,
      );

      if (filesToUpload.length > 0) {
        await Promise.all(
          filesToUpload.map((file) =>
            uploadAssetFile({
              assetId,
              file,
              useLocalFileUpload: USE_LOCAL_FILE_UPLOAD,
            }),
          ),
        );

        await updateAsset({
          assetId,
          filePaths: filesToUpload.map((file) => file.name),
        });
      }

      onClose();
    },
    [createAsset, uploadAssetFile, updateAsset, onClose],
  );

  return (
    <AssetModal
      {...rest}
      title="Add Asset"
      submitButtonText="Add Asset"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
