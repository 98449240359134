import React, { FC } from 'react';
import { Button } from '../Button/Button';
import { ReactComponent as MoreIcon } from '../../images/icons/more-vertical.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/info.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit-3.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/x-circle.svg';
import { Obligation } from 'shared/lib/types/Obligation';
import { Flyout } from '../Flyout/Flyout';
import { useProject } from '../../contexts/ProjectContext';
import { Link } from 'react-router-dom';
import { showConfirm } from '../ConfirmModal/showConfirm';
import useToggleState from '../../utils/react/useToggleState';
import { useAccount } from '../../contexts/AccountContext';
import {
  canUserDeleteObligation,
  canUserUpdateObligation,
} from 'shared/lib/utils/permissions';
import {
  areMembersLoaded,
  areObligationsLoaded,
} from 'shared/lib/utils/projectUtils';

export interface ObligationListItemProps {
  className?: string;
  obligation: Pick<Obligation, 'id' | 'name' | 'description'>;
}

export const ObligationListItem: FC<ObligationListItemProps> = ({
  obligation,
  className = '',
  ...rest
}) => {
  const { user } = useAccount();
  const { projectId, project, deleteObligation } = useProject();
  const [showMenu, toggleMenu] = useToggleState();
  const showEditButton = !!(
    user &&
    project &&
    areObligationsLoaded(project) &&
    areMembersLoaded(project) &&
    canUserUpdateObligation(user, project)
  );
  const showDeleteButton = !!(
    user &&
    project &&
    areObligationsLoaded(project) &&
    areMembersLoaded(project) &&
    canUserDeleteObligation(user, project)
  );

  return (
    <li
      {...rest}
      className={`bg-white px-5 py-6 col relative rounded shadow ${className}`}
    >
      <div className="absolute top-0 right-0 mt-1">
        <Button
          backgroundColor="none"
          textColor="uo-green"
          size="small"
          onClick={toggleMenu}
        >
          <MoreIcon />
        </Button>
        {showMenu && (
          <Flyout
            arrowSide="top-right"
            color="uo-green"
            borderRadius="xl"
            className="w-52 top-0 right-0 mt-12 -mr-2"
            onClickOutside={toggleMenu}
          >
            <Link
              to={`/project/${projectId}/obligation/${obligation.id}/details`}
            >
              <Button
                backgroundColor="none"
                textColor="white"
                className="row items-center px-0"
                onClick={toggleMenu}
              >
                <InfoIcon className="mr-3" /> View Details
              </Button>
            </Link>
            {showEditButton && (
              <Link
                to={`/project/${projectId}/obligation/${obligation.id}/edit`}
              >
                <Button
                  backgroundColor="none"
                  textColor="white"
                  className="row items-center px-0"
                  onClick={toggleMenu}
                >
                  <EditIcon className="mr-3" /> Edit Obligation
                </Button>
              </Link>
            )}
            {showDeleteButton && (
              <Button
                backgroundColor="none"
                textColor="white"
                className="row items-center px-0"
                onClick={async () => {
                  toggleMenu();
                  if (
                    await showConfirm({
                      title: 'Delete Obligation',
                      message: project
                        ? `You are about to delete this obligation for project name “${project.name}.” Are you sure you wish to delete “${obligation.name}”?`
                        : '',
                      confirmButtonText: 'Delete',
                    })
                  ) {
                    await deleteObligation({
                      obligationId: obligation.id,
                    });
                  }
                }}
              >
                <DeleteIcon className="mr-3" /> Delete Obligation
              </Button>
            )}
          </Flyout>
        )}
      </div>
      <h5 className="font-semibold mb-1">{obligation.name}</h5>
      <p className="font-sm">{obligation.description}</p>
    </li>
  );
};
