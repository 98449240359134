export function formatDate(date: Date | string, seperator = '/'): string {
  // 8/22/20
  const dateObject = new Date(date);

  return `${
    dateObject.getMonth() + 1
  }${seperator}${dateObject.getDate()}${seperator}${dateObject
    .getFullYear()
    .toString()
    .slice(2)}`;
}
