import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { isBlank } from 'shared/lib/utils/isBlank';
import { useApi } from '../../contexts/ApiContext';
import UofOLogoSrc from '../../images/univerity_of_oregon_o.svg';
import { Button } from '../Button/Button';
import { TextInput } from '../TextInput/TextInput';

export const ForgotPasswordPage: FC = (props) => {
  const { sendPasswordReset } = useApi();
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        event.preventDefault();
        setSubmitting(true);
        if (isBlank(email)) {
          throw new Error('Email is required.');
        }
        await sendPasswordReset(email);
        setError(null);
        setSuccess(true);
      } catch (error) {
        setError(error.message);
      } finally {
        setSubmitting(false);
      }
    },
    [sendPasswordReset, email],
  );

  return (
    <div {...props} className="pt-12 pb-12 min-h-screen">
      <div className="container mx-auto px-4 w-100 max-w-full">
        <div className="row items-center justify-center">
          <a href="https://www.uoregon.edu/">
            <img src={UofOLogoSrc} alt="University of Oregon" />
          </a>
          <h2 className="text-xl pl-8 ml-8 border-l border-black">IPS Tool</h2>
        </div>
        <h1 className="text-2xl md:text-4xl text-center font-bold mt-16 md:mt-24 lg:mt-32">
          Forgot Password
        </h1>
        <p className={`text-center mt-8 ${success ? 'invisible' : ''}`}>
          Enter your email address to receive a password reset email.
        </p>
        {success ? (
          <div className="col justify-center text-center mt-8 md:mt-24 lg:mt-28">
            <p>
              Password reset sent successfully.
              <br />
              Check your email.
            </p>
            <Link to="/">
              <Button
                backgroundColor="uo-green"
                textColor="white"
                className="w-48 mt-12"
              >
                Login
              </Button>
            </Link>
          </div>
        ) : (
          <form className="col mt-16 md:mt-24 lg:mt-28" onSubmit={handleSubmit}>
            {error && <p className="text-center text-red">{error}</p>}
            <label className="font-semibold mt-8">Email</label>
            <TextInput
              id="email"
              required
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
              backgroundColor="white"
              borderColor="gray"
              className="mt-3"
            />
            <Button
              disabled={submitting}
              textColor="white"
              backgroundColor="uo-green"
              className="w-48 mt-16 self-center"
            >
              Submit
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};
