import { ReactElement } from "react";
import ReactDOM from "react-dom";

export type ResolveModal<T> = (value: T) => void;
export type RejectModal = (error: Error) => void;

export type RenderModalFn<T> = (
  resolve: ResolveModal<T>,
  reject: RejectModal
) => ReactElement;

/**
 * Mounts a temporary modal component in the document body and waits for it to be closed.
 * Returns a promise that resolves when the modal is submitted or closed.
 *
 * @param renderModal The modal render function. Receives a `resolve` and `reject` function arguments for signalling that the modal is closed.
 *
 * Example:
 *
 * ```tsx
 * const confirmed = await showModal<boolean>(resolve =>
 *   <ConfirmModal onConfirm={() => resolve(true)} onCancel={() => resolve(false)}/>
 * );
 * ```
 */
export default async function showModal<T = void>(
  renderModal: RenderModalFn<T>,
  rootEl = document.body
): Promise<T> {
  const mountEl = document.createElement("div");
  rootEl.appendChild(mountEl);

  try {
    const result = await new Promise<T>((resolve, reject) => {
      ReactDOM.render(renderModal(resolve, reject), mountEl);
    });
    return result;
  } finally {
    ReactDOM.unmountComponentAtNode(mountEl);
    rootEl.removeChild(mountEl);
  }
}
