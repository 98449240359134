import { FC } from 'react';

export const AccentBar: FC<{ className?: string; flipped?: boolean }> = ({
  className = '',
  flipped = false,
  ...rest
}) => (
  <div
    {...rest}
    className={`absolute left-0 bottom-0 top-0 w-1 rounded bg-berry ${
      flipped ? 'rounded-l-none' : 'rounded-r-none'
    } ${className}`}
  />
);
