import {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { RestrictedDetailedProject } from 'shared/lib/types/Project';
import { Api, EndpointProps } from 'shared/lib/api/index';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';
import replaceWhere from 'shared/lib/utils/array/replaceWhere';
import { sortAlphabetically } from 'shared/lib/utils/sort';

interface ProjectListContextValue {
  loading: boolean;
  projects: RestrictedDetailedProject[];
  listProjects(): Promise<void>;
  getProjectById: Api['getProjectById'];
  createProject: Api['createProject'];
  updateProject: Api['updateProject'];
  deleteProject: Api['deleteProject'];
}

const ProjectListContext = createContext<ProjectListContextValue | null>(null);

export const ProjectListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectListContextValue['projects']>(
    [],
  );
  const sortedProjects = useMemo(
    () => [...projects].sort((a, b) => sortAlphabetically(a.name, b.name)),
    [projects],
  );

  const { getProjectById } = api;

  const listProjects = useCallback(
    async (isCancelled?: () => boolean) => {
      const fetchedProjects = await api.listProjects();
      if (!isCancelled || !isCancelled()) {
        setProjects(fetchedProjects);
        setLoading(false);
      }
    },
    [api],
  );

  const createProject = useCallback(
    async (inputs: EndpointProps<'createProject'>) => {
      const newProject = await api.createProject(inputs);
      setProjects((projects) => [...projects, newProject]);
      return newProject;
    },
    [api],
  );

  const updateProject = useCallback(
    async (inputs: EndpointProps<'updateProject'>) => {
      const updatedProject = await api.updateProject(inputs);
      setProjects((projects) =>
        replaceWhere(
          projects,
          (project) => project.id === updatedProject.id,
          () => updatedProject,
        ),
      );
      return updatedProject;
    },
    [api],
  );

  const deleteProject = useCallback(
    async ({ projectId }: EndpointProps<'deleteProject'>) => {
      await api.deleteProject({ projectId });
      setProjects((value) => value.filter((other) => other.id !== projectId));
    },
    [api],
  );

  const value = useMemo<ProjectListContextValue>(
    () => ({
      loading,
      projects: sortedProjects,
      listProjects,
      getProjectById,
      createProject,
      updateProject,
      deleteProject,
    }),
    [
      loading,
      sortedProjects,
      listProjects,
      getProjectById,
      createProject,
      updateProject,
      deleteProject,
    ],
  );

  return (
    <ProjectListContext.Provider value={value}>
      {children}
    </ProjectListContext.Provider>
  );
};

export function useProjectList(): ProjectListContextValue {
  return (
    useContext(ProjectListContext) ??
    missingReactContext('ProjectListProvider', 'useProjectList')
  );
}
