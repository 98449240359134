import { FC, useCallback } from 'react';
import {
  ProjectRulesAgreementModal,
  ProjectRulesAgreementModalValue,
} from './ProjectRulesAgreementModal';
import { useProjectRulesAgreementList } from '../../../contexts/ProjectRulesAgreementListContext';

export interface CreateProjectRulesAgreementModalProps {
  forcePublic?: boolean;
  forceName?: string;
  autoCreate?: boolean;
  onClose(): unknown;
  onSubmit?(projectRulesAgreement: ProjectRulesAgreementModalValue): unknown;
}

export const CreateProjectRulesAgreementModal: FC<CreateProjectRulesAgreementModalProps> = ({
  onClose,
  onSubmit,
  autoCreate = true,
  ...rest
}) => {
  const { createProjectRulesAgreement } = useProjectRulesAgreementList();

  const handleSubmit = useCallback(
    async (value: ProjectRulesAgreementModalValue) => {
      if (autoCreate) {
        await createProjectRulesAgreement(value);
      }
      if (onSubmit) {
        await onSubmit(value);
      }
      onClose();
    },
    [createProjectRulesAgreement, onSubmit, onClose, autoCreate],
  );

  return (
    <ProjectRulesAgreementModal
      {...rest}
      title="Add PRA"
      submitButtonText="Add PRA"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
