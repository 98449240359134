import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { Api, EndpointProps } from 'shared/lib/api';
import { ProjectRulesAgreement } from 'shared/lib/types/ProjectRulesAgreement';
import { missingReactContext } from 'shared/lib/utils/errors';
import { EndpointResult } from 'shared/lib/api';
import { useApi } from './ApiContext';
import { removeById } from 'shared/lib/utils/removeById';
import { replaceById } from 'shared/lib/utils/replaceById';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface ProjectRulesAgreementListContextValue {
  loading: boolean;
  projectRulesAgreements: ProjectRulesAgreement[];
  publicProjectRulesAgreements: ProjectRulesAgreement[];
  refresh(isCancelled?: () => boolean): Promise<void>;
  createProjectRulesAgreement: Api['createProjectRulesAgreement'];
  updateProjectRulesAgreement: Api['updateProjectRulesAgreement'];
  deleteProjectRulesAgreement: Api['deleteProjectRulesAgreement'];
}

const ProjectRulesAgreementListContext = createContext<ProjectRulesAgreementListContextValue | null>(
  null,
);

export const ProjectRulesAgreementListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [projectRulesAgreements, setProjectRulesAgreements] = useState<
    ProjectRulesAgreementListContextValue['projectRulesAgreements']
  >([]);
  const sortedProjectRulesAgreements = useMemo(
    () =>
      [...projectRulesAgreements].sort((a, b) =>
        sortAlphabetically(a.name, b.name),
      ),
    [projectRulesAgreements],
  );
  const publicProjectRulesAgreements = useMemo(
    () => sortedProjectRulesAgreements.filter((pra) => pra.public),
    [sortedProjectRulesAgreements],
  );

  const fetchProjectRulesAgreements = useCallback(
    async (isCancelled?: () => boolean) => {
      const fetchedProjectRulesAgreements = await api.listProjectRulesAgreements();

      if (!isCancelled || !isCancelled()) {
        setProjectRulesAgreements(fetchedProjectRulesAgreements);
        setLoading(false);
      }
    },
    [api],
  );

  const createProjectRulesAgreement = useCallback(
    async (
      inputs: EndpointProps<'createProjectRulesAgreement'>,
    ): Promise<EndpointResult<'createProjectRulesAgreement'>> => {
      const newProjectRulesAgreement = await api.createProjectRulesAgreement(
        inputs,
      );
      setProjectRulesAgreements((value) => [
        ...value,
        newProjectRulesAgreement,
      ]);
      return newProjectRulesAgreement;
    },
    [api],
  );

  const updateProjectRulesAgreement = useCallback(
    async (
      inputs: EndpointProps<'updateProjectRulesAgreement'>,
    ): Promise<EndpointResult<'updateProjectRulesAgreement'>> => {
      const updatedProjectRulesAgreement = await api.updateProjectRulesAgreement(
        inputs,
      );
      setProjectRulesAgreements((value) =>
        replaceById(
          value,
          inputs.projectRulesAgreementId,
          updatedProjectRulesAgreement,
        ),
      );
      return updatedProjectRulesAgreement;
    },
    [api],
  );

  const deleteProjectRulesAgreement = useCallback(
    async (
      inputs: EndpointProps<'deleteProjectRulesAgreement'>,
    ): Promise<EndpointResult<'deleteProjectRulesAgreement'>> => {
      await api.deleteProjectRulesAgreement(inputs);
      setProjectRulesAgreements((value) =>
        removeById(value, inputs.projectRulesAgreementId),
      );
    },
    [api],
  );

  useAsyncEffect(fetchProjectRulesAgreements, [fetchProjectRulesAgreements]);

  const value = useMemo<ProjectRulesAgreementListContextValue>(
    () => ({
      loading,
      projectRulesAgreements: sortedProjectRulesAgreements,
      publicProjectRulesAgreements,
      refresh: fetchProjectRulesAgreements,
      createProjectRulesAgreement,
      updateProjectRulesAgreement,
      deleteProjectRulesAgreement,
    }),
    [
      loading,
      sortedProjectRulesAgreements,
      publicProjectRulesAgreements,
      fetchProjectRulesAgreements,
      createProjectRulesAgreement,
      updateProjectRulesAgreement,
      deleteProjectRulesAgreement,
    ],
  );

  return (
    <ProjectRulesAgreementListContext.Provider value={value}>
      {children}
    </ProjectRulesAgreementListContext.Provider>
  );
};

export function useProjectRulesAgreementList(): ProjectRulesAgreementListContextValue {
  return (
    useContext(ProjectRulesAgreementListContext) ??
    missingReactContext(
      'ProjectRulesAgreementListProvider',
      'useProjectRulesAgreementList',
    )
  );
}
