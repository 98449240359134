import { FC, FormEvent, useState, useCallback } from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import { Modal, ModalProps } from '../../Modal/Modal';
import { Button } from '../../Button/Button';
import { TextInput } from '../../TextInput/TextInput';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { Select } from '../../Select/Select';
import { useUserRoleList } from '../../../contexts/UserRoleListContext';

export interface UserModalValue {
  name: string;
  email: string;
  roleId: number;
  affiliation: string | null;
}

export interface UserModalProps extends ModalProps {
  title: string;
  initialValue?: UserModalValue;
  showSpinner?: boolean;
  readOnly?: boolean;
  submitButtonText?: string;
  onSubmit?(value: UserModalValue): unknown;
}

export const UserModal: FC<UserModalProps> = ({
  title,
  initialValue,
  showSpinner,
  readOnly,
  submitButtonText,
  onSubmit,
  ...rest
}) => {
  const { selectableUserRoles } = useUserRoleList();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [name, setName] = useState(initialValue?.name ?? '');
  const [email, setEmail] = useState(initialValue?.email ?? '');
  const [roleId, setRoleId] = useState(initialValue?.roleId ?? null);
  const [affiliation, setAffiliation] = useState(
    initialValue?.affiliation ?? '',
  );
  const role = roleId
    ? selectableUserRoles.find(({ id }) => id === roleId)
    : null;

  const canSubmit = !!(
    !submitting &&
    !isBlank(name) &&
    !isBlank(email) &&
    role &&
    (!role.requireAffiliation || !isBlank(affiliation))
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (readOnly) {
        return;
      }
      setSubmitting(true);
      try {
        if (onSubmit && roleId) {
          await onSubmit({
            name,
            email,
            roleId,
            // Only pass the affiliation if affiliations are enabled for the selected role.
            affiliation: role?.requireAffiliation ? affiliation : null,
          });
        }
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, readOnly, name, email, roleId, role, affiliation],
  );

  return (
    <Modal
      {...rest}
      className="relative w-168 text-left"
      showSpinner={submitting}
    >
      <form className="col" onSubmit={handleSubmit}>
        <h1 className="text-4xl text-center font-normal">{title}</h1>

        <label className="font-semibold mt-8">Name</label>
        <TextInput
          value={name}
          onTextChange={setName}
          disabled={readOnly}
          className="mt-3"
        />

        <label className="font-semibold mt-8">Email</label>
        <TextInput
          value={email}
          onTextChange={setEmail}
          disabled={readOnly}
          className="mt-3"
        />

        <label className="font-semibold mt-8">Role</label>
        <Select
          className="mt-3"
          value={roleId}
          onValueChange={(value) => setRoleId(value ? +value : null)}
          options={selectableUserRoles.map((role) => ({
            value: role.id.toString(),
            label: role.name,
          }))}
        />

        {role?.requireAffiliation && (
          <>
            <label className="font-semibold mt-8">Affiliation</label>
            <TextInput
              value={affiliation ?? ''}
              onTextChange={setAffiliation}
              disabled={readOnly}
              className="mt-3"
            />
          </>
        )}

        {submitError && (
          <p className="mt-8 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}

        {!readOnly && (
          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="mt-16 w-48 self-center"
            disabled={!canSubmit}
          >
            {submitButtonText}
          </Button>
        )}
      </form>
    </Modal>
  );
};
