import { FC, FormEvent, useState, useCallback } from 'react';
import { isBlank } from 'shared/lib/utils/isBlank';
import { assetOriginStories } from 'shared/lib/constants/assetOriginStories';
import { getAssetFileUrl } from 'shared/lib/utils/getAssetFileUrl';
import { Asset } from 'shared/lib/types/Asset';
import { ReactComponent as XIcon } from '../../../images/icons/x.svg';
import { Modal, ModalProps } from '../../Modal/Modal';
import { SpinnerOverlay } from '../../SpinnerOverlay/SpinnerOverlay';
import { Button } from '../../Button/Button';
import { TextInput } from '../../TextInput/TextInput';
import { TextArea } from '../../TextArea/TextArea';
import { getErrorMessage } from '../../../utils/getErrorMessage';
import { Select } from '../../Select/Select';
import { useAssetCategoryList } from '../../../contexts/AssetCategoryListContext';
import { FileInput } from '../../FileInput/FileInput';
import { getFileName } from '../../../utils/getFileName';

export interface AssetModalValue {
  assetCategoryId: number;
  name: string;
  description: string;
  url: string;
  originStory: string;
  files: (File | string)[];
}

export interface AssetModalProps extends ModalProps {
  title: string;
  initialValue?: AssetModalValue;
  showSpinner?: boolean;
  readOnly?: boolean;
  submitButtonText?: string;
  onSubmit?(value: AssetModalValue): unknown;
}

export const AssetModal: FC<AssetModalProps> = ({
  title,
  initialValue,
  showSpinner,
  readOnly,
  submitButtonText,
  onSubmit,
  ...rest
}) => {
  const { assetCategories } = useAssetCategoryList();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);
  const [name, setName] = useState(initialValue?.name ?? '');
  const [description, setDescription] = useState(
    initialValue?.description ?? '',
  );
  const [url, setUrl] = useState(initialValue?.url ?? '');
  const [assetCategoryId, setAssetCategoryId] = useState(
    initialValue?.assetCategoryId ?? null,
  );
  const [originStory, setOriginStory] = useState(
    initialValue?.originStory ?? '',
  );
  const [files, setFiles] = useState<(File | string)[]>(
    initialValue?.files ?? [],
  );

  const canSubmit = !submitting && assetCategoryId && !isBlank(name);

  const handleFilesPicked = useCallback((newFiles: File[]) => {
    setFiles((existingFiles) => [...existingFiles, ...newFiles]);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (readOnly) {
        return;
      }
      setSubmitting(true);
      try {
        if (onSubmit && assetCategoryId) {
          await onSubmit({
            name,
            description,
            url,
            assetCategoryId,
            originStory,
            files,
          });
        }
      } catch (error) {
        setSubmitError(error);
      } finally {
        setSubmitting(false);
      }
    },
    [
      onSubmit,
      name,
      description,
      url,
      assetCategoryId,
      originStory,
      files,
      readOnly,
    ],
  );

  return (
    <Modal {...rest} className="relative w-168 text-left">
      <form className="col" onSubmit={handleSubmit}>
        {(submitting || showSpinner) && (
          <SpinnerOverlay
            message={submitting && `Saving asset`}
            className="rounded-xl"
          />
        )}
        <h1 className="text-4xl text-center font-normal">{title}</h1>

        <label className="font-semibold mt-8">Asset Title*</label>
        <TextInput
          value={name}
          onTextChange={setName}
          disabled={readOnly}
          className="mt-3"
        />

        <label className="font-semibold mt-8">Link URL</label>
        <TextInput
          value={url}
          onTextChange={setUrl}
          disabled={readOnly}
          className="mt-3"
        />

        <label className="font-semibold mt-8">Asset Category*</label>
        <Select
          value={assetCategoryId}
          onValueChange={(value) => setAssetCategoryId(value ? +value : null)}
          disabled={readOnly}
          className="mt-3"
          options={assetCategories.map((assetCategory) => ({
            value: assetCategory.id.toString(),
            label: assetCategory.name,
          }))}
        />

        <label className="mt-8 font-semibold">Asset Description</label>
        <TextArea
          value={description}
          onTextChange={setDescription}
          disabled={readOnly}
          className="mt-3"
          placeholder="Description Goes Here"
        />

        <label className="font-semibold mt-8">Origin Story</label>
        <Select
          className="mt-3"
          value={originStory}
          disabled={readOnly}
          onValueChange={(value) => setOriginStory(value ?? '')}
          options={assetOriginStories.map((story) => ({
            value: story,
            label: story,
          }))}
        />

        <label className="font-semibold mt-8">Attach Document</label>

        {!readOnly && (
          <FileInput multiple className="mt-3" onChange={handleFilesPicked} />
        )}
        {files.length > 0 && (
          <ul className="mt-3">
            {files.map((file, i) => {
              const fileName = getFileName(file);
              return (
                <li
                  key={fileName}
                  className={`row items-center ${i > 0 ? 'mt-1' : ''}`}
                >
                  {/* Display the file as a download link if it is already uploaded. */}
                  {typeof file === 'string' ? (
                    <a href={file} target="_blank" rel="noreferrer">
                      {fileName}
                    </a>
                  ) : (
                    fileName
                  )}
                  {!readOnly && (
                    <Button
                      backgroundColor="none"
                      textColor="black"
                      size="small"
                      onClick={(event) => {
                        event.preventDefault();
                        setFiles((existingFiles) =>
                          existingFiles.filter((other) => other !== file),
                        );
                      }}
                    >
                      <XIcon className="ml-4" title="remove" />
                    </Button>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        {submitError && (
          <p className="mt-8 text-red text-center text-lg">
            {getErrorMessage(submitError)}
          </p>
        )}

        {!readOnly && (
          <Button
            backgroundColor="uo-green"
            textColor="white"
            className="mt-16 w-48 self-center"
            disabled={!canSubmit}
          >
            {submitButtonText}
          </Button>
        )}
      </form>
    </Modal>
  );
};

export function getAssetModalValueFromAsset(asset: Asset): AssetModalValue {
  return {
    assetCategoryId: asset.assetCategoryId,
    name: asset.name,
    description: asset.description,
    url: asset.url,
    originStory: asset.originStory,
    files: asset.filePaths.map((filePath) => getAssetFileUrl(asset, filePath)),
  };
}
