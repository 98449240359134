import {
  FC,
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { missingReactContext } from 'shared/lib/utils/errors';
import { useApi } from './ApiContext';
import { NotificationWithMessage } from 'shared/lib/types/Notification';

interface NotificationContextValue {
  loading: boolean;
  notifications: NotificationWithMessage[];
  hasUnviewedNotification: boolean;
  dismissNotification(notificationId: number): void;
  markNotificationsViewed(): Promise<void>;
}

const NotificationContext = createContext<NotificationContextValue | null>(
  null,
);

export const NotificationProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<NotificationWithMessage[]>(
    [],
  );
  const hasUnviewedNotification = useMemo(
    () => notifications.some((notification) => notification.viewedAt === null),
    [notifications],
  );

  useAsyncEffect(
    async (isCancelled) => {
      const fetchedNotifications = await api.getUnviewedNotifications();
      if (!isCancelled()) {
        setNotifications(fetchedNotifications);
        setLoading(false);
      }
    },
    [api],
  );

  const dismissNotification = useCallback((notificationId: number) => {
    setNotifications((value) =>
      value.filter((other) => other.id !== notificationId),
    );
  }, []);

  const markNotificationsViewed = useCallback(async () => {
    if (notifications.length === 0) {
      return;
    }
    await api.markNotificationsViewed(
      notifications.map((notification) => notification.id),
    );
    const viewedAt = new Date().toISOString();
    setNotifications((notifications) =>
      notifications.map((notification) => ({ ...notification, viewedAt })),
    );
  }, [api, notifications]);

  return (
    <NotificationContext.Provider
      value={{
        loading,
        notifications,
        hasUnviewedNotification,
        dismissNotification,
        markNotificationsViewed,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotifications(): NotificationContextValue {
  return (
    useContext(NotificationContext) ??
    missingReactContext('NotificationProvider', 'useNotification')
  );
}
