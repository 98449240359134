import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ManageCollegesPage } from './ManageCollegesPage';
import { ManageProjectRulesAgreementsPage } from './ManageProjectRulesAgreementsPage';
import { ManageUsersPage } from './ManageUsersPage';

export const AdminToolsPage: FC = () => (
  <Switch>
    <Route path={['/admin/users', '/admin/user']}>
      <ManageUsersPage />
    </Route>
    <Route
      path={['/admin/projectRulesAgreements', '/admin/projectRulesAgreement']}
    >
      <ManageProjectRulesAgreementsPage />
    </Route>
    <Route
      path={[
        '/admin/colleges',
        '/admin/college',
        '/admin/departments',
        '/admin/department',
        '/admin/programs',
        '/admin/program',
      ]}
    >
      <ManageCollegesPage />
    </Route>
  </Switch>
);
