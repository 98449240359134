import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import replaceWhere from 'shared/lib/utils/array/replaceWhere';
import useAsyncEffect from '../utils/react/useAsyncEffect';
import { Api, EndpointProps } from 'shared/lib/api';
import { Program } from 'shared/lib/types/Program';
import { missingReactContext } from 'shared/lib/utils/errors';
import { EndpointResult } from 'shared/lib/api';
import { useApi } from './ApiContext';
import { sortAlphabetically } from 'shared/lib/utils/sort';

/**
 * These values are needed for filtering and authoring throughout the app.
 */
interface ProgramListContextValue {
  loading: boolean;
  programs: Program[];
  createProgram: Api['createProgram'];
  updateProgram: Api['updateProgram'];
  deleteProgram: Api['deleteProgram'];
}

const ProgramListContext = createContext<ProgramListContextValue | null>(null);

export const ProgramListProvider: FC = ({ children }) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState<ProgramListContextValue['programs']>(
    [],
  );
  const sortedPrograms = useMemo(
    () => [...programs].sort((a, b) => sortAlphabetically(a.name, b.name)),
    [programs],
  );

  const createProgram = useCallback(
    async (
      inputs: EndpointProps<'createProgram'>,
    ): Promise<EndpointResult<'createProgram'>> => {
      const program = await api.createProgram(inputs);
      setPrograms((value) => [...value, program]);
      return program;
    },
    [api],
  );

  const updateProgram = useCallback(
    async (
      inputs: EndpointProps<'updateProgram'>,
    ): Promise<EndpointResult<'updateProgram'>> => {
      const program = await api.updateProgram(inputs);
      setPrograms((value) =>
        replaceWhere(
          value,
          (other) => other.id === program.id,
          () => program,
        ),
      );
      return program;
    },
    [api],
  );

  const deleteProgram = useCallback(
    async (
      inputs: EndpointProps<'deleteProgram'>,
    ): Promise<EndpointResult<'deleteProgram'>> => {
      await api.deleteProgram(inputs);
      setPrograms((value) =>
        value.filter((other) => other.id !== inputs.programId),
      );
    },
    [api],
  );

  useAsyncEffect(async (isCancelled) => {
    const fetchedPrograms = await api.listPrograms();

    if (!isCancelled()) {
      setPrograms(fetchedPrograms);
      setLoading(false);
    }
  }, []);

  const value = useMemo<ProgramListContextValue>(
    () => ({
      loading,
      programs: sortedPrograms,
      createProgram,
      updateProgram,
      deleteProgram,
    }),
    [loading, sortedPrograms, createProgram, updateProgram, deleteProgram],
  );

  return (
    <ProgramListContext.Provider value={value}>
      {children}
    </ProgramListContext.Provider>
  );
};

export function useProgramList(): ProgramListContextValue {
  return (
    useContext(ProgramListContext) ??
    missingReactContext('ProgramListProvider', 'useProgramList')
  );
}
