import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { canUserUpdateProject } from 'shared/lib/utils/permissions';
import { areMembersLoaded } from 'shared/lib/utils/projectUtils';
import { useAccount } from '../../contexts/AccountContext';
import { useProject } from '../../contexts/ProjectContext';
import { formatDate } from '../../utils/formatDate';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import { Button } from '../Button/Button';
import { SpinnerOverlay } from '../SpinnerOverlay/SpinnerOverlay';
import { Switch } from '../Switch/Switch';

export interface ProjectInfoFlyoutProps {
  onClose(): unknown;
  className?: string;
}

export const ProjectInfoFlyout: FC<ProjectInfoFlyoutProps> = ({
  onClose,
  className = '',
  ...rest
}) => {
  const { user } = useAccount();
  const ref = useOnClickOutside<HTMLDivElement>(onClose);
  const {
    projectId,
    project,
    toggleAssetsEnabled,
    toggleObligationsEnabled,
  } = useProject();
  const canEdit = !!(
    user &&
    project &&
    areMembersLoaded(project) &&
    canUserUpdateProject(user, project)
  );

  return (
    <div
      {...rest}
      ref={ref}
      className={`bg-white rounded shadow col p-6 w-168 z-10 ${className}`}
    >
      {!project && <SpinnerOverlay />}
      <div className="row justify-between">
        <div className="col">
          <h3 className="text-xl max-w-sm">{project?.name}</h3>
          <p className="text-sm mt-1">
            Date Created:{' '}
            <time>{project && formatDate(project.createdAt)}</time>
          </p>
        </div>
        {canEdit && (
          <Link to={`/project/${projectId}/edit`}>
            <Button
              backgroundColor="uo-green"
              textColor="white"
              className="py-3 w-48"
              onClick={onClose}
            >
              Edit Project Info
            </Button>
          </Link>
        )}
      </div>
      {canEdit && (
        <div className="row font-semibold mt-8">
          <label className="row items-center cursor-pointer">
            <div>Assets</div>
            <Switch
              checked={project?.assetsEnabled}
              onChange={toggleAssetsEnabled}
              className="ml-6"
            />
          </label>
          <label className="row items-center cursor-pointer ml-16">
            <div>Obligations</div>
            <Switch
              checked={project?.obligationsEnabled}
              onChange={toggleObligationsEnabled}
              className="ml-6"
            />
          </label>
        </div>
      )}
      <h4 className="font-semibold mt-10">Project Description</h4>
      <p className="mt-3">{project?.description}</p>
      <div className="col justify-center text-center mt-10">
        {project?.staffManager && (
          <h4 className="text-sm font-bold">{project.staffManager.name}</h4>
        )}
        <ul className="font-semibold">
          {project?.programs?.map((program) => (
            <ul key={program.id} className="mt-1">
              {program.name}
            </ul>
          ))}
        </ul>
      </div>
      <div className="row justify-evenly text-center mt-10">
        <div className="col">
          <h5 className="text-sm italic">
            College{project?.colleges?.length ?? 0 > 1 ? 's' : ''}
          </h5>
          <ul className="font-semibold">
            {project?.colleges?.map((college) => (
              <ul key={college.id} className="mt-1">
                {college.name}
              </ul>
            ))}
          </ul>
        </div>
        <div className="col">
          <h5 className="text-sm italic">
            Department{project?.departments?.length ?? 0 > 1 ? 's' : ''}
          </h5>
          <ul className="font-semibold">
            {project?.departments?.map((department) => (
              <ul key={department.id} className="mt-1">
                {department.name}
              </ul>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
