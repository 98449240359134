import React, { FC, useCallback } from 'react';
import { useProject } from '../../../contexts/ProjectContext';
import { ObligationModal, ObligationModalValue } from './ObligationModal';

export interface CreateObligationModalProps {
  onClose(): unknown;
}

export const CreateObligationModal: FC<CreateObligationModalProps> = ({
  onClose,
  ...rest
}) => {
  const { createObligation } = useProject();

  const handleSubmit = useCallback(
    async (value: ObligationModalValue) => {
      await createObligation(value);
      onClose();
    },
    [createObligation, onClose],
  );

  return (
    <ObligationModal
      {...rest}
      title="Add Obligation"
      submitButtonText="Add Obligation"
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};
