import React, { FC } from 'react';
import { useProgramList } from '../../contexts/ProgramListContext';
import { ProgramListItem } from './ProgramListItem';

export interface ProgramListProps {
  className?: string;
}

export const ProgramList: FC<ProgramListProps> = ({
  className = '',
  ...rest
}) => {
  const { programs } = useProgramList();

  return (
    <ul {...rest} className={`col pl-6 pr-3 shadow rounded ${className}`}>
      {programs.map((program) => (
        <ProgramListItem key={program.id} program={program} />
      ))}
    </ul>
  );
};
